import { combineReducers } from "redux";
import { TOKEN, ROLE, LEVEL,TournamentID} from '../Actions/Types';

const initialState = {
    
    id: -1,
    isChild: false,
    isParent:false,
    level: 0,
    role:0,
    username:'',
    token:''
  };
  
  export const appReducer = (state = initialState, action) => {
    switch (action.type) {
      case TOKEN:
        const { id, username, token } = action.payload;
        return {
          ...state,
          id,
          username,
          token
        };
        case ROLE:
        const {role } = action.payload;
        return {
          ...state,
          role
        };
        case LEVEL:
        const {  level } = action.payload;
        return {
          ...state,
          level
        };
        case TournamentID:
          const { tournamentID } = action.payload;
          return {
            ...state,
            tournamentID
          };
        
  
      default:
        return state;
    }
  };

  export default combineReducers({
    appReducer,
})