import * as React from 'react';
import { Component } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useState, useEffect } from "react";
import { Modal,Button,OverlayTrigger,Tooltip  } from 'react-bootstrap';
import en from '../resources/en.json';
import config from "../config.json";
import { useDispatch, useSelector } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";
import { useCookies } from 'react-cookie';
import i18next from '../i18n/config';
export default function RemoveTeamModal(props) {
    let BaseUrl = config.BaseUrl;
    const navigate = useNavigate();
    const token = useSelector((state) => state.appReducer.token);
    const id = useSelector((state) => state.appReducer.id);
    let [status, setstatus] = useState(0);
    const [loading, setLoading] = useState(false);
    const [cookies, setCookie] = useCookies(['lang']);
    let [show, setShow] = useState(props.show);
    let [team, setTeam] = useState({
        teamID: '',
    });
 
    const [teamName, setTeamName] = useState('');


    useEffect(() => {
        if (props.show == true) {

            setShow(props.show)
            setTeam({
                teamID: props.teamID,
            });
            setTeamName(props.teamName);

        }
    }, [props.show]);

    const handleClose = () => {
        setShow(false);
        // props.onChange('david');
  
    };

    const handlesubmit=(e)=>{
        e.preventDefault()
           setLoading(true);
            fetch(`${BaseUrl}deleteTeam/${props.teamID}`,{
                method:'get',
                headers:{
                    'Accept':'application/json',
                    'Authorization': `Bearer ${token}`,        
                    'Content-Type':'application/json',
                    'Accept-Language': cookies.lang,
                }
            }).then(function(response){
                response.json().then(function(resp_finishmission){
                    setLoading(false);
                    if(resp_finishmission.status==true){
                        document.getElementById('finish_mission1').innerHTML=i18next.t('RemoveSuccess');
                        document.getElementById('finish_mission1').classList.remove('d-none');
                        document.getElementById('finish_mission1').classList.add('alert-info');
                        navigate(-1);
                    }
                    else{
                        document.getElementById('finish_mission1').innerHTML=i18next.t('RemoveFailed');
                        document.getElementById('finish_mission1').classList.remove('d-none');
                        document.getElementById('finish_mission1').classList.add('alert-danger');
    
                    }    
                })
            })        
    
      }

    return (
        <>

            <div class="modelround" className="modal">
                <Modal
                    show={show}
                    onHide={handleClose}
                    backdrop="static"
                    keyboard={false}
                >
                    <Modal.Header class="modelstyle" closeButton>
                        <Modal.Title class="modelstyle">{en.RewardDialog.veryality}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className={cookies.lang  === 'ar' ? 'modelstyle text-end p-3 rtl' : 'modelstyle text-start p-3 ltr'}>
                    {i18next.t('note')} 
                                <OverlayTrigger
                                      key='top'
                                      placement='top'
                                      overlay={
                                        <Tooltip>
                                        { teamName}
                                        </Tooltip>
                                        }
                                        >
                                        
                                        <span className={cookies.lang  === 'ar' ? 'menu_rtl text-primary' : 'menu_ltr text-primary'}>
                                        { teamName.length>27?teamName.substring(0,27)+"...":teamName}
                                        </span>
                                        
                                </OverlayTrigger>
                                .
                    </Modal.Body>
                    <Modal.Footer >
                            <Button type='submit' className='bg_blue w-40' variant="primary" onClick={handlesubmit}>
                            {i18next.t('remove')}
                            </Button>
                            <Button className='white-outline-btn1' onClick={handleClose}>
                            {i18next.t('close')}
                            </Button>
                            { loading==true ? ( <div className="w-100 mt-3 mb-2 m-auto">
                              <CircularProgress />
                            </div>)
                            :
                            <div id='finish_mission1' className='w-100 mt-3 mb-2 m-auto d-none p-3'></div>
                                    }
                    </Modal.Footer>
                </Modal>
            </div>
        </>
    );
}

