import * as React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import Head from "../layout/Head";
import Footer from "../layout/Footer";
import en from "../resources/en.json";
import { useDispatch, useSelector } from "react-redux";
import config from "../config.json";
import Usernav from "../layout/Usernav";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import {
  CardHeader,
  Avatar,
  CircularProgress,
  Button,
  Divider,
} from "@mui/material";
import CircleChart from "./CircleChart";
import RemoveTeamModal from "./RemoveTeamModal";
import UpdateTeamModal from "./UpdateTeamModal";
import InviteModal from "./InviteModal";
import AssignTournamentToTeamModal from "../ESport/AssignTournamentToTeamModal";
import {
  UsergroupAddOutlined,
  SettingOutlined,
  UserOutlined,
  MenuOutlined,
  StarOutlined,
  GroupOutlined,
  CloseOutlined,
  CalendarOutlined,
  FieldTimeOutlined,
  EnvironmentOutlined,
  ScheduleOutlined,
  IdcardOutlined,
  GlobalOutlined,
} from "@ant-design/icons";
import ReactLoading from "react-loading";
import {
  LinearProgressProps,
  LinearProgress,
  Typography,
  Box,
} from "@mui/material";
import { red } from "@mui/material/colors";
import { useCookies } from "react-cookie";
import i18next from "../i18n/config";
import SpeedDial from "@mui/material/SpeedDial";
import SpeedDialIcon from "@mui/material/SpeedDialIcon";
import SpeedDialAction from "@mui/material/SpeedDialAction";
import { styled } from "@mui/material/styles";
import { useHistory, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

const actions = [
  {
    icon: <img src="../assets/imgs/kidwatch.png" width={"100%"} />,
    name: (
      <>
        member1
        <br /> 20%
      </>
    ),
  },
  {
    icon: <img src="../assets/imgs/kidwatch.png" width={"100%"} />,
    name: (
      <>
        member2
        <br /> 20%
      </>
    ),
  },
  {
    icon: <img src="../assets/imgs/kidwatch.png" width={"100%"} />,
    name: (
      <>
        member3
        <br /> 20%
      </>
    ),
  },
];

const StyledSpeedDial = styled(SpeedDial)(({ theme }) => ({
  position: "absolute",
  top: -80,
  "&.MuiSpeedDial-directionUp, &.MuiSpeedDial-directionLeft": {
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  "&.MuiSpeedDial-directionDown, &.MuiSpeedDial-directionRight": {
    top: theme.spacing(2),
    left: theme.spacing(2),
  },
}));

function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      {/* <Box sx={{ minWidth: 25 }}>
        <Typography variant="body2">{`${Math.round(props.value)}`}</Typography>
      </Box> */}
      <Box className="review_progress" sx={{ width: "80%", mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35, color: "#F2C94C" }}>
        <Typography variant="body2">
          <span style={{ padding: "5px" }}>{props.value}%</span>
        </Typography>
      </Box>
    </Box>
  );
}

export default function InprogressTeamDetails() {
  let BaseUrl = config.BaseUrl;
  const navigate = useNavigate();
  const location = useLocation();
  const username = useSelector((state) => state.appReducer.username);
  const token = useSelector((state) => state.appReducer.token);
  const id = useSelector((state) => state.appReducer.id);
  let [tournaments, setTournaments] = useState([]);
  //   const teamId = location.state.teamId;
  const [loading, setLoading] = useState(false);
  const [inviteLoading, setInviteLoading] = useState(false);
  const { i18n } = useTranslation();
  const [cookies, setCookie] = useCookies(["lang"]);
  const [show, setshow] = useState(false);
  const [teams, setTeams] = useState([]);

  let [refresh, setRefresh] = useState(false);
  const [hidden, setHidden] = React.useState(false);

  const handleHiddenChange = (event) => {
    setHidden(event.target.checked);
  };
  const handleChange = React.useCallback((newValue) => {
    setRefresh(true);
    localStorage.removeItem("TournamentID");
  });

  const params = useParams();
  var team = teams.find((t) => Number(t.id) == Number(params.tournament));

  useEffect(() => {
    if (cookies && cookies.lang) {
      i18n.changeLanguage(cookies.lang);
    } else {
      i18n.changeLanguage("en");
    }
  }, []);

  useEffect(() => {
    if (token === "") {
      navigate("/veryalityApp");
    }
    setLoading(true);
    fetch(`${BaseUrl}Team_InProgress/${params.id}`, {
      method: "get",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
        "Accept-Language": cookies.lang,
      },
    }).then(function (response11) {
      response11.json().then(function (resp) {
        setLoading(false);
        if (resp.status == true) {
          setTeams(resp.data);
        }
      });
    });
  }, [cookies.lang]);

  if (loading || !team) {
    return (
      <div className="load-page">
        <ReactLoading type="spin" color="#F9AF22" className="load" />
      </div>
    );
  }
  return (
    <div>
      <Head />

      <section
        className="container-fluid pdash bg_black pt-4 m-0 "
        style={{ paddingLeft: 30, paddingRight: 35 }}
      >
        <Usernav username={username} />
        <br /> <br />
        <div
          className="container bg-grey progress_card  p-4 mt-5"
          dir={cookies.lang === "ar" ? "rtl" : ""}
        >
          <img
            src={team.logo ? team.logo : "../assets/imgs/account_8.png"}
            style={{
              marginTop: "-110px",
              marginBottom: "20px",
              width: "200px",
              height: "200px",
              borderRadius: "50%",
            }}
          />

          <div className="row mb-3">
            <div className="col-md-6 col-sm-12 pt-0">
              <Typography
                className={
                  cookies.lang === "ar" ? "text-end rtl " : "text-start "
                }
                variant="h5"
              >
                <span title={team.name} className="_tooltip">
                  {team.name.length > 24
                    ? team.name.substring(0, 24) + "..."
                    : team.name}
                </span>
              </Typography>
              <Divider sx={{ width: "50%", mt: 1, mb: 2 }} />
              <div
                className={
                  cookies.lang === "ar"
                    ? "text-end mb-3 rtl"
                    : "text-start mb-3"
                }
              >
                <Typography variant="body1">
                  <span className="progress_title">
                    <CalendarOutlined style={{ margin: "4px" }} />
                    {i18next.t("challenge_start")}
                  </span>
                  <span>
                    {team.challenges.length > 0
                      ? team.challenges[0].challeng_startDate
                      : "-"}{" "}
                    {team.challenges.length > 0 ? i18next.t("to") : ""}{" "}
                    {team.challenges.length > 0
                      ? team.challenges[0].challeng_endDate
                      : ""}
                  </span>
                </Typography>
                <Typography variant="body1">
                  <span className="progress_title">
                    <ScheduleOutlined style={{ margin: "4px" }} />
                    {i18next.t("tournament_date")}
                  </span>
                  <span>{team.TournamentDate}</span>
                </Typography>
                <Typography variant="body1">
                  <a
                    href={`https://www.google.com/maps/place/${team.Tournamentlatitude},${team.Tournamentlongitude}`}
                    target="_blank"
                    style={{ color: "#6b7996" }}
                  >
                    <span className="progress_title">
                      <EnvironmentOutlined style={{ margin: "4px" }} />
                      {i18next.t("location")}
                    </span>
                    <span style={{ color: "white" }}>
                      {team.TournamentLocation}
                    </span>
                  </a>
                </Typography>
                <Typography variant="body1">
                  <span className="progress_title">
                    <IdcardOutlined style={{ margin: "4px" }} />
                    {i18next.t("tournament_by")}
                  </span>
                  <span>{team.TournamentBy}</span>
                </Typography>

                <Typography variant="body1">
                  <a
                    href={team.TournamentLink}
                    target="_blank"
                    style={{ color: "#6b7996" }}
                  >
                    <span className="progress_title">
                      <GlobalOutlined style={{ margin: "4px" }} />
                      {i18next.t("website")}
                    </span>
                    <span style={{ color: "white" }}>
                      {team.TournamentLink}
                    </span>
                  </a>
                </Typography>
              </div>
            </div>
            <div
              className="col-md-6 col-sm-12 p-4"
              style={{
                backgroundColor: "#f2c84c46",
                textAlign: "center",
                borderRadius: "44px",
              }}
            >
              <div
                className={
                  cookies.lang === "ar"
                    ? "text-end rtl mb-3"
                    : "text-start mb-3"
                }
              >
                <Typography variant="body1">
                  <span className="progress_title">
                    {i18next.t("challenge_type")}
                  </span>
                  <span>
                    {team.challenges.length > 0
                      ? team.challenges[0].challeng_name
                      : "-"}
                  </span>
                </Typography>
                <Typography variant="body1">
                  <span className="progress_title">
                    {i18next.t("challenge_mode")}
                  </span>
                  <span>
                    {team.challenges.length > 0
                      ? team.challenges[0].challeng_type == "1"
                        ? i18next.t("for_team")
                        : i18next.t("for_gamer")
                      : "-"}
                  </span>
                </Typography>
                <Typography variant="body1">
                  <span className="progress_title"> {i18next.t("Goal")}</span>
                  <span>
                    {team.challenges.length > 0 ? team.challenges[0].Goal : "-"}
                  </span>
                </Typography>
              </div>
              <LinearProgressWithLabel value={team.teamInprogress} />
            </div>
          </div>

          <div className="m-auto">
            <div className="m-auto">
              {team.members && team.members.length > 0
                ? team.members.map((mem) => (
                    <OverlayTrigger
                      key="top"
                      placement="top"
                      overlay={
                        <Tooltip>
                          {
                            <>
                              {mem.name}
                              <br />
                              {mem.percentage}%
                            </>
                          }
                        </Tooltip>
                      }
                    >
                      <img
                        className="hover_icon"
                        src={
                          mem.logo ? mem.logo : "../assets/imgs/kidwatch.png"
                        }
                        style={{
                          width: "50px",
                          height: "50px",
                          borderRadius: "50%",
                          margin: "5px",
                        }}
                      />
                    </OverlayTrigger>
                  ))
                : ""}
            </div>
          </div>
        </div>
      </section>
      <div
        className="container-fluid text-start back p-5"
        style={{ paddingLeft: "25px" }}
      >
        <a className="btn blue-btn back_a" onClick={() => navigate(-1)}>
          <span className="p-2">{i18next.t("back")} </span>
          <span>
            <svg
              width="12"
              height="16"
              viewBox="0 0 12 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7.99245 3.65333C8.72578 3.65333 9.32578 3.05333 9.32578 2.32C9.32578 1.58667 8.72578 0.986668 7.99245 0.986668C7.25911 0.986668 6.65911 1.58667 6.65911 2.32C6.65911 3.05333 7.25911 3.65333 7.99245 3.65333ZM5.59245 12.92L6.25911 9.98667L7.65911 11.32V15.32H8.99245V10.32L7.59245 8.98667L7.99245 6.98667C8.85911 7.98667 10.1924 8.65333 11.6591 8.65333V7.32C10.3924 7.32 9.32578 6.65333 8.79245 5.72L8.12578 4.65333C7.85911 4.25333 7.45911 3.98667 6.99245 3.98667C6.79245 3.98667 6.65911 4.05333 6.45911 4.05333L2.99245 5.52V8.65333H4.32578V6.38667L5.52578 5.92L4.45911 11.32L1.19245 10.6533L0.925781 11.9867L5.59245 12.92Z"
                fill="white"
              />
            </svg>
          </span>
        </a>
      </div>
      <Footer bg="bg_black" />
    </div>
  );
}
