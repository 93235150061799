import * as React from "react";
import { NavLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { useCookies } from "react-cookie";
import en from "../resources/en.json";
import ar from "../resources/ar.json";
import config from "../i18n/config";
import i18next from "../i18n/config";
import { useTranslation } from "react-i18next";
import { withTranslation } from "react-i18next";
import logo from "../imgs/logo.png";
import InfoDialog from "../layout/TermsInfoDialog";
import TermsPDF from "../TermsAndConditions/TermsAndConditions.pdf";

export default function Footer(props) {
  const [cookies, setCookie] = useCookies(["lang"]);
  const [infoPop, setinfoPop] = useState(false);
  const navigate=useNavigate();
  useEffect(() => {
    if (infoPop == true) {
      setTimeout(() => {
        setinfoPop(false);
      }, 1000);
    }
  }, [infoPop]);
  return (
    <div class={`container-fluid p-0 my-5 mb-0 mt-0 ${props.bg}`}>
      {/* Footer */}
      <footer
        className={
          cookies.lang === "ar"
            ? "text-center text-lg-start text-white rtl"
            : "text-center text-lg-start text-white ltr"
        }>
        {/* Grid container */}
        <div class="container p-4 pb-5 pt-5">
          {/* Section: Links */}
          <InfoDialog show={infoPop} />
          <section class="">
            {/*Grid row*/}
            <div class="row pb-5">
              {/* Grid column */}
              <div
                className={
                  cookies.lang === "ar"
                    ? "menu_rtl col-md-3 col-lg-3 col-xl-3 mx-auto mt-3"
                    : "menu_ltr col-md-3 col-lg-3 col-xl-3 mx-auto mt-3"
                }>
                <img
                  src={logo}
                  className="mb-5 img-fluid"
                  width="163px"
                  height="40px"></img>
                <p>{i18next.t("title")}</p>
              </div>
              {/*  Grid column */}

              {/* Grid column */}
              <div
                className={
                  cookies.lang === "ar"
                    ? "menu_rtl col-md-2 col-lg-2 col-xl-2 mx-auto mt-3"
                    : "menu_ltr col-md-2 col-lg-2 col-xl-2 mx-auto mt-3"
                }>
                <h6 class=" mb-md-5 font-weight-bold">
                  {" "}
                  {i18next.t("Service")}
                </h6>
                 <p>
              <a class="text-white"  href='https://scribehow.com/shared/Veryaliry_Parent_Portal__Hz0uN_O8R0avI8Xan7ijdg' target='_blank'>{i18next.t('veryality_guide')}</a>
            </p>
                {/* <p>
              <a class="text-white">{i18next.t('product_features')}</a>
            </p> */}
                {/* <p>
              <a class="text-white">{i18next.t('faq')}</a>
            </p> */}
                <p>

                  <a
                     className="text-white">{i18next.t("contact")}</a>

                </p>

                <p>

                  <a onClick={()=>navigate('/FAQ')}
                      className="text-white">{i18next.t("faq")}</a>

                </p>
              </div>
              {/* Grid column */}

              {/* Grid column */}
              <div
                className={
                  cookies.lang === "ar"
                    ? "menu_rtl col-md-3 col-lg-2 col-xl-2 mx-auto mt-3"
                    : "menu_ltr col-md-3 col-lg-2 col-xl-2 mx-auto mt-3"
                }>
                <h6 class=" mb-md-5 font-weight-bold">{i18next.t("legal")}</h6>
                <p>
                  {/*<a class="text-white" onClick={() => setinfoPop(true)}> {i18next.t("terms")} </a>*/}
                  <a class="text-white" onClick={() => window.open("/TermsAndConditions.pdf",'_blank')}> {i18next.t("terms")} </a>
                </p>
                <p>
                  <a href='https://veryality.com/privacy-policy-for-veryality/' target='_blank' class="text-white">{i18next.t("privacy_policy")}</a>
                </p>
              </div>

              <div
                  className={
                    cookies.lang === "ar"
                        ? "menu_rtl col-md-3 col-lg-2 col-xl-2 mx-auto mt-3"
                        : "menu_ltr col-md-3 col-lg-2 col-xl-2 mx-auto mt-3"
                  }>
                <h6 className=" mb-md-5 font-weight-bold">{i18next.t("our_games")}</h6>
                <p>
                  {/*<a class="text-white" onClick={() => setinfoPop(true)}> {i18next.t("terms")} </a>*/}
                  <a className="text-white"
                     onClick={() => window.open("https://www.roblox.com/games/16743783458/Vracer-DEMO", '_blank')}> {i18next.t("V-Racer")} </a>
                </p>

              </div>

              {/* Grid column */}

              {/* Grid column */}
              {/* <div  className={cookies.lang  === 'ar' ? 'menu_rtl col-md-4 col-lg-3 col-xl-3 mx-auto mt-3' : 'menu_ltr col-md-4 col-lg-3 col-xl-3 mx-auto mt-3'} >
            <h6 class=" mb-md-5 font-weight-bold">{i18next.t('newsletter')}</h6>
            <p>{i18next.t('notificaton_when_goes_live')}</p>
            <form className={cookies.lang  === 'ar' ? 'ltr' : ''}>
                <div class="input-group mt-4">
                    <input type="text" class="form-control" placeholder={i18next.t('mail_placeholder')}
                     />
                    <span class="input-group-text">
                        <a >
                        <svg width="20" height="14" viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M13 0L11.59 1.41L16.17 6H0V8H16.17L11.58 12.59L13 14L20 7L13 0Z" fill="#666666"/>
                        </svg></a>
                    </span>

                </div>

            </form>
          </div> */}
              {/*Grid column */}
            </div>
            {/*Grid row*/}
          </section>



          {/* Section: Links*/}

          <hr class=" hr-yellow" />


          {/* Section: Copyright */}
        </div>
        {/* Grid container */}
      </footer>
      {/* Footer */}
    </div>
  );
}
