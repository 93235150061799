import * as React from 'react';
import { NavLink} from 'react-router-dom';
import {useNavigate} from 'react-router-dom';
import { useState, useEffect,useMemo } from "react";
import en from '../../resources/en.json'
import AlertDialog from '../../layout/AlertDialog';
import Head from "../../layout/Head";
import Nav from "../../layout/Nav";
import Footer from "../../layout/Footer";
import config from "../../config.json";
import { useDispatch, useSelector } from "react-redux";
import {Auth} from "../../redux/Actions/AuthActions";


export default function NewRegisterGamer()  {
  const dispatch = useDispatch();
  const token = useSelector((state) => state.appReducer.token);

  const navigate = useNavigate();
  //const location = useLocation();
  let [count1, setcount1] = useState(0);
  let [idd1, setid1] = useState(0);
  const [form1, setform1] = useState({
    Parent_id: "",
    password:"",
    RoleID: "3",
    WithRole: true

});

const [pop, setpop] = useState(false);
let [watch1, setwatch1] = useState('');

let BaseUrl=config.BaseUrl;

const openInNewTab = (url) => {
    const newWindow = window.open(url, '_blank',  "width=500, height=500")

  }


function submit1(){
  if(form1.RoleID=='3' && watch1==''){
    setpop(true);
  }
  else{

   document.getElementById('form_parent').classList.add('was-validated');
  if (!document.getElementById('password_parent').value.match(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{7,20}$/)){
    document.getElementById('pass_parent').style.display='block';
  }
  if(document.getElementById('co-password_parent').value!=document.getElementById('password_parent').value){
    document.getElementById('confirm_parent').style.display='block';
  }

  if (document.getElementById('form_parent').checkValidity() 
  && document.getElementById('co-password_parent').value==document.getElementById('password_parent').value 
  && document.getElementById('password_parent').value.match(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{7,20}$/)) {

  if(watch1=='fitbit'){
    fetch(`${BaseUrl}connectToFitbiWithPassword/${form1.password}`,{
      method:'get',
      body:JSON.stringify(),
      headers:{
          'Accept':'application/json',
          'Content-Type':'application/json'
      } 
    }).then(function(response){
        response.json().then(function(signresp){
            if(signresp){
                openInNewTab(signresp.data);

                document.getElementById('sign_parent').style.display='none';
                document.getElementById('connect_parent').classList.add('disabled');
                document.getElementById('message_parent').style.display='none';
                document.getElementById("message_parent").innerHTML='';
            
                fetch(`${BaseUrl}loginn`,{
                    method:'post',
                    body:JSON.stringify({
                        email:form1.email,
                        password:form1.password
                    }),
                    headers:{
                        'Accept':'application/json',
                        'Content-Type':'application/json'
                    } 
                  }).then(function(response){
                    response.json().then(function(loginresp){
                        dispatch(Auth(loginresp.user.token,loginresp.user.name));
                        setid1(loginresp.user.id);

                            var seconds =30;
                            // Update the count down every 1 second
                            var x = setInterval(function() {
                              // Time calculations for days, hours, minutes and seconds
                                --seconds;
                            
                              // Display the result in the element with id="demo"
                              document.getElementById("demo_parent").innerHTML = en.sign.watchconnect_within +seconds + "s ";
                            
                              // If the count down is finished, write some text
                              if (seconds < 0) {
                                clearInterval(x);
                                document.getElementById("demo_parent").innerHTML = en.sign.time_expired;
                              }
                            }, 1000);
                            var y = setTimeout(function(){
                              fetch(`${BaseUrl}checkFitbitAccess/${loginresp.id}`,{
                                method:'get',
                                headers:{
                                  'Accept':'application/json',
                                  'Authorization': `Bearer ${token}`
                                } 
                              }).then(function(response33){
                                  response33.json().then(function(check_fitbitResp){
                                   if(Array.isArray(check_fitbitResp) && check_fitbitResp[0]=='user connect to fitbit successfully') {
                                    clearInterval(x);
                                    document.getElementById('connect_parent').style.display='none';
                                    document.getElementById("demo_parent").innerHTML=en.sign.account_prepare;
                                    navigate('/dgamer', {state:{id:loginresp.id,username:form1.name}});
            
            
                                    }
                                   if("UserError" in check_fitbitResp[0]){
                                       document.getElementById('connect_parent').style.display='block';
                                       document.getElementById('connect_parent').classList.remove('disabled');      
                                       document.getElementById('message_parent').style.display='block';
                                      if(check_fitbitResp[0].UserError.status=='This user does not have a wearable account'){
                                        document.getElementById("message_parent").innerHTML = en.sign.watchlongtime_msg;
                                      }
                                      else{
                                        document.getElementById("message_parent").innerHTML = en.sign.watchfailed_msg;
                        
                                      }             
                                    }
                                  })
                                })
                            }, 30000);
                        //   }
                      })
                    
            })
            }
         
        })
    })
    }
  }
   }
 
}


return (
         <div>
             <Head/>
             <section className='sign container-fluid p-2 pb-5'>
                 <Nav/>
                 <div className='flash1'></div>
                 <div className='flash2'></div>
                 <div className='flash3'></div>
                <AlertDialog show={pop} Modalcontent={en.sign.chooseWatch_pop}/>
                  <div className='d-inline-flex p-2 d-flex justify-content-center  pt-3 pb-3'>
                         <h2 >{en.sign.sign_GamerCaptionRegister}</h2>
                 </div>
                 <div className=' m-auto form-white'>
                     <hr className="my-3 hr-yellow" />
                 </div>
                    <form className=' m-auto form-white' id='form_parent'>
                     
                        <div class="mb-3">
                            <input type="password" class="form-control" id="password_parent" placeholder={en.sign.password_placeholder} onFocus={(e) => e.target.placeholder = ""} onBlur={(e) => e.target.placeholder = en.sign.password_placeholder} name="password" onChange={e=>setform1({...form1,password:e.target.value})} required/>
                            <div className='invalid-feedback text-start text-danger'>{en.sign.password_validation} </div>
                            <div className='invalid-feedback text-start text-danger' id='pass_parent'>{en.sign.password_validation_contain}</div>

                        </div>
                        <div class="mb-3">
                            <input type="password" class="form-control" id='co-password_parent'  placeholder={en.sign.confirmPassword_placeholder} onFocus={(e) => e.target.placeholder = ""} onBlur={(e) => e.target.placeholder = en.sign.confirmPassword_placeholder} name="" required/>
                            <div className='invalid-feedback text-start text-danger'>{en.sign.confirmpassword_validation} </div>
                            <div className='invalid-feedback text-start text-danger' id='confirm_parent'>{en.sign.confirmpassword_match} </div>
                        </div>


                        

                        <div class="mb-3 input-group " id='watch_parent'>
                            <span class="input-group-text select-watch">
                                <img src='../assets/imgs/watch.png' className=''></img>
                            </span>
                            <select name="" className='form-select '  id="" onChange={e=>{setwatch1(e.target.value)}}>
                               <option className='' value=''>{en.sign.select_device}</option>
                                <option className='' value="fitbit" >Fitbit </option>
                            </select>                      
                        </div>

                       
                        <div className=' m-auto form-white'>
                            <hr className="my-3 hr-yellow" />
                        </div>
                        <p style={{color:"white",textAlign:"left"}}>{en.sign.sign_gamerRegisterSammary}</p>
                        <div className=' m-auto form-white'>
                            <hr className="my-3 hr-yellow" />
                        </div>
                        <a className="btn yellow-btn mr-5 disabled"  id='connect_parent'  onClick={submit1}>
                            <span className="m-3">{en.sign.linkwatch_btn}</span>
                        </a>
                        <a className="btn yellow-btn mr-5 " id='sign_parent'  onClick={submit1}>
                            <span className="m-3">{en.sign.creategamer_btn}</span>
                            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M7.66659 1.66668V3.44668L6.99992 4.11334L6.33325 3.44668V1.66668H7.66659ZM12.3333 6.33334V7.66668H10.5533L9.88659 7.00001L10.5533 6.33334H12.3333ZM3.44659 6.33334L4.11325 7.00001L3.44659 7.66668H1.66659V6.33334H3.44659ZM6.99992 9.88668L7.66659 10.5533V12.3333H6.33325V10.5533L6.99992 9.88668ZM8.99992 0.333344H4.99992V4.00001L6.99992 6.00001L8.99992 4.00001V0.333344ZM13.6666 5.00001H9.99992L7.99992 7.00001L9.99992 9.00001H13.6666V5.00001ZM3.99992 5.00001H0.333252V9.00001H3.99992L5.99992 7.00001L3.99992 5.00001ZM6.99992 8.00001L4.99992 10V13.6667H8.99992V10L6.99992 8.00001Z" fill="#100E23"/>
                            </svg>
                        </a>
                    </form>
                    <div class="alert alert-danger mt-5" id='message_parent'>

                    </div>
                    <p id="demo_parent" className='text-white p-2 mt-3 '></p>
                 <NavLink className='d-inline-flex p-2 d-flex justify-content-center nav-link text-white link'  to="/RegisterAccountType">{en.sign.sign_Back}</NavLink>

                 <NavLink className='d-inline-flex p-2 d-flex justify-content-center nav-link text-white link'  to="/veryalityApp">{en.sign.have_account}</NavLink>

             </section>


             <Footer bg='bg_blue'/>
        </div>
      
  );
}
