import * as React from "react";
import { Component } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import { Modal, Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import en from "../resources/en.json";
import config from "../config.json";
import { useDispatch, useSelector } from "react-redux";
import TextField from "@mui/material/TextField";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";

import { MobileDateTimePicker } from "@mui/x-date-pickers/MobileDateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Game_small2 from "../layout/Game_small2";
import { SetTournamentID } from "../redux/Actions/AuthActions";
import CircularProgress from "@mui/material/CircularProgress";
// import config from "../config.json";
import { useCookies } from "react-cookie";
import { useTranslation } from "react-i18next";
import { withTranslation } from "react-i18next";
import i18next from "../i18n/config";
import Game_new2 from "../layout/Game_new2";
import ReactLoading from "react-loading";

export default function AssignTournamentToTeamModal(props) {
  let BaseUrl = config.BaseUrl;
  let fullToday = new Date();
  let dd = String(fullToday.getDate()).padStart(2, "0");
  let mm = String(fullToday.getMonth() + 1).padStart(2, "0"); //January is 0!
  let yyyy = fullToday.getFullYear();
  let today = yyyy + "-" + mm + "-" + dd;
  let endDate = yyyy + 2 + "-" + mm + "-" + dd;
  const token = useSelector((state) => state.appReducer.token);
  const id = useSelector((state) => state.appReducer.id);
  let [show, setShow] = useState(props.show);
  let [Tournaments, setTournament] = useState([]);
  const [SelectedItem, setSelectedItem] = React.useState(-1);
  const TournamentID = useSelector((state) => state.appReducer.TournamentID);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [cookies, setCookie] = useCookies(["lang"]);
  const [isLoading, setLoading1] = useState(true);

  let [Assignteam, setAssignteam] = useState({
    team_id: 0,
    tournaments: [],
    StartDate: "",
    EndDate: "",
  });
  useEffect(() => {
    if (props.show == true) {
      setShow(props.show);
      // setAssignteam({...Assignteam,team_id: props.teamID});
      // setAssignteam({...Assignteam,tournaments: JSON.parse(localStorage.getItem('TournamentID'))});
      // setAssignteam({...Assignteam,tournaments:TournamentID });
    }
  }, [props.show]);

  useEffect(() => {
    if (props.teamID != null) {
      setAssignteam({ ...Assignteam, team_id: props.teamID });
    }
  }, [props.teamID]);

  const handleClose = () => {
    setShow(false);
    // dispatch(SetTournamentID([]));
    // localStorage.removeItem('TournamentID');
    props.onChange("david");
  };

  useEffect(() => {
    setLoading1(true);
    fetch(`${BaseUrl}tournaments/${id}`, {
      method: "get",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
        "Accept-Language": cookies.lang,
      },
    }).then(function (response11) {
      response11.json().then(function (Resp) {
        setLoading1(false);
        setTournament(Resp.data);
      });
    });
  }, []);

  var valid = document.getElementsByClassName("mission_valid1");
  const handlesubmit = (e) => {
    document.getElementById("create_assign").innerHTML = "";
    document.getElementById("create_assign").classList.add("d-none");
    if (
      document
        .getElementById("create_assign")
        .classList.contains("alert-info") == true
    ) {
      document.getElementById("create_assign").classList.remove("alert-info");
    } else if (
      document
        .getElementById("create_assign")
        .classList.contains("alert-danger") == true
    ) {
      document.getElementById("create_assign").classList.remove("alert-danger");
    }
    var _StartDate = Assignteam["StartDate"];
    var _EndDate = Assignteam["EndDate"];
    if (
      _StartDate === "" ||
      _EndDate === "" ||
      Assignteam.tournaments.length <= 0
    ) {
      if (Assignteam.tournaments.length <= 0)
        document.getElementById("teamId_valid").classList.remove("d-none");
      if (_StartDate === "") valid[0].classList.remove("d-none");
      if (_EndDate === "") valid[1].classList.remove("d-none");
    } else {
      document.getElementById("teamId_valid").classList.add("d-none");
      valid[0].classList.add("d-none");
      valid[1].classList.add("d-none");
      setLoading(true);
      setSelectedItem(SelectedItem + 1);
      fetch(`${BaseUrl}AssignTeamToTournament`, {
        method: "post",
        body: JSON.stringify(Assignteam),
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          "Accept-Language": cookies.lang,
        },
      }).then(function (response) {
        response.json().then(function (resp_assign) {
          setLoading(false);
          setAssignteam({
            team_id: props.teamID,
            tournaments: [],
            StartDate: "",
            EndDate: "",
          });
          // setSelectedItem(-1);
          if (resp_assign.status == true) {
            // if(resp_assign.data[0].includes('successfully')){
            document.getElementById("create_assign").innerHTML =
              resp_assign.msg;
            // data[0];
            // en.Esport.success;
            document.getElementById("create_assign").classList.remove("d-none");
            document
              .getElementById("create_assign")
              .classList.add("alert-info");
            // }
            // else{
            //   document.getElementById("create_assign").innerHTML =
            //   resp_assign.data[0];
            // document.getElementById("create_assign").classList.remove("d-none");
            // document
            //   .getElementById("create_assign")
            //   .classList.add("alert-danger");
            // }
          } else if (resp_assign.status == false) {
            document.getElementById("create_assign").classList.remove("d-none");
            document
              .getElementById("create_assign")
              .classList.add("alert-danger");
            if (resp_assign.errNum == "E0100") {
              document.getElementById("create_assign").innerHTML =
                resp_assign.msg;
            } else
              document.getElementById("create_assign").innerHTML =
                i18next.t("assign_failed");
          }
        });
      });
    }
  };

  const handleChangeGame = React.useCallback((newValue) => {
    setAssignteam({ ...Assignteam, tournaments: newValue });
  });

  if (isLoading) {
    return (
      <div className="load-page">
        <ReactLoading type="spin" color="#F9AF22" className="load" />
      </div>
    );
  }

  return (
    <>
      <div class="modelround" className="modal">
        <Modal
          show={show}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}>
          <Modal.Header class="modelstyle" closeButton>
            <Modal.Title class="modelstyle">
              {en.RewardDialog.veryality}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body
            className={
              cookies.lang === "ar"
                ? "rtl modelstyle text-start p-3"
                : "ltr modelstyle text-start p-3"
            }>
            <form className=" m-auto form-white" id="form-team">
              <div className="row " id="kid_assign">
                <div className="col-md-12 kid-char pb-0">
                  <div className="row_new row_new_new m-auto ">
                    {Tournaments && Tournaments.length > 0 ? (
                      Tournaments.map((item, index) => (
                        // <div >
                        // <div className='col-md-2'>
                        //   <Game_new />
                        // </div>
                        <div className=" col-md-6 col-sm-12 col-12 p-2 ">
                          <Game_small2
                            item={item}
                            active="1"
                            img={item.logo}
                            index={index}
                            itemID={item.id}
                            onChange={handleChangeGame}
                            SelectedItem={SelectedItem}
                          />
                        </div>
                        // <Game_small name={item.name} active='1' img={item.logo}  index={index} itemID={item.id} onChange={handleChangeGame} SelectedItem={SelectedItem}/>

                        // </div>
                      ))
                    ) : (
                      <div className="  text-white text-center container  ">
                        {i18next.t("NoItem")}
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div class="mb-3 text-center">
                <div
                  className={
                    cookies.lang === "ar"
                      ? "text-end text-danger p-1 small d-none"
                      : "text-start text-danger p-1 small d-none"
                  }
                  id="teamId_valid">
                  {i18next.t("tournamentId_validation")}{" "}
                </div>
              </div>

              <div class="mb-3 input-group">
                <span class="input-group-text select-watch text-white w-50 ">
                  {i18next.t("assignStartdate")}
                </span>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <MobileDateTimePicker
                    className="form-control point"
                    // inputFormat="MM/DD/YYYY HH:mm:ss"
                    value={Assignteam.StartDate}
                    minDate={today}
                    maxDate={
                      Assignteam.EndDate != "" ? Assignteam.EndDate : endDate
                    }
                    onChange={(e) => {
                      setAssignteam({
                        ...Assignteam,
                        StartDate:
                          e?.$y +
                          "-" +
                          (e?.$M + 1) +
                          "-" +
                          e?.$D +
                          " " +
                          e?.$H +
                          ":" +
                          e?.$m +
                          ":" +
                          e?.$s,
                      });
                    }}
                    required
                    renderInput={(params) => (
                      <TextField {...params} placeholder="mm/dd/yyyy" />
                    )}
                  />
                </LocalizationProvider>
              </div>
              <div
                className={
                  cookies.lang === "ar"
                    ? "text-danger text-end p-1 small mission_valid1 d-none"
                    : "text-danger text-start p-1 small mission_valid1 d-none"
                }>
                {i18next.t("AssignStartdate_valid")}
              </div>
              <div class="mb-3 input-group">
                <span class="input-group-text select-watch text-white w-50 ">
                  {i18next.t("assignEnddate")}
                </span>

                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <MobileDateTimePicker
                    className="form-control point"
                    // inputFormat="MM/DD/YYYY"
                    value={Assignteam.EndDate}
                    minDate={
                      Assignteam.StartDate != "" ? Assignteam.StartDate : today
                    }
                    maxDate={endDate}
                    onChange={(e) => {
                      setAssignteam({
                        ...Assignteam,
                        EndDate:
                          e?.$y +
                          "-" +
                          (e?.$M + 1) +
                          "-" +
                          e?.$D +
                          " " +
                          e?.$H +
                          ":" +
                          e?.$m +
                          ":" +
                          e?.$s,
                      });
                    }}
                    required
                    renderInput={(params) => (
                      <TextField {...params} placeholder="mm/dd/yyyy" />
                    )}
                  />
                </LocalizationProvider>
              </div>
              <div
                className={
                  cookies.lang === "ar"
                    ? "text-danger text-end p-1 small mission_valid1 d-none"
                    : "text-danger text-start p-1 small mission_valid1 d-none"
                }>
                {i18next.t("AssignEnddate_valid")}
              </div>
              <div
                id="create_assign"
                className="w-100 mt-4 mb-2 m-auto d-none p-3 text-center"></div>
            </form>
          </Modal.Body>
          <Modal.Footer>
            <Button
              type="submit"
              className="bg_blue w-40"
              variant="primary"
              onClick={handlesubmit}>
              {i18next.t("Assign")}
            </Button>
            <Button className="white-outline-btn1" onClick={handleClose}>
              {i18next.t("close")}
            </Button>
            {loading == true ? (
              <div className="w-100 mt-3 mb-2 m-auto">
                <CircularProgress />
              </div>
            ) : (
              <div
                id="finish_mission1"
                className="w-100 mt-3 mb-2 m-auto d-none p-3"></div>
            )}
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
}
