import * as React from "react";
import { NavLink } from "react-router-dom";
import Head from "../../layout/Head";
import Nav from "../../layout/Nav";
import Footer from "../../layout/Footer";
import en from "../../resources/en.json";
import SignGamer from "./SignGamer";
import { useNavigate, useLocation } from "react-router-dom";
import Usernav from "../../layout/Usernav";
import { useState, useEffect } from "react";
import config from "../../config.json";
import InfoDialog from "../../layout/InfoDialog";
import { useDispatch, useSelector } from "react-redux";
import { useCookies } from "react-cookie";
import i18next from "../../i18n/config";
import { useTranslation } from "react-i18next";

export default function SignChild(props) {
  const dispatch = useDispatch();
  const token = useSelector((state) => state.appReducer.token);
  const id = useSelector((state) => state.appReducer.id);
  const [cookies, setCookie] = useCookies(["lang"]);

  const location = useLocation();
  let username = "";
  useEffect(() => {
    if (location.state) {
      username= location.state.username
    } else {
     navigate("/login")
    }
  }, []);
  // const id=location.state.id;

  const level = useSelector((state) => state.appReducer.level);
  const [infoPop, setinfoPop] = useState(false);
  const navigate = useNavigate();
  const backEnd = "https://phplaravel-592682-2484169.cloudwaysapps.com/api/";
  const front = "http://127.0.0.1:8000/api/";
  let BaseUrl = config.BaseUrl;
  const { i18n } = useTranslation();

  useEffect(() => {
    if (cookies && cookies.lang) {
      i18n.changeLanguage(cookies.lang);
    } else {
      i18n.changeLanguage("en");
    }
  }, []);

  useEffect(() => {
    if (infoPop == true) {
      setTimeout(() => {
        setinfoPop(false);
      }, 1000);
    }
  }, [infoPop]);
  return (
    <div>
      <InfoDialog show={infoPop} />
      <Head />
      <section className="sign container-fluid p-2 pb-5">
        {location.state != null?<Usernav />:""}
        {/* <Nav/> */}
        <div className="flash1"></div>
        <div className="flash2"></div>
        <div className="flash3"></div>
        <div className="container text-center mt-5 pt-3">
          <h2>
            {i18next.t("sign_child")}
            <svg
              onClick={() => setinfoPop(true)}
              className="m-2 point"
              width="34"
              height="34"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <g clip-path="url(#clip0_1_516)">
                <path
                  d="M11 7H13V9H11V7ZM11 11H13V17H11V11ZM12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20Z"
                  fill="#EECE52"
                />
              </g>
              <defs>
                <clipPath id="clip0_1_516">
                  <rect width="24" height="24" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </h2>
          <div class=" mt-3 ">
            {/* Nav pills */}

            {/*Tab panes */}
            <div class="tab-content">
              <div id="gamer" class="container tab-pane active">
                <br />
                <SignGamer parent={id} parentName={username} />
              </div>
            </div>
          </div>
        </div>
        <div
          className="container-fluid text-start  "
          style={{ paddingLeft: "150px" }}>
          <a className="btn blue-btn back_a" onClick={() => navigate(-1)}>
            <span className="p-2">{i18next.t("back")}</span>
            <span>
              <svg
                width="12"
                height="16"
                viewBox="0 0 12 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M7.99245 3.65333C8.72578 3.65333 9.32578 3.05333 9.32578 2.32C9.32578 1.58667 8.72578 0.986668 7.99245 0.986668C7.25911 0.986668 6.65911 1.58667 6.65911 2.32C6.65911 3.05333 7.25911 3.65333 7.99245 3.65333ZM5.59245 12.92L6.25911 9.98667L7.65911 11.32V15.32H8.99245V10.32L7.59245 8.98667L7.99245 6.98667C8.85911 7.98667 10.1924 8.65333 11.6591 8.65333V7.32C10.3924 7.32 9.32578 6.65333 8.79245 5.72L8.12578 4.65333C7.85911 4.25333 7.45911 3.98667 6.99245 3.98667C6.79245 3.98667 6.65911 4.05333 6.45911 4.05333L2.99245 5.52V8.65333H4.32578V6.38667L5.52578 5.92L4.45911 11.32L1.19245 10.6533L0.925781 11.9867L5.59245 12.92Z"
                  fill="white"
                />
              </svg>
            </span>
          </a>
        </div>
      </section>

      <Footer bg="bg_blue" />
    </div>
  );
}
