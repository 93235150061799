import * as React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import en from "../resources/en.json";
import config from "../config.json";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useDispatch, useSelector } from "react-redux";
import { from, Divider, Space, Alert, Button, Form, Input } from "antd";
import CircularProgress from "@mui/material/CircularProgress";
import { useCookies } from "react-cookie";
import i18next from "../i18n/config";
import MissionDialog from "./MissionDialog";
function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
let DefaultValues = {
  mission_id: -1,
  mission_name: "",
  mission_Description: "",

  mission_IsRepeated: 0,
  IsSystemMission: 0,
  icon_Name: "",
};
export default function MissionManagmentDialog(props) {

  const [form] = Form.useForm();
  const [SelectedIcon, setSelectedIcon] = React.useState("");
  const token = useSelector((state) => state.appReducer.token);
  const username = useSelector((state) => state.appReducer.username);
  const id = useSelector((state) => state.appReducer.id);
  const [loading, setLoading] = useState(false);
  const [pop, setPop] = useState(false);
  const [cookies, setCookie] = useCookies(["lang"]);
  const [miss, setmiss] = useState({
    mission_id: -1,
    mission_name: "",
    mission_Description: "",

    IsSystemMission: 0,
    icon_Name: "",
  });
  //const [miss, setmiss]=useState(DefaultValues);

  const openAssign=()=>{
    setPop(true);
  }
  const FillDataToUpdate = () => {
    setmiss({
      mission_id: props.item.id,
      mission_name: props.item.name,
      mission_Description: props.item.description,

      IsSystemMission: props.item.isSystemMission,
      icon_Name: props.item.icon_Name,
    });

    setSelectedIcon(props.item.icon_Name);
    form.setFieldsValue({
      mission_name: props.item.name,
      mission_Description: props.item.description,
    });
  };

  let BaseUrl = config.BaseUrl;

  let [show, setShow] = useState(props.show);
  // const id = props.id;
  const [icons, setIcons] = useState([]);

  useEffect(() => {
    if (props.show == true) {
      setLoading(true);
      fetch(`${BaseUrl}GetmissionsIcon/Api`, {
        method: "get",
        body: JSON.stringify(),
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          "Accept-Language": cookies.lang,
        },
      }).then(function (response) {
        response.json().then(function (iconsresp) {
          setLoading(false);
          if (iconsresp.status == true) {
            setIcons(iconsresp.data);
          }
        });
      });
      if (props.FromEdit) {
        FillDataToUpdate();
      }

      setShow(props.show);
    }
  }, [props.show]);

  // useEffect(() => {
  //
  // }, [icons]);

  const handleClose = (event) => {
    form.resetFields();
    setShowSuccessCreateAlter(false);
    setShowSuccessEditAlter(false);
    setShowErrorAlter(false);
    setSelectedIcon("");
    setShow(false);
    props.onChange("david");
  };

  const [ShowSuccessCreateAlter, setShowSuccessCreateAlter] = useState(false);
  const [ShowSuccessEditAlter, setShowSuccessEditAlter] = useState(false);
  const [ShowErrorAlter, setShowErrorAlter] = useState(false);

  const handlesubmit = (values) => {
    setLoading(true);
    ///Edit case
    if (props.FromEdit) {
      fetch(`${BaseUrl}EditMissionOnly/${id}`, {
        method: "post",
        body: JSON.stringify(miss),
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          "Accept-Language": cookies.lang,
        },
      }).then(function (response) {
        response.json().then(function (resp_createmission) {
          setLoading(false);
          // setmiss({
          //   mission_id: -1,
          //   mission_name: "",
          //   mission_Description: "",
          //   IsSystemMission: 0,
          //   icon_Name:'',

          // });
          if (resp_createmission.status == true) {
            setShowSuccessEditAlter(true);
            // form.resetFields();
            // setSelectedIcon('');
          } else if (resp_createmission.status == false) {
            setShowErrorAlter(true);
          }
        });
      });
    } else {
      //// create case
      fetch(`${BaseUrl}CreateMissionOnly/${id}`, {
        method: "post",
        body: JSON.stringify(miss),
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          "Accept-Language": cookies.lang,
        },
      }).then(function (response) {
        response.json().then(function (resp_createmission) {
          setLoading(false);
          setmiss({
            mission_id: -1,
            mission_name: "",
            mission_Description: "",
            IsSystemMission: 0,
            icon_Name: "",
          });
          if (resp_createmission.status == true) {
            setShowSuccessCreateAlter(true);
            form.resetFields();
            setSelectedIcon("");
          } else if (resp_createmission.status == false) {
            setShowErrorAlter(true);
          }
        });
      });
    }
  };

  const handleAlertClose = () => {
    setShowSuccessEditAlter(false);
    setShowErrorAlter(false);
    setShowSuccessCreateAlter(false);
  };

  const location = useLocation();
  const david = () => {};
  return (
    <>
      <MissionDialog show={pop} username={username} onChange={props.onChange} />
      <div className="modal ">
        <Modal
          show={show}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>{en.RewardDialog.veryality} </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="small ">
              {props.FromEdit ? (
                <h5>{i18next.t("editMissionDialog")} </h5>
              ) : (
                <h5>{i18next.t("addMission")} </h5>
              )}
            </div>
            <Box sx={{ width: "100%" }}>
              <Form
                form={form}
                className={
                  cookies.lang === "ar" ? "rtl form-white" : "ltr form-white"
                }
                name="basic"
                onFinish={handlesubmit}
              >
                {/*-----------------------ALERTS--------------------*/}
                {loading == true ? (
                  <div className="w-100 mt-3 mb-2 m-auto">
                    <CircularProgress />
                  </div>
                ) : (
                  <>
                    {ShowSuccessEditAlter && (
                      <Alert
                        message={i18next.t("mission_update")}
                        type="success"
                        closable
                        showIcon
                        afterClose={handleAlertClose}
                      />
                    )}
                    {ShowSuccessCreateAlter && (
                      <Alert
                        message={i18next.t("mission_create")}
                        type="success"
                        closable
                        showIcon
                        afterClose={handleAlertClose}

                      />
                    )}
                    {ShowErrorAlter && (
                      <Alert
                        message={i18next.t("mission_create_faild")}
                        type="error"
                        closable
                        showIcon
                        afterClose={handleAlertClose}
                      />
                    )}
                  </>
                )}
                <Divider />
                {/*-----------------------Mission Name input--------------------*/}
                <Form.Item
                  name="mission_name"
                  rules={[
                    {
                      required: true,
                      message: i18next.t("missio_name_validate"),
                    },
                  ]}
                >
                  <Input
                    type="text"
                    maxLength="50"
                    className="form-control"
                    value={miss.mission_name}
                    placeholder={i18next.t("missionName")}
                    onChange={(e) => {
                      setmiss({ ...miss, mission_name: e.target.value });
                    }}
                  />
                </Form.Item>
                {/*-----------------------Mission description input--------------------*/}
                <Form.Item
                  name="mission_Description"
                  rules={[
                    {
                      required: true,
                      message: i18next.t("mission_desc_validate"),
                    },
                  ]}
                >
                  <Input
                    type="text"
                    maxLength="50"
                    className="form-control"
                    value={miss.mission_Description}
                    placeholder={i18next.t("missionDesc")}
                    onChange={(e) => {
                      setmiss({ ...miss, mission_Description: e.target.value });
                    }}
                  />
                </Form.Item>

                {/*-----------------------Mission icon input--------------------*/}

                <FormControl sx={{ width: "100%" }}>
                  <Select
                    className={
                      cookies.lang === "ar"
                        ? "multi-select text-end font_13"
                        : "multi-select text-start font_13"
                    }
                    id="demo-simple-select"
                    value={SelectedIcon}
                    displayEmpty
                    onChange={(e) =>
                      setmiss(
                        { ...miss, icon_Name: e.target.value },
                        setSelectedIcon(e.target.value)
                      )
                    }
                  >
                    <MenuItem value="">
                      <em>{i18next.t("select_icon")}</em>
                    </MenuItem>
                    {icons.length > 0 ? (
                      icons.map((icon, index) => (
                        <MenuItem
                          key={index}
                          value={icon.icon_Name}
                          label={icon.Icon_lable}
                          className={cookies.lang === "ar" ? "rtl " : ""}
                        >
                          <img
                            src={icon.url}
                            style={{ width: 36 }}
                            alt={icon.icon_Name}
                          />{" "}
                          {icon.Icon_lable}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem>
                        <em>No icons</em>
                      </MenuItem>
                    )}
                  </Select>
                </FormControl>

                {/*-----------------------Buttons--------------------*/}

                <React.Fragment>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      flexDirection: "row",
                      pt: 2,
                    }}
                  >
                    <Button

                        type={"primary"}
                        sx={{ marginRight: "20px" }}
                        onClick={handleClose}
                    >
                      {i18next.t("back")}
                    </Button>
                    {props.FromEdit ? (
                      <Button

                        type="primary"
                        htmlType="submit"
                        sx={{ marginRight: "20px" }}
                        //onClick={handlesubmit}
                      >
                        {i18next.t("save")}
                      </Button>
                    ) : (
                      <Button
                        type="primary"
                        htmlType="submit"
                        sx={{ marginRight: "20px" }}
                        //onClick={handlesubmit}
                      >
                        {i18next.t("create")}
                      </Button>
                    )}


                  </Box>
                </React.Fragment>
              </Form>
            </Box>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
}
