import * as React from "react";
import { Component } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import en from "../resources/en.json";
import config from "../config.json";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import TextField from "@mui/material/TextField";
import { Alert } from "antd";
import { useDispatch, useSelector } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";
import { useCookies } from 'react-cookie';
import i18next from '../i18n/config';
function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function EditReAssignDialog(props) {
  // const id=props.id;
  const now = new Date()
  const startOfWeek = new Date(now.getFullYear(), now.getMonth(), now.getDate() - now.getDay() );
  const endOfWeek = new Date(now.getFullYear(), now.getMonth(), startOfWeek.getDate() + 6);
  const id = useSelector((state) => state.appReducer.id);
  const token = useSelector((state) => state.appReducer.token);
  const [Assignment, setAssignment] = useState({
    mission_id: "-1",
    Assignemnt_StartDate: "",
    Assignemnt_EndDate: "",
    userMissionID: 0,
    isReAssign: false,
  });
  const [SelectedItem, setSelectedItem] = React.useState(-1);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [SuccessMessage, setSuccessMessage] = React.useState(i18next.t('mission_create_faild'));
  const [ShowSuccessAlter, setShowSuccessAlter] = React.useState(false);
  const [ShowErrorAlter, setShowErrorAlter] = React.useState(false);
  function onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }
  var valid = document.getElementsByClassName("mission_valid");

  const theme = useTheme();

  const location = useLocation();
  let BaseUrl = config.BaseUrl;
  const navigate = useNavigate();
  let [show, setShow] = useState(props.show);
  let fullToday = new Date();
  let dd = String(fullToday.getDate()).padStart(2, "0");
  let mm = String(fullToday.getMonth() + 1).padStart(2, "0"); //January is 0!
  let yyyy = fullToday.getFullYear();
  let today = yyyy + "-" + mm + "-" + dd;
  let endDate = yyyy + 2 + "-" + mm + "-" + dd;
  const [missions, setMissions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [cookies, setCookie] = useCookies(['lang']);
  useEffect(() => {
    if (props.show == true) {
      fetch(`${BaseUrl}GetParentMissions/${id}`, {
        method: "get",
        body: JSON.stringify(),
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          "Accept-Language": cookies.lang,
        },
      }).then(function (response) {
        response.json().then(function (rewardsresp) {
          if (rewardsresp.status == true) {
            setMissions(rewardsresp.data);
          }
        });
      });
      setShow(props.show);

      if ( props.isReAssign)
      {
        setAssignment({
          mission_id: props.item.MissionID,
          // Assignemnt_StartDate: props.item.StartDate.split(" ")[0],
          // Assignemnt_EndDate: props.item.EndDate.split(" ")[0],
          Assignemnt_StartDate:null,
          Assignemnt_EndDate:null,
          userMissionID: props.item.userMission,
          isReAssign: props.isReAssign,
        });
      }
      else
      {
        setAssignment({
          mission_id: props.item.MissionID,
          Assignemnt_StartDate: props.item.StartDate.split(" ")[0],
          Assignemnt_EndDate: props.item.EndDate.split(" ")[0],

          userMissionID: props.item.userMission,
          isReAssign: props.isReAssign,
        });
      }

      setSelectedItem(props.item.MissionID);
    }
  }, [props.show]);
  const handleAlertClose = () => {
    setShowSuccessAlter(false);
    setShowErrorAlter(false);
    props.onChange("david");
  };
  const handleClose = () => {
    setSelectedItem("-1");
    setShow(false);
    setShowSuccessAlter(false);
    setShowErrorAlter(false);
    props.onChange("david");
  };
  const handlesubmit = () => {
    setLoading(true);

    if (Assignment.Assignemnt_StartDate == null ||Assignment.Assignemnt_EndDate==null || Assignment.mission_id==-1)
    {
      setLoading(false);
      setShowErrorAlter(true);
      setErrorMessage(i18next.t('allFieldsIsRequired'));


    }
    else
    {
      fetch(`${BaseUrl}UpdateAndReAssign`, {
        method: "post",
        body: JSON.stringify({
          Mission_id: Assignment.mission_id,
          Assignemnt_StartDate: Assignment.Assignemnt_StartDate,
          Assignemnt_EndDate: Assignment.Assignemnt_EndDate,
          userMissionID: Assignment.userMissionID,
          isReAssign: Assignment.isReAssign,
        }),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Accept-Language": cookies.lang,
        },
      }).then(function (response) {
        response.json().then(function (resp_editmission) {
          setLoading(false);
          if (resp_editmission.status == true) {
            //setrewards(respp.data);
            // document.getElementById('edit_mission').innerHTML=en.MissionDialog.editsuccess;
            // document.getElementById('edit_mission').classList.remove('d-none');
            // document.getElementById('edit_mission').classList.add('alert-info');
            setShowSuccessAlter(true);
            setShowErrorAlter(false);
            setSuccessMessage(resp_editmission.msg);
          } else if (resp_editmission.status == false) {
            // document.getElementById('edit_mission').innerHTML=resp_editmission.msg;
            // document.getElementById('edit_mission').classList.remove('d-none');
            // document.getElementById('edit_mission').classList.add('alert-danger');
            setShowSuccessAlter(false);
            setShowErrorAlter(true);
          } else {
            // document.getElementById('edit_mission').innerHTML=en.MissionDialog.editfailed;
            // document.getElementById('edit_mission').classList.remove('d-none');
            // document.getElementById('edit_mission').classList.add('alert-danger');
            setShowSuccessAlter(false);
            setShowErrorAlter(true);
          }
        });
      });
    }

  };

  const onSelectMission = (data) => {
    const e = data.target.value;
    setSelectedItem(e);
    setAssignment({ ...Assignment, mission_id: e });

    if (
      document.getElementById("missId_valid").classList.contains("d-none") ==
      false
    )
      document.getElementById("missId_valid").classList.add("d-none");
    if (valid[0].classList.contains("d-none") == false)
      valid[0].classList.add("d-none");
    if (valid[1].classList.contains("d-none") == false)
      valid[1].classList.add("d-none");
  };
  return (
    <>
      <div className="modal">
        <Modal
          show={show}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}>
          <Modal.Header closeButton>
            <Modal.Title>{en.RewardDialog.veryality} </Modal.Title>
          </Modal.Header>
          <Modal.Body>
          <div className={cookies.lang  === 'ar' ? 'rtl' : 'ltr'} >
            <div className="small p-3">
              {props.isReAssign ? (
                <h5>  {i18next.t('editMission')} </h5>
              ) : (
                <h5>{i18next.t('editAssignemnt')} </h5>
              )}
            </div>
            <Box sx={{ width: "100%" }}>
              <form className=" m-auto form-white" id="">
                {/*-----------------------ALERTS--------------------*/}
                {loading == true ? (
                  <div className="w-100 mt-3 mb-2 m-auto">
                    <CircularProgress />
                  </div>
                ) : (
                  <>
                    {ShowSuccessAlter && (
                      <Alert
                        message={SuccessMessage}
                        type="success"
                        closable
                        showIcon
                        afterClose={handleAlertClose}
                      />
                    )}
                    {ShowErrorAlter && (
                      <Alert
                        message= {errorMessage}
                        type="error"
                        closable
                        showIcon
                        afterClose={handleAlertClose}
                      />
                    )}
                  </>
                )}
                <>
                  <br></br>
                  <div class="mb-3  " id="custom_mission">
                    <select
                      id="selectMiss"
                      name=""
                      className="form-select "
                      value={SelectedItem}
                      onChange={onSelectMission}
                      required>
                      <option value={-1}> {i18next.t('select')} </option>
                      {missions.length != 0
                        ? missions.map((item, index) => (
                              item.isSystemMission ==false?(
                            <option
                              selected={
                                Assignment.mission_id == item.id ? true : false
                              }
                              value={item.id}
                              key={index}>
                              {item.name}
                            </option> ) :("")
                          ))
                        : ""}
                    </select>
                    <div
                      className="text-start text-danger p-1 small d-none"
                      id="missId_valid">
                       {i18next.t('missId_validation')} {" "}
                    </div>
                  </div>
                  <div class="mb-3 input-group">
                    <span class="input-group-text select-watch text-white w-50">
                    {i18next.t('missionStartdate')}
                    </span>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <MobileDatePicker
                        className="form-control point"
                        inputFormat="MM/DD/YYYY"
                        value={Assignment.Assignemnt_StartDate}
                        //value={null}
                        minDate={today}
                        maxDate={
                          Assignment.Assignemnt_EndDate != "" && Assignment.Assignemnt_EndDate != null
                            ? Assignment.Assignemnt_EndDate
                            : endOfWeek
                        }
                      // minDate={startOfWeek}

                       // maxDate={endOfWeek}
                        onChange={(e) => {
                          setAssignment({
                            ...Assignment,
                            Assignemnt_StartDate:
                              e?.$y + "-" + (e?.$M + 1) + "-" + e?.$D,
                          });
                        }}
                        required
                        renderInput={(params) => (
                          <TextField {...params} placeholder="mm/dd/yyyy" />
                        )}
                      />
                    </LocalizationProvider>
                    <div className="text-danger text-white p-1 small mission_valid d-none">
                    {i18next.t('missionStartdate_valid')}
                    </div>
                  </div>
                  <div class="mb-4 input-group">
                    <span class="input-group-text select-watch text-white w-50 ">
                    {i18next.t('missionEnddate')}
                    </span>

                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <MobileDatePicker
                        className="form-control point"
                        inputFormat="MM/DD/YYYY"
                        //value={null}
                        value={Assignment.Assignemnt_EndDate}

                        minDate={
                          Assignment.Assignemnt_StartDate != "" && Assignment.Assignemnt_StartDate!= null
                            ? Assignment.Assignemnt_StartDate
                            : today
                        }
                        // maxDate={endDate}
                        //minDate={today}

                        maxDate={endOfWeek}
                        onChange={(e) => {
                          setAssignment({
                            ...Assignment,
                            Assignemnt_EndDate:
                              e?.$y + "-" + (e?.$M + 1) + "-" + e?.$D,
                          });
                        }}
                        required
                        renderInput={(params) => (
                          <TextField {...params} placeholder="mm/dd/yyyy" />
                        )}
                      />
                    </LocalizationProvider>
                    <div className="text-danger text-start p-1 small mission_valid d-none">
                    {i18next.t('missionEnddate_valid')}
                    </div>
                  </div>
                </>
                <React.Fragment>
                  <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                    <Button
                        className="btn-danger"
                        color="inherit"
                        onClick={handleClose}
                        sx={{ mr: 1 }}>
                      {i18next.t('discard')}
                    </Button>

                    <Box sx={{ flex: "1 1 auto" }} />
                    <Button
                        className="btn-success"
                        color="inherit"
                        onClick={handlesubmit}
                        sx={{ mr: 1 }}>
                      {i18next.t('save')}
                    </Button>
                  </Box>
                </React.Fragment>
              </form>
            </Box>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
}
