import * as React from "react";
import { Component } from "react";

import { useNavigate, useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import en from "../resources/en.json";
import config from "../config.json";
import { useDispatch, useSelector } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";
import { useCookies } from 'react-cookie';
import i18next from '../i18n/config';

export default function ConnectWearableDialog(props) {
  const dispatch = useDispatch();
  const token = useSelector((state) => state.appReducer.token);
  const id = useSelector((state) => state.appReducer.id);
  const [cookies, setCookie] = useCookies(['lang']);
  const location = useLocation();
  let BaseUrl = config.BaseUrl;
  let [show, setShow] = useState(props.show);
  let [showbuttons, setshowbuttons] = useState(true);
  let [watch1, setwatch1] = useState(0);
  const [wearableTypes, setwearableTypes] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (props.show == true) {
      setShow(props.show);
    }
  }, [props.show]);

  useEffect(() => {
    fetch(`${BaseUrl}wearabletype`, {
      method: "get",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Accept-Language": cookies.lang,
      },
    }).then(function (response) {
      response.json().then(function (wearablesresp) {
        if (wearablesresp.status == true) setwearableTypes(wearablesresp.data);
      });
    });
  }, []);
  const handleClose = () => {
    setShow(false);
    setwatch1(0);
    setshowbuttons(true);
    props.onChange("david");
  };

  const handlesubmit = (e) => {
    e.preventDefault();
    setshowbuttons(false);
    setLoading(true);
    fetch(`${BaseUrl}connectUserTowearable/${id}/${watch1}`, {
      method: "get",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
        "Accept-Language": cookies.lang,
      },
    }).then(function (response11) {
      response11.json().then(function (connect_fitbitResp) {
        openInNewTab(connect_fitbitResp.data);
        var y = setTimeout(function () {

          fetch(`${BaseUrl}checkwearableAccess/${id}`, {
            method: "get",
            headers: {
              Accept: "application/json",
              Authorization: `Bearer ${token}`,
              "Accept-Language": cookies.lang,
            },
          }).then(function (response33) {
            response33.json().then(function (check_fitbitResp) {
              setLoading(false);
              if (
                Array.isArray(check_fitbitResp) &&
                check_fitbitResp[0] == "user connect to wearable successfully"
              ) {
                document.getElementById("finish_mission1").innerHTML =
                i18next.t('connectsuccessfully') ;
                document
                  .getElementById("finish_mission1")
                  .classList.remove("d-none");
                document
                  .getElementById("finish_mission1")
                  .classList.add("alert-info");
                setshowbuttons(true);
                setwatch1(0);
              } else {
                document.getElementById("finish_mission1").innerHTML =
                i18next.t('connectfaild');
                document
                  .getElementById("finish_mission1")
                  .classList.remove("d-none");
                document
                  .getElementById("finish_mission1")
                  .classList.add("alert-danger");
                setshowbuttons(true);
              }
            });
          });
        }, 40000);
      });
    });
  };
  const openInNewTab = (url) => {

    const newWindow = window.open(url, "_blank", "width=500, height=500");
  };

  return (
    <>
      <div class="modelround" className="modal">
        <Modal
          show={show}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}>
          <Modal.Header class="modelstyle">
            <Modal.Title class="modelstyle">
              {en.RewardDialog.veryality}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body  className={cookies.lang  === 'ar' ? 'rtl modelstyle' : 'ltr modelstyle'}>
            <h5   className={cookies.lang  === 'ar' ? 'text-end font p-3' : 'text-start font p-3'}>{i18next.t('connectaccess')}</h5>
            <form className=" m-auto form-white" id="form_parent">
              <div class="mb-3 input-group " id="watch_parent">
                <span class="input-group-text select-watch">
                  <img src="../assets/imgs/watch.png" className=""></img>
                </span>
                <select
                  name=""
                  className="form-select "
                  id=""
                  onChange={(e) => {
                    setwatch1(e.target.value);
                  }}>
                  <option className="" value="">
                  {i18next.t('select_device')} 
                  </option>
                  {wearableTypes.length > 0
                    ? wearableTypes.map((item) => (
                        <option className="" value={item.id}>
                          {item.Type}{" "}
                        </option>
                      ))
                    : ""}
                </select>
              </div>
            </form>

            <Modal.Footer>
              <div
                style={{ display: showbuttons ? "" : "none", width: "100%" }}>
                <Button
                  type="submit"
                  disabled={watch1 > 0 ? false : true}
                  className="bg_blue w-40 m-1"
                  variant="primary"
                  onClick={handlesubmit}>
                  {i18next.t('connect')} 
                </Button>
                <Button className=" white-outline-btn1" onClick={handleClose}>
                {i18next.t("close")}
                </Button>

                <div
                  id="finish_mission1"
                  className="w-100 mt-3 mb-2 m-auto d-none p-3"></div>
              </div>
              {loading == true ? (
                <div className="w-100 mt-3 mb-2 m-auto">
                  <CircularProgress />
                </div>
              ) : (
                ""
              )}

              <div
                style={{
                  display: !showbuttons ? "" : "none",
                  width: "100%",
                  color: "red",
                }}>
                <h5>{i18next.t('wait')} </h5>
              </div>
            </Modal.Footer>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
}
