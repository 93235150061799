import * as React from 'react';
import { NavLink} from 'react-router-dom';
import Head from '../layout/Head';
import Nav from '../layout/Nav';
import Footer from '../layout/Footer';
import en from '../resources/en.json'
import SignGamer from './components/SignGamer';
import SignParent from './components/SignParent';


export default function Signup()  {

return (
    <div>
        <Head/>
        <section className='sign container-fluid p-2 pb-5'>
        <Nav/>
        <div className='flash1'></div>
        <div className='flash2'></div>
        <div className='flash3'></div>
        <div className='container text-center mt-5 pt-3'>
            <h2>{en.sign.sign_GamerCaptionRegister}</h2>
            <div class=" mt-3 ">
            {/* Nav pills */}
                <ul class="nav nav-pills d-flex justify-content-center" role="tablist">
                    <li class="nav-item p-md-4 p-lg-4 p-sm-1">
                    <a class="nav-link active" data-bs-toggle="pill" href="#gamer">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M21 6H3C1.9 6 1 6.9 1 8V16C1 17.1 1.9 18 3 18H21C22.1 18 23 17.1 23 16V8C23 6.9 22.1 6 21 6ZM21 16H3V8H21V16ZM6 15H8V13H10V11H8V9H6V11H4V13H6V15Z" fill="#EECE52"/>
                        <path d="M14.5 15C15.3284 15 16 14.3284 16 13.5C16 12.6716 15.3284 12 14.5 12C13.6716 12 13 12.6716 13 13.5C13 14.3284 13.6716 15 14.5 15Z" fill="#EECE52"/>
                        <path d="M18.5 12C19.3284 12 20 11.3284 20 10.5C20 9.67157 19.3284 9 18.5 9C17.6716 9 17 9.67157 17 10.5C17 11.3284 17.6716 12 18.5 12Z" fill="#EECE52"/>
                        </svg>
                     <span className='m-3'>{en.sign.gamer}</span></a>
                    </li>
                    <li class="nav-item p-md-4 p-lg-4 p-sm-1">
                    <a class="nav-link" data-bs-toggle="pill" href="#parent">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12.5 10C12.5 8.35001 11.15 7.00001 9.49999 7.00001C7.84999 7.00001 6.49999 8.35001 6.49999 10C6.49999 11.65 7.84999 13 9.49999 13C11.15 13 12.5 11.65 12.5 10ZM9.49999 11C8.94999 11 8.49999 10.55 8.49999 10C8.49999 9.45001 8.94999 9.00001 9.49999 9.00001C10.05 9.00001 10.5 9.45001 10.5 10C10.5 10.55 10.05 11 9.49999 11ZM16 13C17.11 13 18 12.11 18 11C18 9.89001 17.11 9.00001 16 9.00001C14.89 9.00001 13.99 9.89001 14 11C14 12.11 14.89 13 16 13ZM11.99 2.01001C6.46999 2.01001 1.98999 6.49001 1.98999 12.01C1.98999 17.53 6.46999 22.01 11.99 22.01C17.51 22.01 21.99 17.53 21.99 12.01C21.99 6.49001 17.51 2.01001 11.99 2.01001ZM5.83999 17.12C6.51999 16.58 8.10999 16.01 9.49999 16.01C9.56999 16.01 9.64999 16.02 9.72999 16.02C9.96999 15.38 10.4 14.73 11.03 14.16C10.47 14.06 9.93999 14 9.49999 14C8.19999 14 6.10999 14.45 4.76999 15.43C4.26999 14.39 3.98999 13.23 3.98999 12C3.98999 7.59001 7.57999 4.00001 11.99 4.00001C16.4 4.00001 19.99 7.59001 19.99 12C19.99 13.2 19.72 14.34 19.24 15.37C18.24 14.78 16.88 14.5 16 14.5C14.48 14.5 11.5 15.31 11.5 17.2V19.98C9.22999 19.85 7.20999 18.77 5.83999 17.12Z" fill="white"/>
                        
                        </svg>
                      <span className='m-3'>{en.sign.parent}</span>
                    </a>
                    </li>
                </ul>
                  
                    {/*Tab panes */}
                <div class="tab-content">
                    <div id="gamer" class="container tab-pane active"><br/>
                         <SignGamer/>
                    </div>
                    <div id="parent" class="container tab-pane fade"><br/>
                         <SignParent/>
                    </div>
                </div>
            </div>
          <NavLink className='nav-link text-white link'  to="/veryalityApp">{en.sign.have_account}</NavLink>

        </div>
        </section>


        <Footer bg='bg_blue'/>
    </div>
  );
}
