import * as React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import Head from "../layout/Head";
import Footer from "../layout/Footer";
import en from "../resources/en.json";
import { useDispatch, useSelector } from "react-redux";
import config from "../config.json";
import Usernav from "../layout/Usernav";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { CardHeader, Avatar, CircularProgress, Button } from "@mui/material";
import CircleChart from "./CircleChart";
import RemoveTeamModal from "./RemoveTeamModal";
import UpdateTeamModal from "./UpdateTeamModal";
import InviteModal from "./InviteModal";
import AssignTournamentToTeamModal from "../ESport/AssignTournamentToTeamModal";
import {
  UsergroupAddOutlined,
  SettingOutlined,
  UserOutlined,
  MenuOutlined,
} from "@ant-design/icons";
import { Dropdown, Space, message } from "antd";
import ReactLoading from "react-loading";

import { red } from "@mui/material/colors";
import { useCookies } from "react-cookie";
import i18next from "../i18n/config";
import { useTranslation } from "react-i18next";

export default function TeamPage() {
  let BaseUrl = config.BaseUrl;
  const navigate = useNavigate();
  const location = useLocation();
  const username = useSelector((state) => state.appReducer.username);
  const token = useSelector((state) => state.appReducer.token);
  const id = useSelector((state) => state.appReducer.id);
  let [tournaments, setTournaments] = useState([]);
  const teamId = location.state.teamId;
  const logo = location.state.logo;
  const [loading, setLoading] = useState(false);
  const [inviteLoading, setInviteLoading] = useState(false);
  const { i18n } = useTranslation();

  const [cookies, setCookie] = useCookies(["lang"]);
  const [show, setshow] = useState(false);
  const [team, setTeam] = useState({
    id: 1,
    name: "team",
    logo: logo,
    XP_points: 0,
    level: 1,
    manager_name: "manager",
    manager_logo: "",
    joined_at: "",
  });

  const [invitations, setInvitations] = useState([]);
  const [members, setMembers] = useState([]);
  let [NotStartedTournaments, setNotStartedTournaments] = useState(0);
  let [InProgressTournaments, setInProgressTournaments] = useState(0);
  let [CompletedTournaments, setCompletedTournaments] = useState(0);
  let [PendingInvitation, setPendingInvitation] = useState(0);
  let [AcceptedInvitation, setAcceptedInvitation] = useState(0);
  let [refresh, setRefresh] = useState(false);
  let [removePop, setRemovePop] = useState(false);
  let [updatePop, setUpdatePop] = useState(false);
  let [viewPopAssign, setviewPopAssign] = useState(false);
  let [invitePop, setInvitePop] = useState(false);

  useEffect(() => {
    if (cookies && cookies.lang) {
      i18n.changeLanguage(cookies.lang);
    } else {
      i18n.changeLanguage("en");
    }
  }, []);

  const handleMenuClick = (e) => {
    switch (e.key) {
      case "1-1": //update team
        setUpdatePop(true);
        break;
      case "1-2": //delete team
        setRemovePop(true);
        break;
      case "2-1": //'Invite member'
        setInvitePop(true);
        break;
      case "2-2": // 'Assign tournament'
        setviewPopAssign(true);
        break;
      default:
    }
  };
  const items = [
    {
      label: "",
      key: "1",
      icon: <SettingOutlined />,
      children: [
        {
          key: "1-1",
          label: i18next.t("Update_team"),
        },
        {
          key: "1-2",
          label: i18next.t("Delete_team"),
          danger: true,
        },
      ],
    },
    {
      label: "",
      key: "2",
      icon: <UsergroupAddOutlined />,
      children: [
        {
          key: "2-1",
          label: i18next.t("Invite_member"),
        },
        {
          key: "2-2",
          label: i18next.t("Assign_tournament"),
        },
      ],
    },
  ];

  const menuProps = {
    items,
    onClick: handleMenuClick,
    mode: "horizontal",
  };

  const handleChange = React.useCallback((newValue) => {
    setRefresh(true);
    localStorage.removeItem("TournamentID");
    fetch(`${BaseUrl}Team_Info/${teamId}`, {
      method: "get",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
        "Accept-Language": cookies.lang,
      },
    }).then(function (response11) {
      response11.json().then(function (resp) {
        setRefresh(false);
        if (resp.status == true) {
          setTeam(resp.data.team);
          setMembers(resp.data.members);
          setInvitations(resp.data.invitations);
          setTournaments(resp.data.tournaments);

          if (resp.data.invitations && resp.data.invitations.length > 0) {
            var AcceptedCount = 0;
            var PendingCount = 0;
            for (let i = 0; i < resp.data.invitations.length; i++) {
              if (resp.data.invitations[i].invitationStatusCode == 1) {
                AcceptedCount++;
              } else {
                PendingCount++;
              }
            }
            setAcceptedInvitation(AcceptedCount);
            setPendingInvitation(PendingCount);
          }
          if (resp.data.tournaments && resp.data.tournaments.length > 0) {
            var countCompleted = 0;
            var countNotStarted = 0;
            var countInProgress = 0;
            for (let i = 0; i < resp.data.tournaments.length; i++) {
              if (resp.data.tournaments[i].StatusCode == 2) {
                ++countCompleted;
              }
              if (resp.data.tournaments[i].StatusCode == 1) {
                ++countInProgress;
              }
              if (resp.data.tournaments[i].StatusCode == 0) {
                ++countNotStarted;
              }
              // if (i == resp.data.tournaments.length - 1)
            }
            setCompletedTournaments(countCompleted);
            setNotStartedTournaments(countNotStarted);
            setInProgressTournaments(countInProgress);
          }
        }
      });
    });
  });

  useEffect(() => {
    if (token === "") {
      navigate("/veryalityApp");
    }
    setLoading(true);
    fetch(`${BaseUrl}Team_Info/${teamId}`, {
      method: "get",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
        "Accept-Language": cookies.lang,
      },
    }).then(function (response11) {
      response11.json().then(function (resp) {
        setLoading(false);
        if (resp.status == true) {
          setTeam(resp.data.team);
          setMembers(resp.data.members);
          setInvitations(resp.data.invitations);
          setTournaments(resp.data.tournaments);

          if (resp.data.invitations && resp.data.invitations.length > 0) {
            var AcceptedCount = 0;
            var PendingCount = 0;
            for (let i = 0; i < resp.data.invitations.length; i++) {
              if (resp.data.invitations[i].invitationStatusCode == 1) {
                AcceptedCount++;
              } else {
                PendingCount++;
              }
            }
            setAcceptedInvitation(AcceptedCount);
            setPendingInvitation(PendingCount);
          }
          if (resp.data.tournaments && resp.data.tournaments.length > 0) {
            var countCompleted = 0;
            var countNotStarted = 0;
            var countInProgress = 0;
            for (let i = 0; i < resp.data.tournaments.length; i++) {
              if (resp.data.tournaments[i].StatusCode == 2) {
                ++countCompleted;
              }
              if (resp.data.tournaments[i].StatusCode == 1) {
                ++countInProgress;
              }
              if (resp.data.tournaments[i].StatusCode == 0) {
                ++countNotStarted;
              }
              // if (i == resp.data.tournaments.length - 1)
              setCompletedTournaments(countCompleted);
              setNotStartedTournaments(countNotStarted);
              setInProgressTournaments(countInProgress);
            }
          }
        }
      });
    });
  }, cookies.lang);

  const Reinvite = (item) => {
    setRefresh(true);
    document.getElementById("invite_status").innerHTML = "";
    setInviteLoading(true);
    fetch(`${BaseUrl}InviteUsers`, {
      method: "post",
      body: JSON.stringify([
        {
          nickName: item.nickName,
          email: item.email,
          user_id: id,
          team_id: teamId,
          invite_status: "0",
        },
      ]),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }).then(function (response) {
      response.json().then(function (resp_finishmission) {
        setRefresh(false);
        setInviteLoading(false);
        if (resp_finishmission.status == true) {
          document.getElementById("invite_status").innerHTML =
            i18next.t("oneInviteSuccess");
          document.getElementById("invite_status").classList.remove("d-none");
          document.getElementById("invite_status").classList.add("alert-info");
        } else {
          document.getElementById("invite_status").innerHTML =
            i18next.t("oneInviteFailed");

          // en.Esport.oneInviteFailed;
          document.getElementById("invite_status").classList.remove("d-none");
          document
            .getElementById("invite_status")
            .classList.add("alert-danger");
        }
        setTimeout(() => {
          document.getElementById("invite_status").classList.add("d-none");
        }, 7000);
      });
    });
  };

  useEffect(() => {
    if (removePop == true) {
      setTimeout(() => {
        setRemovePop(false);
      }, 1000);
    }
  }, [removePop]);

  useEffect(() => {
    if (updatePop == true) {
      setTimeout(() => {
        setUpdatePop(false);
      }, 1000);
    }
  }, [updatePop]);

  useEffect(() => {
    if (invitePop == true) {
      setTimeout(() => {
        setInvitePop(false);
      }, 1000);
    }
  }, [invitePop]);

  useEffect(() => {
    if (viewPopAssign == true) {
      setTimeout(() => {
        setviewPopAssign(false);
      }, 1000);
    }
  }, [viewPopAssign]);

  if (loading) {
    return (
      <div className="load-page">
        <ReactLoading type="spin" color="#F9AF22" className="load" />
      </div>
    );
  }
  return (
    <div>
      <Head />
      <RemoveTeamModal
        show={removePop}
        teamName={team.name}
        teamID={team.id}
        // onChange={() => }
      />
      <UpdateTeamModal show={updatePop} team={team} onChange={handleChange} />
      <InviteModal show={invitePop} onChange={handleChange} teamID={team.id} />
      <AssignTournamentToTeamModal
        show={viewPopAssign}
        teamID={team.id}
        onChange={handleChange}
      />

      <section
        className="container-fluid pdash bg_black pt-4 m-0 "
        style={{ paddingLeft: 30, paddingRight: 35 }}>
        <Usernav />

        <div className="container-fluid bg-grey  p-0 mt-3">
          <div className="team_cover">
            <a
              className={
                cookies.lang === "ar" ? "yallow_ar yellow" : "yallow_en yellow"
              }>
              {/* <Space wrap> */}
              <Dropdown menu={menuProps} trigger={["click"]}>
                <Button
                  variant="contained"
                  sx={{ p: 1.5, borderRadius: "50%", minWidth: "auto" }}>
                  <MenuOutlined />
                </Button>
              </Dropdown>
            </a>
            <img src={team.logo} />
            <OverlayTrigger
              key="top"
              placement="top"
              overlay={<Tooltip>{team.name}</Tooltip>}>
              <p
                className="letters_limit text-white mt-4 "
                style={
                  cookies.lang === "ar"
                    ? { paddingLeft: "60px" }
                    : { paddingRight: "60px" }
                }>
                {team.name.length > 25
                  ? team.name.substring(0, 25) + "..."
                  : team.name}
              </p>
            </OverlayTrigger>
          </div>
          <div
            className={cookies.lang === "ar" ? "rtl p-2 row" : "ltr p-2 row"}>
            <div
              className="col-lg-3 col-md-6 col-sm-12 p-2"
              style={{ borderLeft: "1px solid white" }}>
              <CardHeader
                sx={
                  cookies.lang === "ar"
                    ? { color: "white", textAlign: "right" }
                    : { color: "white", textAlign: "left" }
                }
                avatar={
                  <Avatar
                    sx={{
                      m: { sm: 1, md: 0, xs: 1 }
                    }}
                    aria-label="recipe">
                    <svg
                      width="20"
                      height="19"
                      viewBox="0 0 20 19"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M14.5 0C12.76 0 11.09 0.81 10 2.09C8.91 0.81 7.24 0 5.5 0C2.42 0 0 2.42 0 5.5C0 9.28 3.4 12.36 8.55 17.04L10 18.35L11.45 17.03C16.6 12.36 20 9.28 20 5.5C20 2.42 17.58 0 14.5 0ZM10.1 15.55L10 15.65L9.9 15.55C5.14 11.24 2 8.39 2 5.5C2 3.5 3.5 2 5.5 2C7.04 2 8.54 2.99 9.07 4.36H10.94C11.46 2.99 12.96 2 14.5 2C16.5 2 18 3.5 18 5.5C18 8.39 14.86 11.24 10.1 15.55Z"
                        fill="#100E23"
                      />
                    </svg>
                  </Avatar>
                }
                subheader={
                  <span
                    style={
                      cookies.lang === "ar"
                        ? { fontSize: 12, color: "white", paddingRight: "10px" }
                        : { fontSize: 12, color: "white" }
                    }>
                    {team.level}
                  </span>
                }
                title={
                  <span
                    style={
                      cookies.lang === "ar" ? { paddingRight: "10px" } : {}
                    }>
                    {i18next.t("level")}
                  </span>
                }
              />
            </div>
            <div
              className="col-lg-3 col-md-6 col-sm-12 p-2"
              style={{ borderLeft: "1px solid white" }}>
              <CardHeader
                sx={
                  cookies.lang === "ar"
                    ? { color: "white", textAlign: "right" }
                    : { color: "white", textAlign: "left" }
                }
                avatar={
                  <Avatar
                    sx={{
                      m: { sm: 1, md: 0, xs: 1 }
                    }}
                    aria-label="recipe">
                    <svg
                      width="20"
                      height="22"
                      viewBox="0 0 20 22"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M7.5 4.5C8.6 4.5 9.5 3.6 9.5 2.5C9.5 1.4 8.6 0.5 7.5 0.5C6.4 0.5 5.5 1.4 5.5 2.5C5.5 3.6 6.4 4.5 7.5 4.5ZM3.75 7.9L1 22H3.1L4.85 14L7 16V22H9V14.45L6.95 12.4L7.55 9.4C8.85 11 10.8 12 13 12V10C11.15 10 9.55 9 8.65 7.55L7.7 5.95C7.35 5.35 6.7 5 6 5C5.75 5 5.5 5.05 5.25 5.15L0 7.3V12H2V8.65L3.75 7.9ZM11 1V8H14.75V22H16.25V8H20V1H11ZM16.01 7V5.25H12.5V3.75H16.01V2L18.5 4.5L16.01 7Z"
                        fill="#100E23"
                      />
                    </svg>
                  </Avatar>
                }
                subheader={
                  <span
                    style={
                      cookies.lang === "ar"
                        ? { fontSize: 12, color: "white", paddingRight: "10px" }
                        : { fontSize: 12, color: "white" }
                    }>
                    {tournaments.length}
                  </span>
                }
                title={
                  <span
                    style={
                      cookies.lang === "ar" ? { paddingRight: "10px" } : {}
                    }>
                    {i18next.t("Tournaments_count")}
                  </span>
                }
              />
            </div>
            <div
              className="col-lg-3 col-md-6 col-sm-12 p-2"
              style={{ borderLeft: "1px solid white" }}>
              <CardHeader
                sx={
                  cookies.lang === "ar"
                    ? { color: "white", textAlign: "right" }
                    : { color: "white", textAlign: "left" }
                }
                avatar={
                  <Avatar
                    sx={{
                      m: { sm: 1, md: 0, xs: 1 }
                    }}
                    aria-label="recipe">
                    <svg
                      width="20"
                      height="22"
                      viewBox="0 0 20 22"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M18 2H17V0H15V2H5V0H3V2H2C0.9 2 0 2.9 0 4V20C0 21.1 0.9 22 2 22H18C19.1 22 20 21.1 20 20V4C20 2.9 19.1 2 18 2ZM18 20H2V9H18V20ZM18 7H2V4H18V7Z"
                        fill="#100E23"
                      />
                    </svg>
                  </Avatar>
                }
                subheader={
                  <span
                    style={
                      cookies.lang === "ar"
                        ? { fontSize: 12, color: "white", paddingRight: "10px" }
                        : { fontSize: 12, color: "white" }
                    }>
                    {team.joined_at}
                  </span>
                }
                title={
                  <span
                    style={
                      cookies.lang === "ar" ? { paddingRight: "10px",textAlign: "right" } : {}
                    }>
                    {i18next.t("Joined_at")}
                  </span>
                }
              />
            </div>
            <div
              className="col-lg-3 col-md-6 col-sm-12 p-2 "
              style={{ borderLeft: "1px solid white" }}>
              <CardHeader
                sx={
                  cookies.lang === "ar"
                    ? { color: "white", textAlign: "right" }
                    : { color: "white", textAlign: "left" }
                }
                avatar={
                  <Avatar
                    sx={{
                      m: { sm: 1, md: 0, xs: 1 }
                    }}
                    aria-label="recipe">
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M8 2C9.1 2 10 2.9 10 4C10 5.1 9.1 6 8 6C6.9 6 6 5.1 6 4C6 2.9 6.9 2 8 2ZM8 12C10.7 12 13.8 13.29 14 14H2C2.23 13.28 5.31 12 8 12ZM8 0C5.79 0 4 1.79 4 4C4 6.21 5.79 8 8 8C10.21 8 12 6.21 12 4C12 1.79 10.21 0 8 0ZM8 10C5.33 10 0 11.34 0 14V16H16V14C16 11.34 10.67 10 8 10Z"
                        fill="#100E23"
                      />
                    </svg>
                  </Avatar>
                }
                subheader={
                  <span
                    style={
                      cookies.lang === "ar"
                        ? { fontSize: 12, color: "white", paddingRight: "10px" }
                        : { fontSize: 12, color: "white" }
                    }>
                    {team.manager_name}
                  </span>
                }
                title={
                  <span
                    style={
                      cookies.lang === "ar" ? { paddingRight: "10px" } : {}
                    }>
                    {i18next.t("Manager")}
                  </span>
                }
              />
            </div>
          </div>
        </div>

        <div className="container-fluid mt-5 " style={{ height: "auto" }}>
          <h2
            className={
              cookies.lang === "ar"
                ? "text-white text-end"
                : "text-white text-start"
            }>
            {i18next.t("Progress_chart")}
          </h2>
          <div className={"row mt-4"}>
            <div className={"col-md-6"}>
              <h5 className={"text-white"}>{i18next.t("Tournament")}</h5>
              <CircleChart
                data={{
                  Completed_tournaments: CompletedTournaments,
                  NotStartedYet: NotStartedTournaments,
                  InprogressTournament: InProgressTournaments,
                }}
                refresh={refresh}
              />
            </div>
            <div className={"col-md-6"}>
              <h5 className={"text-white"}>{i18next.t("Invitations")}</h5>
              <CircleChart
                data={{
                  PendingInvitation: PendingInvitation,
                  Acceptedinvitation: AcceptedInvitation,
                }}
                refresh={refresh}
              />
            </div>
          </div>
        </div>

        <div className="container-fluid mt-5 teamPage_row mb-5">
          <h2
            className={
              cookies.lang === "ar"
                ? "text-white text-end"
                : "text-white text-start"
            }>
            {i18next.t("Invitations")}
          </h2>
          <div class="table-responsive teams_table table-bordered table-dark ">
            <table class="table table-striped mt-2">
              <thead className="text-white ">
                <tr>
                  <th>{i18next.t("Nikname")}</th>
                  <th>{i18next.t("Email")}</th>
                  <th>{i18next.t("Status")}</th>
                  <th>{i18next.t("Action")}</th>
                </tr>
              </thead>

              <tbody>
                {invitations.length > 0 ? (
                  invitations.map((item, index) => (
                    <tr
                      style={
                        index !== invitations.length - 1
                          ? { borderBottom: "1px solid black" }
                          : {}
                      }>
                      <td title={item.nickName} className="text-white _tooltip">
                        {item.nickName.length > 25
                          ? item.nickName.substring(0, 25) + "..."
                          : item.nickName}
                      </td>
                      <td className="text-white">{item.email}</td>
                      <td
                        className={
                          item.invitationStatusCode == 0
                            ? "text-danger"
                            : "text-success"
                        }>
                        {item.invite_status}
                      </td>
                      <td onClick={() => Reinvite(item)}>
                        {item.invitationStatusCode == 0 ? (
                          // 'Reinvite'
                          <a className="btn blue-btn ">
                            <span>{i18next.t("Reinvite")}</span>
                          </a>
                        ) : (
                          ""
                        )}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={4} className="text-white">
                      {" "}
                      -{" "}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            {inviteLoading == true ? (
              <div className="w-100 mt-3 mb-2 m-auto">
                <CircularProgress />
              </div>
            ) : (
              <div
                id="invite_status"
                className="w-100 mt-3 mb-2 m-auto d-none p-3"></div>
            )}
          </div>
        </div>

        <div className="container-fluid mt-3 teamPage_row">
          <h2
            className={
              cookies.lang === "ar"
                ? "text-white text-end"
                : "text-white text-start"
            }>
            {i18next.t("Tournaments")}
          </h2>
          <div class="table-responsive teams_table table-bordered table-dark ">
            <table class="table table-striped mt-2">
              <thead className="text-white ">
                <tr>
                  <th></th>
                  <th>{i18next.t("Name")}</th>
                  <th>{i18next.t("Number_of_asigned_teams")}</th>
                  <th>{i18next.t("Status")}</th>
                  <th>{i18next.t("Start_date")}</th>
                  <th> {i18next.t("End_date")}</th>
                </tr>
              </thead>

              <tbody>
                {tournaments.length > 0 ? (
                  tournaments.map((item, index) => (
                    <tr
                      style={
                        index !== tournaments.length - 1
                          ? { borderBottom: "1px solid black" }
                          : {}
                      }>
                      <td>
                        <img src={item.logo} width={"40px"} height={"35px"} />{" "}
                      </td>
                      <td title={item.name} className="text-white _tooltip">
                        {" "}
                        {item.name.length > 25
                          ? item.name.substring(0, 25) + "..."
                          : item.name}
                      </td>
                      <td className="text-white">
                        {item.number_of_team_allowed}{" "}
                      </td>
                      <td className="text-white">{item.status}</td>
                      <td className="text-white">{item.StartDate}</td>
                      <td className="text-white">{item.EndDate}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={6} className="text-white">
                      {" "}
                      -{" "}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            {/* {loading == true ? (
              <div className="w-100 mt-3 mb-2 m-auto">
                <CircularProgress />
              </div>
            ) : (
              <div
                id="finish_mission1"
                className="w-100 mt-3 mb-2 m-auto d-none p-3"></div>
            )} */}
          </div>
        </div>

        <div className="container-fluid mt-5 ">
          <h2
            className={
              cookies.lang === "ar"
                ? "text-white text-end"
                : "text-white text-start"
            }>
            {i18next.t("Team_members")}
          </h2>

          <div className=" bg-grey p-4 mt-1 teamPage_row">
            <div className="row  ">
              {members.length > 0 ? (
                members.map((item, index) => (
                  <div
                    className="col-lg-2 col-md-4 text-center container_card m-2"
                    style={{ position: "relative" }}>
                    <div className="black-layer1 center_team">
                      {/* <a
                                  className=" yellow "
                                  style={{ top: "-10px" }}>
                                  <Space wrap>
                                    <Dropdown
                                      menu={menuProps}
                                      trigger={["click"]}>
                                      <Button
                                        onClick={() => handleOpenChange(item)}
                                        variant="link">
                                        <Space>
                                          <MenuOutlined />
                                        </Space>
                                      </Button>
                                    </Dropdown>
                                  </Space>
                                </a> */}
                      {/* <div
                                  className="text-center child_one"
                                  style={{ top: "-25px", left: "-30px" }}> */}
                      <div className="hexagon">
                        <img
                          src={
                            item.logo !== ""
                              ? item.logo
                              : "../assets/imgs/kidwatch.png"
                          }
                          className=" team-img"
                        />
                      </div>
                      <OverlayTrigger
                        key="top"
                        placement="top"
                        overlay={<Tooltip>{item.name}</Tooltip>}>
                        <p
                          className="letters_limit text-white"
                          style={{ marginTop: "20px" }}>
                          {item.name.length > 25
                            ? item.name.substring(0, 25) + "..."
                            : item.name}
                        </p>
                      </OverlayTrigger>
                      <p
                        className="letters_limit text-white"
                        style={{ marginTop: "20px" }}>
                        {i18next.t("level")} {item.level}
                      </p>
                    </div>
                  </div>
                ))
              ) : (
                <p className="text-center text-white mt-5 pt-5">
                  {i18next.t("No_members_registered")}
                </p>
              )}
            </div>
          </div>
        </div>
      </section>
      <div
        className="container-fluid text-start back p-5"
        style={{ paddingLeft: "25px" }}>
        <a className="btn blue-btn back_a" onClick={() => navigate(-1)}>
          <span className="p-2">{i18next.t("back")} </span>
          <span>
            <svg
              width="12"
              height="16"
              viewBox="0 0 12 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                d="M7.99245 3.65333C8.72578 3.65333 9.32578 3.05333 9.32578 2.32C9.32578 1.58667 8.72578 0.986668 7.99245 0.986668C7.25911 0.986668 6.65911 1.58667 6.65911 2.32C6.65911 3.05333 7.25911 3.65333 7.99245 3.65333ZM5.59245 12.92L6.25911 9.98667L7.65911 11.32V15.32H8.99245V10.32L7.59245 8.98667L7.99245 6.98667C8.85911 7.98667 10.1924 8.65333 11.6591 8.65333V7.32C10.3924 7.32 9.32578 6.65333 8.79245 5.72L8.12578 4.65333C7.85911 4.25333 7.45911 3.98667 6.99245 3.98667C6.79245 3.98667 6.65911 4.05333 6.45911 4.05333L2.99245 5.52V8.65333H4.32578V6.38667L5.52578 5.92L4.45911 11.32L1.19245 10.6533L0.925781 11.9867L5.59245 12.92Z"
                fill="white"
              />
            </svg>
          </span>
        </a>
      </div>
      <Footer bg="bg_black" />
    </div>
  );
}
