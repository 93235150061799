import * as React from 'react';
import  { Component } from 'react';
// import Modal from '../../Components/Modal';
import {useNavigate,useLocation} from 'react-router-dom';
import { useState, useEffect } from "react";
import { Modal,Button} from 'react-bootstrap';
import en from '../resources/en.json';
import config from "../config.json";
import { useDispatch, useSelector } from "react-redux";
import i18next from "../i18n/config";
import { useCookies } from "react-cookie";
import { useTranslation } from "react-i18next";

export default function DeleteAccountDialog(props) {
  const dispatch = useDispatch();
  const token = useSelector((state) => state.appReducer.token);

    const location = useLocation();
    const backEnd="https://phplaravel-592682-2484169.cloudwaysapps.com/api/";
  const front="http://127.0.0.1:8000/api/";
  let BaseUrl = config.BaseUrl;;
    const navigate = useNavigate();
    let [show, setShow] = useState(props.show);
    let [deletemission, setdeletemission] = useState('false');
    const id = useSelector((state) => state.appReducer.id);
    const [cookies, setCookie] = useCookies(["lang"]);
    const { i18n } = useTranslation();

    // const id=props.id;
    

   
    useEffect(() => {
      if(props.show==true){
        setShow(props.show);
        
      }
      }, [props.show]);
   
   const handleClose = () => {
          setShow(false);
          props.onChange('david');
    
      };

  const handlesubmit=(e)=>{
   e.preventDefault()
   fetch(`${BaseUrl}users/deleteAccount/${id}`,{
    method:'get',
    headers:{
      'Accept':'application/json',
      'Authorization': `Bearer ${token}`,
      "Accept-Language": cookies.lang,
    } 
  }).then(function(response){
     response.json().then(function(response){
       if(response.status==true){
         navigate('/veryalityApp');
      }
      })
         
    })
  }
  
  return (
    <>
      <div class="modelround" className="modal">
        <Modal
          // isOpen={ props.show }
          show={show}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}>
          <Modal.Header class="modelstyle" closeButton>
            <Modal.Title class="modelstyle">
              {en.RewardDialog.veryality}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body class="modelstyle">
            <h5
              className={
                cookies.lang === "ar"
                  ? " text-end font p-3"
                  : " text-start font p-3"
              }>
              {i18next.t("Confirmtodeleteaccount")}
              {/* {en.profile.Confirmtodeleteaccount} */}
            </h5>

            <Modal.Footer>
              <Button
                type="submit"
                className="bg_blue w-40"
                variant="primary"
                onClick={handlesubmit}>
                {/* {en.notifDialog.confirm} */}
                {i18next.t("confirm")}
              </Button>
              <Button className=" white-outline-btn1" onClick={handleClose}>
                {/* {en.RewardDialog.close} */}
                {i18next.t("close")}
              </Button>
            </Modal.Footer>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
}

