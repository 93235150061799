import * as React from "react";
import { Component } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import en from "../resources/en.json";
import config from "../config.json";
import { useDispatch, useSelector } from "react-redux";
import { Container, Row, Col, Button, Form, Modal } from "react-bootstrap";
import CircularProgress from "@mui/material/CircularProgress";
import { useCookies } from "react-cookie";
import i18next from "../i18n/config";
export default function InviteModal(props) {
  let BaseUrl = config.BaseUrl;
  const token = useSelector((state) => state.appReducer.token);
  const id = useSelector((state) => state.appReducer.id);
  const [cookies, setCookie] = useCookies(["lang"]);
  let [show, setShow] = useState(props.show);
  let [teamID, setTeamID] = useState(props.teamID);
  const [loading, setLoading] = useState(false);

  const [allMembers, setAllMembers] = useState([
    {
      nickName: "",
      email: "",
      user_id: id,
      team_id: teamID,
      invite_status: "0",
    },
  ]);

  useEffect(() => {
    if (props.show == true) {
      setTeamID(props.teamID);
      setAllMembers([
        {
          nickName: "",
          email: "",
          user_id: id,
          team_id: props.teamID,
          invite_status: "0",
        },
      ]);
      setShow(props.show);
    }
  }, [props.show]);

  const handleClose = () => {
    setShow(false);
    setAllMembers([
      {
        nickName: "",
        email: "",
        user_id: id,
        team_id: teamID,
        invite_status: "0",
      },
    ]);

    props.onChange("david");
  };

  const handlesubmit = (e) => {
    document.getElementById("finish_mission1").innerHTML = "";
    if (
      document
        .getElementById("finish_mission1")
        .classList.contains("alert-info") == true
    ) {
      document.getElementById("finish_mission1").classList.remove("alert-info");
    } else if (
      document
        .getElementById("finish_mission1")
        .classList.contains("alert-danger") == true
    ) {
      document
        .getElementById("finish_mission1")
        .classList.remove("alert-danger");
    }
    var validation = true;
    document.getElementById("form-team").classList.add("was-validated");
    if (document.getElementById("form-team").checkValidity()) {
      setLoading(true);
      fetch(`${BaseUrl}InviteUsers`, {
        method: "post",
        body: JSON.stringify(allMembers),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Accept-Language": "en",
        },
      }).then(function (response) {
        response.json().then(function (resp_finishmission) {
          setLoading(false);
          if (resp_finishmission.status == true) {
            document.getElementById("finish_mission1").innerHTML =
              i18next.t("InviteSuccess");
            document
              .getElementById("finish_mission1")
              .classList.remove("d-none");
            document
              .getElementById("finish_mission1")
              .classList.add("alert-info");
            setAllMembers([
              {
                nickName: "",
                email: "",
                user_id: id,
                team_id: teamID,
                invite_status: "0",
              },
            ]);
            document
              .getElementById("form-team")
              .classList.remove("was-validated");
          } else {
            document
              .getElementById("finish_mission1")
              .classList.remove("d-none");
            document
              .getElementById("finish_mission1")
              .classList.add("alert-danger");
            if (resp_finishmission.errNum == "E0100") {
              document.getElementById("finish_mission1").innerHTML =
                resp_finishmission.msg;
            } else
              document.getElementById("finish_mission1").innerHTML =
                i18next.t("InviteFailed");
          }
        });
      });
    }
  };
  const handleAddPlayers = () => {
    const values = [...allMembers];
    values.push({
      nickName: "",
      email: "",
      user_id: id,
      team_id: teamID,
      invite_status: "0",
    });
    setAllMembers(values);
  };

  const handleRemovePlayers = (index) => {
    const values = [...allMembers];
    values.splice(index, 1);
    setAllMembers(values);
  };

  const handleInputChange = (index, event) => {
    const values = [...allMembers];
    const updatedValue = event.target.name;
    values[index][updatedValue] = event.target.value;

    setAllMembers(values);
  };

  return (
    <>
      <div class="modelround" className="modal">
        <Modal
          show={show}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}>
          <Modal.Header class="modelstyle" closeButton>
            <Modal.Title class="modelstyle">
              {en.RewardDialog.veryality}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body
            className={
              cookies.lang === "ar"
                ? "rtl modelstyle text-start p-3"
                : "ltr modelstyle text-start p-3"
            }>
            <Form className="form-white" id="form-team">
              <Row className="justify-content-center mt-0">
                {allMembers.length > 0 && (
                  <>
                    {allMembers.map((field, index) => (
                      <Col xs="12 mt-3">
                        <div className="add-player-div  ">
                          <h4
                            className={
                              cookies.lang === "ar" ? "menu_rtl" : "menu_ltr"
                            }>
                            {i18next.t("Member")} {index + 1}
                          </h4>
                          <Form.Group
                            className="mb-3"
                            controlId="formBasicEmail">
                            <Form.Control
                              type="text"
                              className="form-control"
                              name="nickName"
                              required
                              placeholder={i18next.t("Enter_nick_name")}
                              value={field.nickName}
                              onChange={(event) =>
                                handleInputChange(index, event)
                              }
                            />
                            <div
                              className={
                                cookies.lang === "ar"
                                  ? "invalid-feedback text-end text-danger"
                                  : "invalid-feedback text-start text-danger"
                              }>
                              {i18next.t("name_validation")}{" "}
                            </div>
                          </Form.Group>
                          <Form.Group
                            className="mb-3"
                            controlId="formBasicEmail">
                            <Form.Control
                              type="email"
                              name="email"
                              form-control="form-control"
                              required
                              placeholder={i18next.t("inv_email")}
                              value={field.email}
                              onChange={(event) =>
                                handleInputChange(index, event)
                              }
                            />
                            <div
                              className={
                                cookies.lang === "ar"
                                  ? "invalid-feedback text-end text-danger"
                                  : "invalid-feedback text-start text-danger"
                              }>
                              {i18next.t("mail_validation")}{" "}
                            </div>
                          </Form.Group>
                          <Button
                            variant="secondary"
                            size="sm"
                            onClick={() => handleRemovePlayers(index)}>
                            {i18next.t("Cancel")}
                          </Button>
                        </div>
                      </Col>
                    ))}
                  </>
                )}
              </Row>
            </Form>

            <span
              className="float-end p-2 point"
              onClick={() => handleAddPlayers()}>
              <svg
                width="34"
                height="34"
                viewBox="0 0 34 34"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M17.0007 0.333252C7.80065 0.333252 0.333984 7.79992 0.333984 16.9999C0.333984 26.1999 7.80065 33.6666 17.0007 33.6666C26.2007 33.6666 33.6673 26.1999 33.6673 16.9999C33.6673 7.79992 26.2007 0.333252 17.0007 0.333252ZM25.334 18.6666H18.6673V25.3333H15.334V18.6666H8.66732V15.3333H15.334V8.66659H18.6673V15.3333H25.334V18.6666Z"
                  fill="#100E23"
                />
              </svg>
            </span>
          </Modal.Body>
          <Modal.Footer>
            <Button
              type="submit"
              className="bg_blue w-40"
              variant="primary"
              onClick={handlesubmit}>
              {i18next.t("invite_team")}
            </Button>
            <Button className="white-outline-btn1" onClick={handleClose}>
              {i18next.t("close")}
            </Button>
            {loading == true ? (
              <div className="w-100 mt-3 mb-2 m-auto">
                <CircularProgress />
              </div>
            ) : (
              <div
                id="finish_mission1"
                className="w-100 mt-3 mb-2 m-auto d-none p-3"></div>
            )}
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
}
