import * as React from 'react';
import { NavLink} from 'react-router-dom';
import Head from '../layout/Head';
import Nav from '../layout/Nav';
import Footer from '../layout/Footer';
import en from '../resources/en.json'
import SignGamer from './components/SignGamer';
import SignParent from './components/SignParent';
import i18next from '../i18n/config';
import { useCookies } from "react-cookie";
import { useTranslation } from "react-i18next";

export default function RegisterAccountType()  {
    const { i18n } = useTranslation();
    const [cookies, setCookie] = useCookies(["lang"]);

    React.useEffect(() => {
        if (cookies && cookies.lang) {
          i18n.changeLanguage(cookies.lang);
        } else {
          i18n.changeLanguage("en");
        }
      }, []);
return (
    
    <div>
        <Head/>
        <section className='sign container-fluid p-2 pb-5'>
        <Nav/>
        <div className='flash1'></div>
        <div className='flash2'></div>
        <div className='flash3'></div>
        <div className='container text-center mt-5 pt-3'>
            <h2> {i18next.t('sign_createAs')} </h2>
            <div className='d-inline-flex p-2 d-flex justify-content-center SignUp  pt-3 pb-3'>

                <div className='col-md-6 SignUpBtns pt-3 pb-3'>

                    <img src='../assets/imgs/parentIcon.png' width='40px' height='40px' className='' alt="icon"/>
                    <NavLink className='nav-link text-white '  to="/RegisterParent"><h2>{i18next.t('sign_ParentCaption')} </h2></NavLink>


                </div>

                <div className='col-md-6 SignUpBtns pt-3 pb-3'>

                    <img src='../assets/imgs/gamer.png' width='40px' height='40px' className='' alt="icon"/>
                    <NavLink className='nav-link text-white '  to="/RegisterGamer"><h2>{i18next.t('sign_GamerCaption')} </h2></NavLink>

                </div>


            </div>
          <NavLink className='nav-link text-white link'  to="/veryalityApp">{i18next.t('have_account')} </NavLink>

        </div>
        </section>


        <Footer bg='bg_blue'/>
    </div>
  );
}
