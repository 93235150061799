import * as React from "react";
import { Component } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import { Modal, Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import en from "../resources/en.json";
import config from "../config.json";
import { useDispatch, useSelector } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";
import i18next from '../i18n/config';
import { useCookies } from 'react-cookie';
export default function ViewTournamentModal(props) {
  let BaseUrl = config.BaseUrl;
  const token = useSelector((state) => state.appReducer.token);
  const id = useSelector((state) => state.appReducer.id);
  const [loading, setLoading] = useState(false);

  let [show, setShow] = useState(props.show);
  let [Tournament, setTournament] = useState({
    name: props.Tournamentname,
    logo: "",
    number_of_team_allowed: props.number_of_team_allowed,
    min_team_level: props.min_team_level,
    min_team_members_level: props.min_team_members_level,
  });
  const [cookies, setCookie] = useCookies(['lang']);
  useEffect(() => {
    if (props.show == true) {
      setShow(props.show);
              setLoading(true);
      fetch(`${BaseUrl}GetTournament/${props.TournamentID}`, {
        method: "get",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          'Accept-Language': 'en',
        },
      }).then(function (response) {
        response.json().then(function (resp) {
                    setLoading(false);
          // if(resp.status==true){
          setTournament({
            name: resp.data.name,
            logo: resp.data.logo,
            number_of_team_allowed: resp.data.number_of_team_allowed,
            min_team_level: resp.data.min_team_level,
            min_team_members_level: resp.data.min_team_members_level,
          });

          // }
        });
      });
      // setTeamName(props.teamName);
    }
  }, [props.show]);

  const handleClose = () => {
    setShow(false);
    // props.onChange('david');
  };

  const [width, setwidth] = useState(window.innerWidth);
  useEffect(() => {
    function handleResize() {
      setwidth(window.innerWidth)
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  return (
    <>
      <div class="modelround"  className="modal">
        <Modal
          show={show}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}>
          <Modal.Header class="modelstyle" closeButton>
            <Modal.Title class="modelstyle">
              {en.RewardDialog.veryality}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body class="modelstyle text-center p-3 ">
            <p class="margin_p">{i18next.t('tournament_details')}</p>
            {loading == true ? (
              <div className="w-100 mt-3 mb-2 m-auto">
                <CircularProgress />
              </div>
            ) : (
              <div class="container text-start mt-3 d-flex justify-content-center">
                <div
                  class="card w-75  border"
                  style={{
                    borderRadius: "20px",
                    backgroundColor: "#f5f5f5bd",
                  }}>
                  <div class=" d-flex justify-content-center">
                    <img
                      class="card-img-top w-50 p-3  rounded-circle"
                      src={
                        Tournament.logo == ""
                          ? "../assets/imgs/account_8.png"
                          : Tournament.logo
                      }
                      alt="Card image"
                      style={{ width: "150px", height: "150px" }}
                    />
                  </div>
                  <div class="card-body"  >
                  <h4 class="card-title  text-center">
                      <strong>{Tournament.name}</strong>
                    </h4>
                    <br></br>
                    <p class="card-text m-0 ">
                      <div  className={cookies.lang  === 'ar' ? 'row nnn_ar  aligh_ar ' : 'row nnn_en aligh_en'}>
                        <small className="col-9">{i18next.t('Number_of_team_allowed')}</small>
                        <span className="col-3" style={{ color: "#2A49B6" }}>
                        {Tournament.number_of_team_allowed}
                      </span>
                      </div>



                    </p>
                    <br></br>
                    <p class="card-text m-0">
                      <div  className={cookies.lang  === 'ar' ? 'row nnn_ar  aligh_ar ' : 'row nnn_en aligh_en'}>
                        <small className="col-9">{i18next.t('Min_team_level')}</small>
                        <span className="col-3" style={{ color: "#2A49B6" }}>
                        {Tournament.min_team_level}
                      </span>
                      </div>

                      <br></br>

                    </p>

                    <p class="card-text">
                      <div className={cookies.lang  === 'ar' ? 'row nnn_ar  aligh_ar ' : 'row nnn_en aligh_en'}>
                        <small className="col-9">{i18next.t('Min_team_members_level')}</small>
                         <span className="col-3" style={{ color: "#2A49B6" }}>
                        {Tournament.min_team_members_level}
                      </span>
                      </div>

                      <br></br>

                    </p>
                  </div>
                </div>
              </div>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button className="white-outline-btn1" onClick={handleClose}>
            {i18next.t('close')}
            </Button>
            <div
              id="finish_mission1"
              className="w-100 mt-3 mb-2 m-auto d-none p-3"></div>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
}
