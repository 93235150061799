import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import Head from "../layout/Head";
import Nav from "../layout/Nav";
import Usernav from "../layout/Usernav";
import Footer from "../layout/Footer";
import { NavLink } from "react-router-dom";
import en from "../resources/en.json";
import config from "../config.json";
import { useDispatch, useSelector } from "react-redux";
import { useCookies } from "react-cookie";
import { useTranslation } from "react-i18next";
import i18next from "../i18n/config";

export default function Resetpassword(props) {
  const dispatch = useDispatch();
  const token = useSelector((state) => state.appReducer.token);
  const id = useSelector((state) => state.appReducer.id);

  const navigate = useNavigate();
  const location = useLocation();
  // const id = location.state.id;
  const username = useSelector((state) => state.appReducer.username);
  const level = useSelector((state) => state.appReducer.level);
  const [items, setitems] = useState([]);
  const backEnd = "https://phplaravel-592682-2484169.cloudwaysapps.com/api/";
  const front = "http://127.0.0.1:8000/api/";
  const { i18n } = useTranslation();
  const [cookies, setCookie] = useCookies(["lang"]);

  let BaseUrl = config.BaseUrl;
  const [form, setform] = useState({
    email: "",
    id: id,
    oldpassword: "",
    newpassword: "",
  });

  useEffect(() => {
    if (cookies && cookies.lang) {
      i18n.changeLanguage(cookies.lang);
    } else {
      i18n.changeLanguage("en");
    }
  }, []);
  
  function submit() {
    document.getElementById("form-log").classList.add("was-validated");
    if (
      !document
        .getElementById("newpassword")
        .value.match(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{7,20}$/)
    ) {
      document.getElementById("pass").style.display = "block";
    } else {
      document.getElementById("pass").style.display = "none";
    }
    if (
      document.getElementById("confirmepassword").value !=
      document.getElementById("newpassword").value
    ) {
      document.getElementById("confirm").style.display = "block";
    } else {
      document.getElementById("confirm").style.display = "none";
    }
    if (
      document.getElementById("confirmepassword").value ==
        document.getElementById("newpassword").value &&
      document
        .getElementById("newpassword")
        .value.match(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{7,20}$/)
    ) {
      fetch(`${BaseUrl}resetPassword`, {
        method: "post",
        body: JSON.stringify(form),
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          "Accept-Language": cookies.lang,
        },
      }).then(function (response) {
        response.json().then(function (resetpassResp) {
          document.getElementById("message1").style.display = "block";
          if (resetpassResp.status == true) {
            document.getElementById("message1").innerHTML =
              i18next.t("resetpass_success");
          } else {
            document.getElementById("message1").innerHTML =
              i18next.t("login_check");
          }
        });
      });
    }
  }

  return (
    <div>
      <Head />
      <div className="container-fluid p-2 pb-5 sign vh">
        <Usernav />
        <div className="flash1"></div>
        <div className="flash2"></div>
        <div className="flash3"></div>
        <div className="container text-center mt-5 pt-3">
          <h2 className="text-white">{i18next.t("resetpassword_title")}</h2>
          <div class=" mt-3 ">
            <div class="container ">
              <br />
              <form className=" m-auto form-white" id="form-log">
                <div class="mb-3 ">
                  <input
                    type="email"
                    class="form-control"
                    id="email"
                    placeholder={i18next.t("mail_placeholder")}
                    onFocus={(e) => (e.target.placeholder = "")}
                    onBlur={(e) =>
                      (e.target.placeholder = i18next.t("mail_placeholder"))
                    }
                    name="email"
                    onChange={(e) =>
                      setform({ ...form, email: e.target.value })
                    }
                    required
                  />
                  <div className="invalid-feedback text-start text-danger">
                    {i18next.t("mail_validation")}{" "}
                  </div>
                </div>
                <div class="mb-3">
                  <input
                    type="password"
                    class="form-control"
                    id="oldpassword"
                    placeholder={i18next.t("old_password")}
                    onFocus={(e) => (e.target.placeholder = "")}
                    onBlur={(e) =>
                      (e.target.placeholder = i18next.t("old_password"))
                    }
                    name="oldpassword"
                    onChange={(e) =>
                      setform({ ...form, oldpassword: e.target.value })
                    }
                    required
                  />
                  <div className="invalid-feedback text-start text-danger">
                    {i18next.t("password_validation")}{" "}
                  </div>
                </div>

                <div class="mb-3">
                  <input
                    type="password"
                    class="form-control"
                    id="newpassword"
                    placeholder={i18next.t("new_password")}
                    onFocus={(e) => (e.target.placeholder = "")}
                    onBlur={(e) =>
                      (e.target.placeholder = i18next.t("new_password"))
                    }
                    name="newpassword"
                    onChange={(e) =>
                      setform({ ...form, newpassword: e.target.value })
                    }
                    required
                  />
                  <div className="invalid-feedback text-start text-danger">
                    {i18next.t("password_validation")}{" "}
                  </div>
                  <div
                    className="invalid-feedback text-start text-danger"
                    id="pass">
                    {i18next.t("password_validation_contain")}
                  </div>
                </div>

                <div class="mb-3">
                  <input
                    type="password"
                    class="form-control"
                    id="confirmepassword"
                    placeholder={i18next.t("confirme_password")}
                    onFocus={(e) => (e.target.placeholder = "")}
                    onBlur={(e) =>
                      (e.target.placeholder = i18next.t("confirme_password"))
                    }
                    name="confirmepassword"
                    onChange={(e) =>
                      setform({ ...form, password: e.target.value })
                    }
                    required
                  />
                  <div className="invalid-feedback text-start text-danger">
                    {i18next.t("password_validation")}{" "}
                  </div>
                  <div
                    className="invalid-feedback text-start text-danger"
                    id="confirm">
                    {i18next.t("confirmpassword_match")}{" "}
                  </div>
                </div>

                <a
                  className="btn yellow-btn mr-5 mt-4"
                  id="sign"
                  type="submit"
                  onClick={submit}>
                  <span className="m-3">{i18next.t("resetpass_btn")}</span>
                  <svg
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M7.66659 1.66668V3.44668L6.99992 4.11334L6.33325 3.44668V1.66668H7.66659ZM12.3333 6.33334V7.66668H10.5533L9.88659 7.00001L10.5533 6.33334H12.3333ZM3.44659 6.33334L4.11325 7.00001L3.44659 7.66668H1.66659V6.33334H3.44659ZM6.99992 9.88668L7.66659 10.5533V12.3333H6.33325V10.5533L6.99992 9.88668ZM8.99992 0.333344H4.99992V4.00001L6.99992 6.00001L8.99992 4.00001V0.333344ZM13.6666 5.00001H9.99992L7.99992 7.00001L9.99992 9.00001H13.6666V5.00001ZM3.99992 5.00001H0.333252V9.00001H3.99992L5.99992 7.00001L3.99992 5.00001ZM6.99992 8.00001L4.99992 10V13.6667H8.99992V10L6.99992 8.00001Z"
                      fill="#100E23"
                    />
                  </svg>
                </a>

                <div
                  id="message1"
                  className="w-100 mt-4 mb-5 m-auto alert-info p-3"></div>
              </form>
            </div>
          </div>
        </div>
        <div
          className="container-fluid text-start  pt-5"
          style={{ paddingLeft: "150px" }}>
          <a className="btn blue-btn back_a" onClick={() => navigate(-1)}>
            <span className="p-2">{i18next.t("back")}</span>
            <span>
              <svg
                width="12"
                height="16"
                viewBox="0 0 12 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M7.99245 3.65333C8.72578 3.65333 9.32578 3.05333 9.32578 2.32C9.32578 1.58667 8.72578 0.986668 7.99245 0.986668C7.25911 0.986668 6.65911 1.58667 6.65911 2.32C6.65911 3.05333 7.25911 3.65333 7.99245 3.65333ZM5.59245 12.92L6.25911 9.98667L7.65911 11.32V15.32H8.99245V10.32L7.59245 8.98667L7.99245 6.98667C8.85911 7.98667 10.1924 8.65333 11.6591 8.65333V7.32C10.3924 7.32 9.32578 6.65333 8.79245 5.72L8.12578 4.65333C7.85911 4.25333 7.45911 3.98667 6.99245 3.98667C6.79245 3.98667 6.65911 4.05333 6.45911 4.05333L2.99245 5.52V8.65333H4.32578V6.38667L5.52578 5.92L4.45911 11.32L1.19245 10.6533L0.925781 11.9867L5.59245 12.92Z"
                  fill="white"
                />
              </svg>
            </span>
          </a>
        </div>
      </div>

      <Footer bg="bg_blue" />
    </div>
  );
}
