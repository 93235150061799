
//////////////All action types //////////////////


//////////////////System state///////////////

////////////Auth//////////////
export const TOKEN = 'TOKEN';
export const ROLE = 'ROLE';
export const LEVEL = 'LEVEL';
export const TournamentID = 'TournamentID';

////////////////Mission///////////////