import * as React from 'react';
import { NavLink} from 'react-router-dom';
import {useNavigate} from 'react-router-dom';
import { useState, useEffect } from "react";
import Head from '../layout/Head';
import Usernav from '../layout/Usernav';
import Footer from '../layout/Footer';
import Game from '../layout/Game';
import {useLocation } from 'react-router-dom';
import ReactLoading from "react-loading";
import en from '../resources/en.json'
import { isVisible } from '@testing-library/user-event/dist/utils';
import config from "../config.json";
import { useDispatch, useSelector } from "react-redux";


export default function ChildMissionDetails(props)  {
  const [isLoading, setLoading] = useState(false);
  const [visible, setVisible] = useState(true);
  
       const navigate = useNavigate();
       const location = useLocation();
    //    const id=location.state.id;
       const id = useSelector((state) => state.appReducer.id);

    const username = useSelector((state) => state.appReducer.username);
    const level=useSelector((state) => state.appReducer.level);
    const item=location.state.num1;
       const index=location.state.index;
       


      var hostName =window.location.origin;
      const backend="https://phplaravel-592682-2484169.cloudwaysapps.com/api/";
      const front="http://127.0.0.1:8000/api/";
      let BaseUrl=config.BaseUrl;;
      const [width, setwidth] = useState(window.innerWidth);
      useEffect(() => {
        function handleResize() {
          setwidth(window.innerWidth)
        }
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
      }, []);
    
  
    return (
        <div>
            <Head/>
            {width > 950 ?
            (
                <div className='desktop'>
                <section className=' container-fluid p-2 bg_black'>
                <Usernav />
                    <div className='container mt-3  bg-grey p-4 '>
                        <div className='pb-3'>
                            <a>
                            <span className='float-start text-white'>{en.dgamer.mission_with_reward_details}</span>
                            </a>
                            <a className='float-end yellow'>
                            <span ></span>
                            </a>
                        </div><br></br><br></br>
                        <div className='kid-manage p-4'>
                            <div className='pb-3'>
                                    <a>
                                    <span className='float-start yellow kid text-capitalize'>{username}</span>
                                    </a>

                            </div><br></br><br></br>
                            <div className='row'>
                                <div className='col-md-5 kid-char pb-3'>
                                    <div className='row'>
                                        <div className=' text-center'>
                                            <img src='../assets/imgs/kidchar.png' className='img-fluid mb-3'/>
                                            <p>{username}</p>
                                        </div>
                                    </div>

                                </div>
                                <div className='col-md-1 pt-3 pb-3 vector'>
                                    <svg width="40" height="29" viewBox="0 0 40 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M26 0.5L23.18 3.32L32.34 12.5H0V16.5H32.34L23.16 25.68L26 28.5L40 14.5L26 0.5Z" fill="#EECE52"/>
                                    </svg>
                                </div>
                                <div className='col-md-6 kid-mission pt-3 pb-2'>
                                    <p  >{en.dgamer.mission_with_reward_details_}</p>
                                    {visible ? (
                                    <div class=" mb-2 pt-2">
                                        <a class="list-group-item list-group-item-action mb-2 pb-3">
                                            <p className='tit text-center'>{en.dgamer.mission_info}</p>
                                            <span className='desc'>{en.dgamer.mission_name} </span>
                                            <span className='desc'>{item.name}</span><br/><br/>
                                            <span className='desc'>{en.dgamer.mission_description} </span>
                                            <span className='desc'>{item.description}</span><br/><br/>
                                            <span className='desc'>{en.dgamer.mission_start_date} </span>
                                            <span className='desc'>{item.startDate.split(" ")[0]}</span><br/><br/>
                                            <span className='desc'>{en.dgamer.mission_end_date} </span>
                                            <span className='desc'>{item.endDate.split(" ")[0]}</span><br/><br/>
                                            <hr className='text-white'/>
                                            <p className='tit text-center mt-4'>{en.dgamer.rewardinfo}</p>
                                            <span className='desc '> </span>
                                            <span className='desc'>{en.dgamer.rewardname} </span>
                                            <span className='desc'>{item.rewards[0].name}</span><br/><br/>
                                            <span className='desc'>{en.dgamer.reward_description} </span>
                                            <span className='desc'>{item.rewards[0].description}</span><br/><br/>
                                        </a>
                                    </div>
                                    ):(
                                        <div class="list-group mb-2 pt-2">
                                        <a  class="list-group-item list-group-item-action mb-2 pb-3">
                                            <p className='tit text-center'>{en.challenge.no_assigned_challenges}</p>
                                            
                                            
                                        </a>
                                    </div>
                                    )}

                                </div>

                            </div>
                        </div>
                        <div className='d-flex justify-content-end  back'>
                           <a className='btn blue-btn back_a' onClick={() => navigate(-1)}>
                               <span className='p-2'>
                               {en.dgamer.back}
                               </span>
                               <span>
                                   <svg width="12" height="16" viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                   <path d="M7.99245 3.65333C8.72578 3.65333 9.32578 3.05333 9.32578 2.32C9.32578 1.58667 8.72578 0.986668 7.99245 0.986668C7.25911 0.986668 6.65911 1.58667 6.65911 2.32C6.65911 3.05333 7.25911 3.65333 7.99245 3.65333ZM5.59245 12.92L6.25911 9.98667L7.65911 11.32V15.32H8.99245V10.32L7.59245 8.98667L7.99245 6.98667C8.85911 7.98667 10.1924 8.65333 11.6591 8.65333V7.32C10.3924 7.32 9.32578 6.65333 8.79245 5.72L8.12578 4.65333C7.85911 4.25333 7.45911 3.98667 6.99245 3.98667C6.79245 3.98667 6.65911 4.05333 6.45911 4.05333L2.99245 5.52V8.65333H4.32578V6.38667L5.52578 5.92L4.45911 11.32L1.19245 10.6533L0.925781 11.9867L5.59245 12.92Z" fill="white"/>
                                   </svg>
                               </span>
                           </a>
                       </div>
                    </div>
                    
                </section>
    
                <section class="container-fluid p-2 bg_black" >
  
                </section>
            </div>
            )
            :
            (
                <div className='mobile'>
                <section className=' container-fluid p-4 bg_black'>
                <Usernav />
                    <div className='container-fluid mt-3  bg-grey p-4 '>
                        <div className='pb-3'>
                            <a>
                            <span className='float-start text-white'>{en.dgamer.mission_with_reward_details}</span>
                            </a>
                        </div><br></br><br></br>
                        <div className='kid-manage p-3'>
                            <div className='pb-3'>
                                    <a>
                                    <span className='float-start yellow kid'>{username}</span>
                                    </a>

                            </div><br></br><br></br>
                            <div className='kid-char pdash '>
                                <div class="tab-pane container active" id="kid-avatar">
                                       <div className=' text-center'>
                                            <img src='../assets/imgs/kidchar.png' className='img-fluid mb-3'/>
                                            <p>{username}</p>
                                          
                                        </div>
                                </div>
    
                            </div>
    
                            <div className='kid-mission pt-3 pb-3 mt-3'>
                                    <p  >{en.dgamer.mission_with_reward_details_}</p>
                                {visible ? (
    
                                    <div className=" mb-3 pt-2">
                                        <a className="list-group-item list-group-item-action mb-2 pb-3">
                                            <p className='tit text-center'>{en.dgamer.mission_info}</p>
                                            <span className='desc '>{en.dgamer.mission_name} </span>
                                            <span className='desc'>{item.name}</span><br/>
                                            <span className='desc '>{en.dgamer.mission_description} </span>
                                            <span className='desc'>{item.description}</span><br/>
                                            <span className='desc '>{en.dgamer.mission_start_date} </span>
                                            <span className='desc'>{item.startDate.split(" ")[0]}</span><br/>
                                            <span className='desc '>{en.dgamer.mission_end_date} </span>
                                            <span className='desc'>{item.endDate.split(" ")[0]}</span><br/>
                                            <hr className='text-white'/>
                                            <p className='tit text-center mt-4'>{en.dgamer.rewardinfo}</p>
                                            <span className='desc'> </span>
                                                <span className='desc'>{en.dgamer.rewardname} </span>
                                                <span className='desc'>{item.rewards[0].name}</span><br/>
                                                <span className='desc'>{en.dgamer.reward_description} </span>
                                                <span className='desc'>{item.rewards[0].description}</span><br/>
                                        </a>
    
                                    </div>
                                ):(
    
                                    <div className=" mb-2 pt-2">
                                        <a className="list-group-item list-group-item-action mb-2 pb-3">
                                            <p className='tit text-center'>{en.challenge.no_assigned_challenges}</p>
    
    
                                        </a>
                                    </div>
                                )}
    
    
                                </div>
                        </div>
                        <div className='d-flex justify-content back_m'>
                               <a className='btn blue-btn back_a_m' onClick={() => navigate(-1)}>
                                   <span className='p-2'>
                                   {en.dgamer.back}
                                   </span>
                                   <span>
                                       <svg width="12" height="16" viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                       <path d="M7.99245 3.65333C8.72578 3.65333 9.32578 3.05333 9.32578 2.32C9.32578 1.58667 8.72578 0.986668 7.99245 0.986668C7.25911 0.986668 6.65911 1.58667 6.65911 2.32C6.65911 3.05333 7.25911 3.65333 7.99245 3.65333ZM5.59245 12.92L6.25911 9.98667L7.65911 11.32V15.32H8.99245V10.32L7.59245 8.98667L7.99245 6.98667C8.85911 7.98667 10.1924 8.65333 11.6591 8.65333V7.32C10.3924 7.32 9.32578 6.65333 8.79245 5.72L8.12578 4.65333C7.85911 4.25333 7.45911 3.98667 6.99245 3.98667C6.79245 3.98667 6.65911 4.05333 6.45911 4.05333L2.99245 5.52V8.65333H4.32578V6.38667L5.52578 5.92L4.45911 11.32L1.19245 10.6533L0.925781 11.9867L5.59245 12.92Z" fill="white"/>
                                       </svg>
                                   </span>
                               </a>
                           </div>
                    </div>
                   
                </section>
                
            
        
        
                </div>
            )
           }
            
           
           
                       
                       
                   
         <Footer bg='bg_black'/>
     </div>
      );
    }