import * as React from "react";
import { Component } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import { Modal, Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import en from "../resources/en.json";
import config from "../config.json";
import { useDispatch, useSelector } from "react-redux";
import TournamentchallengItem from "./TournamentchallengItem";
import TournamentchallengItemNew from "./TournamentchallengItemNew";
import CircularProgress from "@mui/material/CircularProgress";
import { useCookies } from "react-cookie";
import i18next from "../i18n/config";
export default function TournamentchallengesModal(props) {
  let BaseUrl = config.BaseUrl;
  const token = useSelector((state) => state.appReducer.token);
  const id = useSelector((state) => state.appReducer.id);
  let [status, setstatus] = useState(0);
  let [tournaments, setTournaments] = useState([]);
  let [show, setShow] = useState(props.show);
  const [loading, setLoading] = useState(false);
  const [cookies, setCookie] = useCookies(["lang"]);
  let [teams, setTeams] = useState([]);
  let [team, setTeam] = useState({
    name: "",
    logo: "",
    XP_points: 0,
    user_id: id,
    level_id: 0,
  });

  useEffect(() => {
    if (props.show == true) {
      setShow(props.show);
    }
  }, [props.show]);

  const handleClose = () => {
    setShow(false);
    // props.onChange('david');
  };
  useEffect(() => {
    setLoading(true);
    fetch(`${BaseUrl}ViewtournamentChallenges/${props.Tournament_id}`, {
      method: "get",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
        "Accept-Language": cookies.lang,
      },
    }).then(function (response11) {
      response11.json().then(function (Resp) {
        setLoading(false);
        if (Resp.status == true) {
          setTournaments(Resp.data);
        }
      });
    });
  }, []);
  // useEffect(() => {
  //   fetch(`${BaseUrl}teams/${id}`, {
  //     method: "get",
  //     headers: {
  //       Accept: "application/json",
  //       Authorization: `Bearer ${token}`,
  //       "Accept-Language": "en",
  //     },
  //   }).then(function (response11) {
  //     response11.json().then(function (Resp) {
  //       setTeams(Resp.data);
  //       setLoading(false);
  //     });
  //   });
  // }, []);
  return (
    <>
      <div class="modelround" className="modal">
        <Modal
          show={show}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}>
          <Modal.Header class="modelstyle" closeButton>
            <Modal.Title class="modelstyle">
              {en.RewardDialog.veryality}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body class="modelstyle text-center p-3">
            <p class="margin_p">{i18next.t("tournament_challenges")}</p>
            {/* <div className="row " id="kid_assign"> */}
            {/* <div className="col-md-12 kid-char pb-0"> */}
            {loading == true ? (
              <div className="w-100 mt-3 mb-2 m-auto">
                <CircularProgress />
              </div>
            ) : (
              <div
                className={
                  cookies.lang === "ar"
                    ? "rtl row childs_row new_p"
                    : "ltr row childs_row new_p"
                }
                style={{
                  margin: "1px",
                  justify_content: "center",
                  align_items: "center",
                }}>
                {tournaments && tournaments.length > 0 ? (
                  tournaments.map((item, index) => (
                    <TournamentchallengItemNew
                      challengeName={item.challeng_name}
                      Favorite_destination={item.Favorite_destination}
                      Goal={item.Goal}
                    />
                  ))
                ) : (
                  <div className="  text-white text-center container  ">
                    {/* {en.dgamer.noReward} */}
                    {i18next.t("noChallenge")}
                  </div>
                )}
              </div>
            )}
            {/* </div> */}
            {/* </div> */}
          </Modal.Body>
          <Modal.Footer>
            {/* <Button type='submit' className='bg_blue w-40' variant="primary" onClick={handlesubmit}>
                                {en.editRewardDialog.create}
                            </Button> */}
            <Button className="white-outline-btn1" onClick={handleClose}>
              {i18next.t("close")}
            </Button>
            <div
              id="finish_mission1"
              className="w-100 mt-3 mb-2 m-auto d-none p-3"></div>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
}
