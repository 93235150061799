import * as React from "react";
import { Component } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import { Modal, Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import en from "../resources/en.json";
import config from "../config.json";
import { useDispatch, useSelector } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";
import { useCookies } from 'react-cookie';
import i18next from '../i18n/config';
export default function ParentChildChallengesDialog(props) {
  const backEnd = "https://phplaravel-592682-2484169.cloudwaysapps.com/api/";
  const front = "http://127.0.0.1:8000/api/";
  let BaseUrl = config.BaseUrl;
  const username = useSelector((state) => state.appReducer.username);
  const token = useSelector((state) => state.appReducer.token);
  const id = useSelector((state) => state.appReducer.id);
  const [loading, setLoading] = useState(false);

  // const id = props.id;
  const navigate = useNavigate();
  const [cookies, setCookie] = useCookies(['lang']);
  const [challenges, setchallenges] = useState([]);
  let [show, setShow] = useState(props.show);
  useEffect(() => {
    if (props.show == true) {
      setShow(props.show);
    }
  }, [props.show]);

  useEffect(() => {
    if (token === "") {
      navigate("/veryalityApp");
    }
    setLoading(true);
    fetch(`${BaseUrl}getChildesChallengedNeedApprove/${id}`, {
      method: "get",
      body: JSON.stringify(),
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        "Accept-Language": cookies.lang,
      },
    }).then(function (response) {
      response.json().then(function (getChildesChallengedNeedApprove_response) {
        setLoading(false);
        if (getChildesChallengedNeedApprove_response.data.length != 0) {
          setchallenges(getChildesChallengedNeedApprove_response.data);
        }
      });
    });
  }, []);

  function selectchallenge(index, num1) {
    setShow(false);
    navigate("/childchallengedetails", { state: { num1: num1, index: index } });
  }

  const handleClose = () => {
    setShow(false);
    props.onChange("david");
  };

  const location = useLocation();

  return (
    <>
      <div className="modal modelround ">
        <Modal
          show={show}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}>
          <Modal.Header class="modelstyle" closeButton>
            <Modal.Title class="modelstyle">
              {en.RewardDialog.veryality}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body  className={cookies.lang  === 'ar' ? 'modelstyle text-end p-3' : 'modelstyle text-start p-3'}>
            <p class="margin_p">
            {i18next.t('childChallenge')}
            </p>
            <small  className={cookies.lang  === 'ar' ? 'text-danger text-end' : 'text-danger text-start'} id="editreward_alert">
            {i18next.t('note_child')} 
            </small>
            <br />
            <div>
              <div className=" kid-mission pt-3 pb-3">
                {loading == true ? (
                  <div className="w-100 mt-3 mb-2 m-auto">
                    <CircularProgress />
                  </div>
                ) : (
                  <div class="list-group mb-3 pt-2">
                    {challenges.length != 0 ? (
                      challenges.map((item, index) => (
                        <a
                          class="list-group-item  list-group-item-action mb-2 kid_mis"
                          onClick={() => selectchallenge(index, item[0])}>
                          <div className="row">
                            <span className="w-10">
                              <svg
                                width="16"
                                height="20"
                                viewBox="0 0 16 20"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                  d="M14 0H2C0.9 0 0 0.9 0 2V18C0 19.1 0.9 20 2 20H14C15.1 20 16 19.1 16 18V2C16 0.9 15.1 0 14 0ZM5 2H7V7L6 6.25L5 7V2ZM14 18H2V2H3V11L6 8.75L9 11V2H14V18Z"
                                  fill="#EECE52"
                                />
                              </svg>
                            </span>
                            <span className="w-90">
                              <span className="desc">
                              {i18next.t('theChallenge')} 
                                <OverlayTrigger
                                  key="top"
                                  placement="top"
                                  overlay={
                                    <Tooltip>{item[0].Challenge_Name}</Tooltip>
                                  }>
                                  <>
                                    {item[0].Challenge_Name.length > 27
                                      ? item[0].Challenge_Name.substring(
                                          0,
                                          27
                                        ) + "..."
                                      : item[0].Challenge_Name}
                                  </>
                                </OverlayTrigger>
                                {i18next.t('challengeAssigned')}
                                <OverlayTrigger
                                  key="top"
                                  placement="top"
                                  overlay={
                                    <Tooltip>{item[0].child_Name}</Tooltip>
                                  }>
                                  <>
                                    {item[0].child_Name.length > 27
                                      ? item[0].child_Name.substring(0, 27) +
                                        "..."
                                      : item[0].child_Name}
                                  </>
                                </OverlayTrigger>
                                {i18next.t('needApprove')} 
                              </span>
                              <br></br>
                            </span>
                          </div>
                        </a>
                      ))
                    ) : (
                      <div className="pb-3 text-white text-center  pt-5 ">
                          {i18next.t('child_notHave')}  <br />
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button className=" white-outline-btn1" onClick={handleClose}>
            {i18next.t("close")}
            </Button>
            <div
              id="finish_mission1"
              className="w-100 mt-3 mb-2 m-auto d-none p-3"></div>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
}
