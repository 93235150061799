import * as React from "react";
import { Component } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import en from "../resources/en.json";
import { useDispatch, useSelector } from "react-redux";
import i18next from "../i18n/config";
import {useCookies} from "react-cookie";

export default function TermsInfoDialog(props) {
  const id = useSelector((state) => state.appReducer.id);
  const [cookies, setCookie] = useCookies(["lang"]);
  let [show, setShow] = useState(props.show);

  useEffect(() => {
    if (props.show == true) {
      setShow(props.show);
    }
  }, [props.show]);

  const handleClose = () => setShow(false);

  const location = useLocation();
  const content = props.Modalcontent;

  return (
    <>
      <div class="modelround" className="modal">
        <Modal
          show={show}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}>
          <Modal.Header class="modelstyle" closeButton>
            <Modal.Title class="modelstyle">
              {en.RewardDialog.veryality}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body  className={
            cookies.lang === "ar"
                ? "modelstyle text-end p-3 rtl"
                : "modelstyle text-start p-3 ltr" } >
            <h4 className="text-center"> {i18next.t("TermsHead")}</h4>

            <p>
              {i18next.t('TermsLable1')}
            </p>
            <p>
              {i18next.t('TermsLable2')}
            </p>
            <p>
              {i18next.t('TermsLable3')}
            </p>
            <p>
              {i18next.t('TermsLable4')}
            </p>
            <p>
              {i18next.t('TermsLable5')}
            </p>
            <p>
              {i18next.t('TermsLable6')}
            </p>
            <p>
              {i18next.t('TermsLable7')}
            </p>
            <p>
              {i18next.t('TermsLable8')}
            </p>
            <p>
              {i18next.t('TermsLable9')}
            </p>
            <p>
              {i18next.t('TermsLable10')}
            </p>
            <p>
              {i18next.t('TermsLable11')}
            </p>
            <p className="text-danger">
              {i18next.t('TermsLable12')}
            </p>


            {/* <p>
              <svg
                className="m-1"
                width="16"
                height="24"
                viewBox="0 0 16 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M10.31 2L10.72 4.48C9.87 4.17 8.96 4 8 4C7.05 4 6.13 4.17 5.29 4.47L5.7 2H10.31ZM10.72 19.52L10.31 22H5.7L5.29 19.53C6.13 19.83 7.05 20 8 20C8.96 20 9.87 19.83 10.72 19.52ZM12 0H4L3.05 5.73C1.19 7.19 0 9.45 0 12C0 14.55 1.19 16.81 3.05 18.27L4 24H12L12.96 18.27C14.81 16.81 16 14.54 16 12C16 9.46 14.81 7.19 12.96 5.73L12 0ZM8 18C4.69 18 2 15.31 2 12C2 8.69 4.69 6 8 6C11.31 6 14 8.69 14 12C14 15.31 11.31 18 8 18Z"
                  fill="#100E23"
                />
              </svg>
              Select device is to link your wearable with Veryality app by
              logging to your fitbit account and allow all permissions.
            </p> */}
          </Modal.Body>
          <Modal.Footer class="modelstyle">
            <div class="d-flex justify-content-end mt-4 button_right">
              <Button class="blue-btn" variant="primary" onClick={handleClose}>
                {i18next.t('close')}
              </Button>
            </div>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
}
