import * as React from "react";
import { NavLink } from "react-router-dom";
import { useNavigate, useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import Head from "../layout/Head";
import Footer from "../layout/Footer";
import Kidreward from "../layout/Kidreward";
import en from "../resources/en.json";
import ManagerAssign from "./ManagerAssign";
import { useDispatch, useSelector } from "react-redux";
import Game from "../layout/Game";
import Game_new2 from "../layout/Game_new2";
import Game_new from "../layout/Game_new";
import config from "../config.json";
import { useCookies } from "react-cookie";
import { useTranslation } from "react-i18next";
import { withTranslation } from "react-i18next";
import i18next from "../i18n/config";

export default function EsportDashboard() {
  let BaseUrl = config.BaseUrl;
  const navigate = useNavigate();
  const location = useLocation();
  const username = useSelector((state) => state.appReducer.username);
  const token = useSelector((state) => state.appReducer.token);
  const id = useSelector((state) => state.appReducer.id);
  const [cookies, setCookie] = useCookies(["lang"]);
  let [tournaments, setTournaments] = useState([]);
  // const id=location.state.id;
  const [show, setshow] = useState(false);
  const [width, setwidth] = useState(window.innerWidth);
  const { i18n } = useTranslation();

  useEffect(() => {
    if (cookies && cookies.lang) {
      i18n.changeLanguage(cookies.lang);
    } else {
      i18n.changeLanguage("en");
    }
  }, []);

  useEffect(() => {
    function handleResize() {
      setwidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (token === "") {
      navigate("/veryalityApp");
    }
    fetch(`${BaseUrl}tournaments/${id}`, {
      method: "get",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
        "Accept-Language": cookies.lang,
      },
    }).then(function (response11) {
      response11.json().then(function (Resp) {
        setTournaments(Resp.data);
      });
    });
  }, cookies.lang);

  return (
    <div className={cookies.lang === "ar" ? "rtl" : "ltr"}>
      <Head />
      <ManagerAssign />
      {/* <section
        className={
          cookies.lang === "ar"
            ? "container-fluid pdash bg_black pt-4 m-0 rtl"
            : "container-fluid pdash bg_black pt-4 m-0 ltr"
        }
        style={{ paddingLeft: 30, paddingRight: 35 }}>
        <div className="container-fluid bg-grey p-4">
          <div id="games" class="container-fluid p-0 ">
            <div className="row m-auto  pre-brows ">
              {tournaments.length != 0 ? (
                tournaments.map((item, index) => (
                  <Game
                    name={item.name}
                    active="1"
                    isManager={true}
                    img={
                      item.logo !== null && item.logo != ""
                        ? item.logo
                        : "../assets/imgs/game.png"
                    }
                    Tournament_id={item.id}
                    number_of_team_allowed={item.number_of_team_allowed}
                    min_team_level={item.min_team_level}
                    min_team_members_level={item.min_team_members_level}
                  />
                ))
              ) : (
                <div className="  text-white text-center container  ">
                  {en.ParentMissionsComponent.NoItem}
                </div>
              )}
            </div>
            {tournaments.length != 0 ? (
              <div className="brows mt-0">
                <a className="btn yellow-outline-btn font-13 w-100">
                  <span className="p-1">
                    {i18next.t("browse_all_veryality_games")}
                  </span>
                  <span>
                    <svg
                      width="12"
                      height="11"
                      viewBox="0 0 12 11"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M7.33268 8.83337H0.666016V10.1667H7.33268V8.83337ZM11.3327 3.50004H0.666016V4.83337H11.3327V3.50004ZM0.666016 7.50004H11.3327V6.16671H0.666016V7.50004ZM0.666016 0.833374V2.16671H11.3327V0.833374H0.666016Z"
                        fill="#EECE52"
                      />
                    </svg>
                  </span>
                </a>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </section> */}

      <section
        className={
          cookies.lang === "ar"
            ? "container-fluid pdash bg_black pt-3 m-0 rtl"
            : "container-fluid pdash bg_black pt-3 m-0 ltr"
        }
        style={{ paddingLeft: 25, paddingRight: 25 }}
      >
        <div className="container-fluid bg-grey p-4">
          <div id="games" class="container-fluid p-0 ">
            <div className="row m-auto  pre-brows ">
              {tournaments && tournaments.length > 0 ? (
                tournaments.map((item, index) => (
                  <div className=" col-lg-3 col-md-4 col-sm-12 p-2">
                    <Game_new2
                      item={item}
                      active="1"
                      isManager={true}
                      img={
                        item.logo !== null && item.logo != ""
                          ? item.logo
                          : "../assets/imgs/game.png"
                      }
                      // Tournament_id={item.id}
                      // number_of_team_allowed={item.number_of_team_allowed}
                      // min_team_level={item.min_team_level}
                      // min_team_members_level={item.min_team_members_level}
                    />
                  </div>
                ))
              ) : (
                <div className="  text-white text-center container  ">
                  {i18next.t("NoItem")}
                </div>
              )}
            </div>
            {/* {tournaments.length!=0?
                            <div className='brows mt-0'>
                                    <a className='btn yellow-outline-btn font-13 w-100'>
                                        <span className='p-1'>
                                        {i18next.t('browse_all_veryality_games')}
                                        </span>
                                        <span>
                                        <svg width="12" height="11" viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M7.33268 8.83337H0.666016V10.1667H7.33268V8.83337ZM11.3327 3.50004H0.666016V4.83337H11.3327V3.50004ZM0.666016 7.50004H11.3327V6.16671H0.666016V7.50004ZM0.666016 0.833374V2.16671H11.3327V0.833374H0.666016Z" fill="#EECE52"/>
                                        </svg>
                                        </span>
                                    </a>
                            </div>
                            :''} */}
          </div>
        </div>
      </section>
      <Footer bg="bg_black" />
    </div>
  );
}
