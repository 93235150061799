import * as React from 'react';
import { NavLink} from 'react-router-dom';
import {useNavigate} from 'react-router-dom';
import { useState, useEffect } from "react";
import en from "../resources/en.json";
import { Modal,Button,OverlayTrigger,Tooltip  } from 'react-bootstrap';
import DeleteRewardDialog from "../layout/DeleteRewardDialog";
import DeleteMissionDialog from "./DeleteMissionDialog";
import MissionManagmentDialog from "./MissionManagmentDialog";
import { useDispatch, useSelector } from "react-redux";
import { useCookies } from 'react-cookie';
import i18next from '../i18n/config';
import Avatar from '@mui/material/Avatar';
import Chip from "@mui/material/Chip";

export default function MissionCard(props)  {
    var hostName =window.location.origin;
    var BaseUrl="https://phplaravel-592682-2484169.cloudwaysapps.com/api/";
    const img_name='../assets/imgs/'+props.imageName+'.png';
    let [deletemissionpop, setdeletemissionpop] = useState(false);
    let [EditMissionFlag, setEditMissionFlag] = useState(false);
    const id = useSelector((state) => state.appReducer.id);

    useEffect(() => {
        if (deletemissionpop == true) {
          setTimeout(() => {
            setdeletemissionpop(false);
          }, 1000);
        }
      }, [deletemissionpop]);
useEffect(() => {
        if (EditMissionFlag == true) {
          setTimeout(() => {
            setEditMissionFlag(false);
          }, 1000);
        }
      }, [EditMissionFlag]);

    const handleChange = React.useCallback((newValue) => {
        props.onChange('david');
    });
return (
    <div className='col-md-2 reward m-md-4 mb-2  kidreward'>

        <DeleteMissionDialog
            show={deletemissionpop}

            missionId={props.item.id}
            onChange={handleChange}
        />
        <MissionManagmentDialog
            show={EditMissionFlag}
            onChange={handleChange}
            item={props.item}
            FromEdit={true}
        />
    <div class="card pb-4 ">
       <div class="card-header"  style={{height:"170px"}}>
           <div  >
           {props.item.icon != null && props.item.icon!= '' ? (
           <img src={props.item.icon}   className='icon_miss'/>
           ):(
               <img src='../assets/imgs/MicrosoftTeams-image.png'  width='30px' height='30px' className='icon_miss' alt="icon"/>
                )}
           </div>
           {props.item.isSystemMission ==0 ? (
               <div>

                   {props.item.canDelete == true && (
                       <a className='float-end' onClick={()=> setdeletemissionpop(true)}>
                           <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                               <g clip-path="url(#clip0_1_316)">
                                   <path d="M14.12 10.47L12 12.59L9.87 10.47L8.46 11.88L10.59 14L8.47 16.12L9.88 17.53L12 15.41L14.12 17.53L15.53 16.12L13.41 14L15.53 11.88L14.12 10.47ZM15.5 4L14.5 3H9.5L8.5 4H5V6H19V4H15.5ZM6 19C6 20.1 6.9 21 8 21H16C17.1 21 18 20.1 18 19V7H6V19ZM8 9H16V19H8V9Z" fill="#BF3323"/>
                               </g>
                               <defs>
                                   <clipPath id="clip0_1_316">
                                       <rect width="24" height="24" fill="white"/>
                                   </clipPath>
                               </defs>
                           </svg>
                       </a>
                   )}
                   {props.item.canEdit == true && (
                       <a className='float-end' onClick={()=> setEditMissionFlag(true)}>
                           <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                               <path d="M11.06 6.02L11.98 6.94L2.92 16H2V15.08L11.06 6.02ZM14.66 0C14.41 0 14.15 0.1 13.96 0.29L12.13 2.12L15.88 5.87L17.71 4.04C18.1 3.65 18.1 3.02 17.71 2.63L15.37 0.29C15.17 0.09 14.92 0 14.66 0ZM11.06 3.19L0 14.25V18H3.75L14.81 6.94L11.06 3.19Z" fill="#2A49B6"/>
                           </svg>

                       </a>
                   )}
               </div>
           ):(

               <img width={24} height={24} className='float-end'
                   src="../assets/imgs/loge.png"


               />
           )}

       </div>
       <div class="card-body pb-4">

       <OverlayTrigger
                            key='top'
                            placement='top'
                            overlay={
                                <Tooltip >
                                    {props.item.AssignedTo}
                                </Tooltip>
                            }
                        >
                            <span style={{fontSize:"14px"}}>{props.item.AssignedTo && props.item.AssignedTo.length <50 ?props.item.AssignedTo:props.item.AssignedTo.substring(0,10)+'...'} </span>
                        </OverlayTrigger>
           <br/>
           <OverlayTrigger
               key='top'
               placement='top'
               overlay={
                   <Tooltip >
                       {props.item.MissionName}
                   </Tooltip>
               }
           >
               <span style={{fontSize:"14px"}}>{props.item.MissionName && props.item.MissionName.length <50 ?props.item.MissionName:props.item.MissionName.substring(0,50)+'...'} </span>
           </OverlayTrigger>
           <br/>
           <OverlayTrigger
               key='top'
               placement='top'
               overlay={
                   <Tooltip >
                       {props.item.StartDate}
                   </Tooltip>
               }
           >
               <span style={{fontSize:"14px"}}>{props.item.StartDate && props.item.StartDate.length <50 ?props.item.StartDate:props.item.StartDate.substring(0,10)+'...'} </span>
           </OverlayTrigger>
           <br/>
           <OverlayTrigger
               key='top'
               placement='top'
               overlay={
                   <Tooltip >
                       {props.item.EndDate}
                   </Tooltip>
               }
           >
               <span style={{fontSize:"14px"}}>{props.item.EndDate && props.item.EndDate.length <50 ?props.item.EndDate:props.item.EndDate.substring(0,10)+'...'} </span>
           </OverlayTrigger>
<br/>
           <Chip style={{width:"100%"}} label={props.item.Status} color="primary" />

       </div>

    </div>
  </div>

  );
}
