import * as React from "react";
import { Component } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useState, useEffect,useRef } from "react";
import { Modal, Button } from "react-bootstrap";
import en from "../resources/en.json";
import config from "../config.json";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import IconButton  from "@mui/material/IconButton";

import Chip from "@mui/material/Chip";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import TextField from "@mui/material/TextField";
import { useDispatch, useSelector } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";
import { useCookies } from "react-cookie";
import i18next from "../i18n/config";
function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
    sx: {
      "&& .Mui-selected": {
        backgroundColor: "pink"
      }
    }
  },
};

export default function MissionDialog(props) {

  const [open, setOpen] = useState(false);
  const selectRef = useRef(null);
  const handleOpenSelect = () => {
    setOpen(!open);
  };

  const handleCloseSelect = () => {
    setOpen(false);
  };
  const handleDelete = (value) => {

    const filteredIds = personName.filter(number => number !== value);
    setPersonName(filteredIds);

  };


  // const now = new Date()
  // const startOfWeek = new Date(now.getFullYear(), now.getMonth(), now.getDate() - now.getDay() );
  // const endOfWeek = new Date(now.getFullYear(), now.getMonth(), startOfWeek.getDate() + 6);

  const today1 = new Date();
  const currentDayOfWeek = today1.getDay(); // 0 is Sunday, 1 is Monday, ..., 6 is Saturday

  // Calculate the difference between today and the start of the week (Sunday)
  const diff = today1.getDate() - currentDayOfWeek;

  // Set the date to the start of the week (Sunday)
  const startOfWeek = new Date(today1.setDate(diff));

  // Set the date to the end of the week (Saturday)
  const endOfWeek = new Date(startOfWeek);
  endOfWeek.setDate(startOfWeek.getDate() + 6);
  //////////////
  const [personName, setPersonName] = React.useState([]);
  const [SelectedItem, setSelectedItem] = React.useState(-1);
  const steps = [i18next.t("Mission_details"), i18next.t("assign_")];
  const dispatch = useDispatch();
  const token = useSelector((state) => state.appReducer.token);
  const id = useSelector((state) => state.appReducer.id);

  const [miss, setmiss] = useState({
    mission_id: -1,
    mission_StartDate: "",
    mission_EndDate: "",
    ChildIDs: [],
  });

  // wizard stepper form
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());
  const [isSystemMission, setisSystemMission] = React.useState(false);
  const [cookies, setCookie] = useCookies(["lang"]);
  const isStepOptional = (step) => {};
  const isStepSkipped = (step) => {
    return skipped.has(step);
  };
  var valid = document.getElementsByClassName("mission_valid1");

  const handleNext = () => {
    var mission_id = miss["mission_id"];
    var mission_StartDate = miss["mission_StartDate"];
    var mission_EndDate = miss["mission_EndDate"];
    var ChildIDs = miss["ChildIDs"];

    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }
    if (activeStep == 0) {
      if (
          isSystemMission== false&&(
        mission_StartDate === "" ||
        mission_EndDate === "" ||
        mission_id < 0)
      ) {
        // if (mission_id== -1)
        if (mission_id < 0)
          document.getElementById("missId_valid").classList.remove("d-none");
        if (mission_StartDate === "") valid[0].classList.remove("d-none");
        if (mission_EndDate === "") valid[1].classList.remove("d-none");
      } else {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
    } else if (activeStep == 1) {
      if (ChildIDs.length == 0) {
        valid[0].classList.remove("d-none");
      } else {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        handlesubmit();
      }
    }
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = () => {
    document.getElementById("create_mission").classList.add("d-none");
    setActiveStep(0);
    setmiss({
      mission_id: -1,
      mission_StartDate: "",
      mission_EndDate: "",
      ChildIDs: [],
    });
    // setchilds([]);

    fetch(`${BaseUrl}ParentChildsGetWithForCreate/${id}`, {
      method: "get",
      body: JSON.stringify(),
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        "Accept-Language": cookies.lang,
      },
    }).then(function (response) {
      response.json().then(function (childsresp) {
        if (childsresp.status == true) {
          setchilds(childsresp.data);
        }
      });
    });
    setPersonName([]);
    setSelectedItem("-1");
  };

  const theme = useTheme();

  const handleChange = (event) => {

    const {
      target: { value },
    } = event;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === "number" ? value.split(",") : value
    );
    handleCloseSelect()
  };

  // end wizard

  let BaseUrl = config.BaseUrl;
  let fullToday = new Date();
  let dd = String(fullToday.getDate()).padStart(2, "0");
  let mm = String(fullToday.getMonth() + 1).padStart(2, "0"); //January is 0!
  let yyyy = fullToday.getFullYear();
  let today = yyyy + "-" + mm + "-" + dd;
  let endDate = yyyy + 2 + "-" + mm + "-" + dd;
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  let [show, setShow] = useState(props.show);
  // const id = props.id;
  const [missions, setMissions] = useState([]);
  const [childs, setchilds] = useState([]);
  useEffect(() => {
    setmiss({ ...miss, ChildIDs: personName });
  }, [miss.mission_StartDate]);

  useEffect(() => {
    setmiss({ ...miss, ChildIDs: personName });
  }, [miss.mission_EndDate]);

  useEffect(() => {

    setmiss({ ...miss, ChildIDs: personName });
  }, [personName]);
  useEffect(() => {
    const handleClickOutside = (event) => {

      if (selectRef.current && !selectRef.current.contains(event.target)) {
        handleCloseSelect();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  useEffect(() => {

    if (props.show == true) {
      fetch(`${BaseUrl}ParentChildsGetWithForCreate/${id}`, {
        method: "get",
        body: JSON.stringify(),
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          "Accept-Language": cookies.lang,
        },
      }).then(function (response) {
        response.json().then(function (childsresp) {
          if (childsresp.status == true) {
            setchilds(childsresp.data);
          }
        });
      });

      fetch(`${BaseUrl}GetParentMissions/${id}`, {
        method: "get",
        body: JSON.stringify(),
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          "Accept-Language": cookies.lang,
        },
      }).then(function (response) {
        response.json().then(function (rewardsresp) {
          if (rewardsresp.status == true) {
            setMissions(rewardsresp.data);
          }
        });
      });
      setShow(props.show);
    }
  }, [props.show]);

  const handleClose = (event) => {
    handleReset();
    setShow(false);
    props.onChange("david");
  };
  function handleKeyPress(e) {
    e.preventDefault();
    e.stopPropagation();
    return false;
  }

  const handlesubmit = () => {
    setLoading(true);
    fetch(`${BaseUrl}CreateAssignment/${id}`, {
      method: "post",
      body: JSON.stringify(miss),
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        "Accept-Language": cookies.lang,
      },
    }).then(function (response) {
      response.json().then(function (resp_createmission) {
        setLoading(false);
        setmiss({
          mission_id: -1,
          mission_StartDate: "",
          mission_EndDate: "",
          ChildIDs: [],
        });
        if (resp_createmission.status == true) {
          document.getElementById("create_mission").innerHTML =resp_createmission.msg.replace('\n','<br/>');

          document.getElementById("create_mission").classList.remove("d-none");
          document.getElementById("create_mission").classList.add("alert-info");
        } else if (resp_createmission.status == false) {
          document.getElementById("create_mission").innerHTML =
            i18next.t("failed");
          document.getElementById("create_mission").classList.remove("d-none");
          document
            .getElementById("create_mission")
            .classList.add("alert-danger");
        }
      });
    });
  };

  function onSelectMission(e) {
    setSelectedItem(e.value);
    setisSystemMission(false);
    e = e.value;
    setmiss({ ...miss, mission_id: e });
    var selectedMission=missions.find(mission => mission.id == e);

    if (selectedMission && selectedMission.isSystemMission==1)
    {
      setLoading(true);
      setisSystemMission(true);
      fetch(`${BaseUrl}ParentChildsGetWithForCreate/${id}/true`, {
        method: "get",
        body: JSON.stringify(),
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          "Accept-Language": cookies.lang,
        },
      }).then(function (response) {
        response.json().then(function (childsresp) {
          if (childsresp.status == true) {
            setchilds(childsresp.data);
            setLoading(false);
          }
        });
      });
    }
    else
    {
      setLoading(true);
      setisSystemMission(false);
      fetch(`${BaseUrl}ParentChildsGetWithForCreate/${id}`, {
        method: "get",
        body: JSON.stringify(),
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          "Accept-Language": cookies.lang,
        },
      }).then(function (response) {
        response.json().then(function (childsresp) {
          if (childsresp.status == true) {
            setchilds(childsresp.data);
            setLoading(false);
          }
        });
      });
    }

    let element = document.getElementById("missionSelect");
    if (
      document.getElementById("missId_valid").classList.contains("d-none") ==
      false
    )
      document.getElementById("missId_valid").classList.add("d-none");
  }

  return (
    <>
      <div className="modal ">
        <Modal
          show={show}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>{en.RewardDialog.veryality} </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className={cookies.lang === "ar" ? "rtl" : "ltr"}>
              <div className="small p-3">
                <h5>{i18next.t("newAssignment")} </h5>
              </div>
              <Box sx={{ width: "100%" }}>
                <Stepper activeStep={activeStep}>
                  {steps.map((label, index) => {
                    const stepProps = {};
                    const labelProps = {};
                    if (isStepOptional(index)) {
                      labelProps.optional = (
                        <Typography variant="caption">
                          {i18next.t("optional")}
                        </Typography>
                      );
                    }
                    if (isStepSkipped(index)) {
                      stepProps.completed = false;
                    }
                    return (
                      <Step key={label} {...stepProps}>
                        <StepLabel {...labelProps}>{label}</StepLabel>
                      </Step>
                    );
                  })}
                </Stepper>
                <form className="   form-white" id="">
                  {loading == true ? (
                    <div className="w-100 mt-3 mb-2 m-auto">
                      <CircularProgress />
                    </div>
                  ) : (
                    <div
                      id="create_mission"
                      className={
                        cookies.lang === "ar"
                          ? " text-end w-100 mt-4 mb-2 m-auto d-none p-3"
                          : " text-start w-100 mt-4 mb-2 m-auto d-none p-3"
                      }
                    ></div>
                  )}
                  {activeStep === steps.length ? (
                    <React.Fragment>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          flexDirection: "row",
                          pt: 2,
                        }}
                      >
                        <Button
                          sx={{ marginRight: "20px" }}
                          onClick={handleReset}
                        >
                          {i18next.t("assignMission")}
                        </Button>

                        <Button
                          sx={{ marginRight: "20px" }}
                          onClick={handleClose}
                        >
                          {i18next.t("close")}
                        </Button>
                      </Box>
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      {activeStep === 0 ? (
                        <>
                          <br></br>
                          <div class="mb-3  " id="custom_mission">
                            <select
                              value={SelectedItem}
                              name=""
                              className="form-select "
                              id="missionSelect"
                              onChange={(e) => onSelectMission(e.target)}
                              required
                            >
                              <option value={-1}>
                                {" "}
                                {i18next.t("select")}{" "}
                              </option>
                              {missions.length != 0
                                ? missions.map((item) => (

                                      item.isSystemMission ==false?(

                                          <option value={item.id}>{item.name}</option>
                                      ):""



                                  ))
                                : ""}
                            </select>
                            <div
                              className={
                                cookies.lang === "ar"
                                  ? "text-danger text-end p-1 small d-none"
                                  : "text-danger text-start p-1 small d-none"
                              }
                              id="missId_valid"
                            >
                              {i18next.t("missId_validation")}{" "}
                            </div>
                          </div>
                          {isSystemMission == false?(
                              <>
                                <div className="mb-3 input-group">
                            <span className="input-group-text select-watch text-white w-50 ">
                              {i18next.t("missionStartdate")}
                            </span>
                                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <MobileDatePicker
                                        className="form-control point"
                                        inputFormat="MM/DD/YYYY"
                                        value={miss.mission_StartDate}
                                        minDate={today}
                                        maxDate={
                                          miss.mission_EndDate != "" && miss.mission_EndDate != null
                                              ? miss.mission_EndDate
                                              : endOfWeek
                                        }
                                        //   maxDate={endOfWeek}
                                        onChange={(e) => {
                                          setmiss({
                                            ...miss,
                                            mission_StartDate:
                                                e?.$y + "-" + (e?.$M + 1) + "-" + e?.$D,
                                          });
                                        }}
                                        required
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                placeholder="mm/dd/yyyy"
                                            />
                                        )}
                                    />
                                  </LocalizationProvider>
                                </div>
                                <div
                                    className={
                                      cookies.lang === "ar"
                                          ? "text-danger text-end p-1 small mission_valid1 d-none"
                                          : "text-danger text-start p-1 small mission_valid1 d-none"
                                    }
                                >
                                  {i18next.t("missionStartdate_valid")}
                                </div>
                                <div className="mb-3 input-group">
                            <span className="input-group-text select-watch text-white w-50 ">
                              {i18next.t("missionEnddate")}
                            </span>

                                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <MobileDatePicker
                                        className="form-control point"
                                        inputFormat="MM/DD/YYYY"
                                        value={miss.mission_EndDate}
                                        minDate={
                                          miss.mission_StartDate != "" && miss.mission_StartDate != null
                                              ? miss.mission_StartDate
                                              : today
                                        }
                                        // maxDate={endDate}
                                        // minDate={today}

                                        maxDate={endOfWeek}
                                        onChange={(e) => {
                                          setmiss({
                                            ...miss,
                                            mission_EndDate:
                                                e?.$y + "-" + (e?.$M + 1) + "-" + e?.$D,
                                          });
                                        }}
                                        required
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                placeholder="mm/dd/yyyy"
                                            />
                                        )}
                                    />
                                  </LocalizationProvider>
                                </div>
                                <div
                                    className={
                                      cookies.lang === "ar"
                                          ? "text-danger text-end p-1 small mission_valid1 d-none"
                                          : "text-danger text-start p-1 small mission_valid1 d-none"
                                    }
                                >
                                  {i18next.t("missionEnddate_valid")}
                                </div>
                              </>

                          ):""}

                        </>
                      ) : (
                        <>
                          <br></br>

                          <div
                            className={
                              cookies.lang === "ar"
                                ? " text-end "
                                : "text-start"
                            }
                          >
                            <small className="text-danger ">
                              {i18next.t("childHint")}
                            </small>
                          </div>
                          <FormControl sx={{ m: 1, width: 300 }}>
                            <InputLabel
                              sx={{ color: "white", outlineWidth: "0" }}
                            >
                              {" "}
                            </InputLabel>
                            <label
                              className={
                                cookies.lang === "ar"
                                  ? "text-end"
                                  : "text-start"
                              }
                            >
                              {i18next.t("select_child")}:
                            </label>
                            <Select ref={selectRef}
                              open={open}
                              className="no_outline"
                              sx={{ outlineWidth: "0" }}
                              id="demo-multiple-chip"
                              multiple
                              value={personName}
                              onChange={handleChange}

                              input={
                                <OutlinedInput
                                  id="select-multiple-chip"
                                  className="multi-select"
                                  sx={{ outlineWidth: "0" }}
                                />
                              }
                              renderValue={(selected) => (
                                <Box
                                  sx={{
                                    display: "flex",
                                    flexWrap: "wrap",
                                    gap: 0.5,
                                  }}
                                >
                                  {selected.map((value) => (
                                    <Chip
                                      sx={{
                                        color: "white",
                                        backgroundColor: "#353155 ",
                                      }}
                                      variant="outlined"
                                      onDelete={()=>handleDelete(value)}
                                      key={value}
                                      label={childs.map((child) =>
                                        child.id == value ? child.name : ""
                                      )}
                                    />
                                  ))}
                                </Box>
                              )}
                              MenuProps={MenuProps}
                            >
                              {childs.length > 0 ? (
                                childs.map((child) => (
                                  <MenuItem
                                    key={child.id}
                                    value={child.id}
                                    label={child.name}
                                    style={getStyles(
                                      child.id,
                                      personName,
                                      theme
                                    )}
                                  >
                                    {child.name}
                                  </MenuItem>
                                ))
                              ) : (
                                <MenuItem>
                                  <em>  {i18next.t("no_child")}</em>
                                </MenuItem>
                              )}
                            </Select>
                            <IconButton onClick={handleOpenSelect} style={{ position: "absolute", right: 0, top: "50%", transform: "translateY(-50%)",color:"#353155 " }}>
                              <div style={{height:"50px",width:"30px"}}></div>
                            </IconButton>
                            <div
                              className={
                                cookies.lang === "ar"
                                  ? "text-danger text-end p-1 small mission_valid1 d-none"
                                  : "text-danger text-start p-1 small mission_valid1 d-none"
                              }
                            >
                              {i18next.t("child_valid")}
                            </div>
                          </FormControl>
                        </>
                      )}

                      <Box
                        sx={{ display: "flex", flexDirection: "row", pt: 2 }}
                      >
                        <Button
                          color="inherit"
                          disabled={activeStep === 0}
                          onClick={handleBack}
                          sx={{ mr: 1 }}
                        >
                          {i18next.t("back")}
                        </Button>
                        <Box sx={{ flex: "1 1 auto" }} />
                        {isStepOptional(activeStep) && (
                          <Button
                            color="inherit"
                            onClick={handleSkip}
                            sx={{ mr: 1 }}
                          >
                            {i18next.t("skip")}
                          </Button>
                        )}

                        <Button onClick={handleNext}>
                          {activeStep === steps.length - 1
                            ? i18next.t("finish")
                            : i18next.t("next")}
                        </Button>
                      </Box>
                    </React.Fragment>
                  )}
                </form>
              </Box>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
}
