import * as React from "react";
import { Component } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import en from "../resources/en.json";
import { useDispatch, useSelector } from "react-redux";
import { useCookies } from "react-cookie";
import i18next from "../i18n/config";

export default function InfoDialog(props) {
  const id = useSelector((state) => state.appReducer.id);
  const [cookies, setCookie] = useCookies(["lang"]);

  let [show, setShow] = useState(props.show);

  useEffect(() => {
    if (props.show == true) {
      setShow(props.show);
    }
  }, [props.show]);

  const handleClose = () => setShow(false);

  const location = useLocation();
  const content = props.Modalcontent;

  return (
    <>
      <div class="modelround" className="modal">
        <Modal
          show={show}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header class="modelstyle" closeButton>
            <Modal.Title class="modelstyle">
              {en.RewardDialog.veryality}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body
            className={
              cookies.lang === "ar"
                ? "modelstyle text-end p-3 rtl"
                : "modelstyle text-start p-3"
            }
          >
            <h4 className="text-center">{i18next.t("guide")}</h4>
            <p className="text-danger">{i18next.t("guide_please")}</p>
            <p>{i18next.t("guide_first")}</p>
            <p>
              <svg
                className="m-1"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_1_70)">
                  <path
                    d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM7.07 18.28C7.5 17.38 10.12 16.5 12 16.5C13.88 16.5 16.51 17.38 16.93 18.28C15.57 19.36 13.86 20 12 20C10.14 20 8.43 19.36 7.07 18.28ZM18.36 16.83C16.93 15.09 13.46 14.5 12 14.5C10.54 14.5 7.07 15.09 5.64 16.83C4.62 15.49 4 13.82 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 13.82 19.38 15.49 18.36 16.83ZM12 6C10.06 6 8.5 7.56 8.5 9.5C8.5 11.44 10.06 13 12 13C13.94 13 15.5 11.44 15.5 9.5C15.5 7.56 13.94 6 12 6ZM12 11C11.17 11 10.5 10.33 10.5 9.5C10.5 8.67 11.17 8 12 8C12.83 8 13.5 8.67 13.5 9.5C13.5 10.33 12.83 11 12 11Z"
                    fill="#100E23"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_1_70">
                    <rect width="24" height="24" fill="white" />
                  </clipPath>
                </defs>
              </svg>
              {i18next.t("guide_user")}
            </p>
            <p>
              <svg
                className="m-1"
                width="20"
                height="16"
                viewBox="0 0 20 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M20 2C20 0.9 19.1 0 18 0H2C0.9 0 0 0.9 0 2V14C0 15.1 0.9 16 2 16H18C19.1 16 20 15.1 20 14V2ZM18 2L10 6.99L2 2H18ZM18 14H2V4L10 9L18 4V14Z"
                  fill="#100E23"
                />
              </svg>
              {i18next.t("guide_email")}
            </p>
            <p>
              <svg
                className="m-1"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_1_3144)">
                  <path
                    d="M4.5 11C5.32843 11 6 10.3284 6 9.5C6 8.67157 5.32843 8 4.5 8C3.67157 8 3 8.67157 3 9.5C3 10.3284 3.67157 11 4.5 11Z"
                    fill="#100E23"
                  />
                  <path
                    d="M22.17 9.17C22.17 5.3 19.04 2.17 15.17 2.17C11.3 2.17 8.17 5.3 8.17 9.17C8.17 12.64 10.69 15.51 14 16.06V20H6V17H7V13C7 12.45 6.55 12 6 12H3C2.45 12 2 12.45 2 13V17H3V22H19V20H16V16.12C19.47 15.71 22.17 12.76 22.17 9.17V9.17ZM15.17 14.17C12.41 14.17 10.17 11.93 10.17 9.17C10.17 6.41 12.41 4.17 15.17 4.17C17.93 4.17 20.17 6.41 20.17 9.17C20.17 11.93 17.93 14.17 15.17 14.17Z"
                    fill="#100E23"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_1_3144">
                    <rect width="24" height="24" fill="white" />
                  </clipPath>
                </defs>
              </svg>
              {i18next.t("guide_gender")}
            </p>
            <p>
              <svg
                className="m-1"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_1_510)">
                  <path
                    d="M18 8H17V6C17 3.24 14.76 1 12 1C9.24 1 7 3.24 7 6V8H6C4.9 8 4 8.9 4 10V20C4 21.1 4.9 22 6 22H18C19.1 22 20 21.1 20 20V10C20 8.9 19.1 8 18 8ZM9 6C9 4.34 10.34 3 12 3C13.66 3 15 4.34 15 6V8H9V6ZM18 20H6V10H18V20ZM12 17C13.1 17 14 16.1 14 15C14 13.9 13.1 13 12 13C10.9 13 10 13.9 10 15C10 16.1 10.9 17 12 17Z"
                    fill="#100E23"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_1_510">
                    <rect width="24" height="24" fill="white" />
                  </clipPath>
                </defs>
              </svg>
              {i18next.t("guide_password")}
            </p>
            <p>
              <svg
                className="m-1"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_1_510)">
                  <path
                    d="M18 8H17V6C17 3.24 14.76 1 12 1C9.24 1 7 3.24 7 6V8H6C4.9 8 4 8.9 4 10V20C4 21.1 4.9 22 6 22H18C19.1 22 20 21.1 20 20V10C20 8.9 19.1 8 18 8ZM9 6C9 4.34 10.34 3 12 3C13.66 3 15 4.34 15 6V8H9V6ZM18 20H6V10H18V20ZM12 17C13.1 17 14 16.1 14 15C14 13.9 13.1 13 12 13C10.9 13 10 13.9 10 15C10 16.1 10.9 17 12 17Z"
                    fill="#100E23"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_1_510">
                    <rect width="24" height="24" fill="white" />
                  </clipPath>
                </defs>
              </svg>
              {i18next.t("guide_confirmpassword")}
            </p>
            {/* <p>
              <svg
                className="m-1"
                width="16"
                height="24"
                viewBox="0 0 16 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M10.31 2L10.72 4.48C9.87 4.17 8.96 4 8 4C7.05 4 6.13 4.17 5.29 4.47L5.7 2H10.31ZM10.72 19.52L10.31 22H5.7L5.29 19.53C6.13 19.83 7.05 20 8 20C8.96 20 9.87 19.83 10.72 19.52ZM12 0H4L3.05 5.73C1.19 7.19 0 9.45 0 12C0 14.55 1.19 16.81 3.05 18.27L4 24H12L12.96 18.27C14.81 16.81 16 14.54 16 12C16 9.46 14.81 7.19 12.96 5.73L12 0ZM8 18C4.69 18 2 15.31 2 12C2 8.69 4.69 6 8 6C11.31 6 14 8.69 14 12C14 15.31 11.31 18 8 18Z"
                  fill="#100E23"
                />
              </svg>
              Select device is to link your wearable with Veryality app by
              logging to your fitbit account and allow all permissions.
            </p> */}
          </Modal.Body>
          <Modal.Footer class="modelstyle">
            <div class="d-flex justify-content-end mt-4 button_right">
              <Button class="blue-btn" variant="primary" onClick={handleClose}>
                {i18next.t("close")}
              </Button>
            </div>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
}
