import * as React from "react";
import { NavLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useState, useEffect, useRef } from "react";
import Kidreward from "../../layout/Kidreward";
import MissionDialog from "../../layout/MissionDialog";
import FinishMissionDialog from "../../layout/FinishMissionDialog";
import config from "../../config.json";
import en from "../../resources/en.json";
import { Modal, Button, OverlayTrigger, Tooltip } from "react-bootstrap";
// import 'antd/dist/antd.css';
import { Space, Table, DatePicker, Tag, Input } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import moment from "moment";
import DeleteAssignmentDialog from "../../layout/DeleteAssignmentDialog";
import EditReAssignDialog from "../../layout/EditReAssignDialog";
import { useDispatch, useSelector } from "react-redux";
import { useCookies } from "react-cookie";
import i18next from "../../i18n/config";

export default function Missions(props) {
  const token = useSelector((state) => state.appReducer.token);
  const id = useSelector((state) => state.appReducer.id);
  const [cookies, setCookie] = useCookies(["lang"]);

  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [FieldValue, setFieldValue] = useState("");
  const searchInput = useRef(null);
  const [loading, setLoading] = useState(false);
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,

    },
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    setLoading(true);
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
    setLoading(false);
  };
  const handleReset = (clearFilters, confirm, dataIndex) => {
    setLoading(true);
    clearFilters();
    setFieldValue(null);
    setSearchText("");
    handleSearch([], confirm, dataIndex);
    setLoading(false);
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div
        style={{
          padding: 8,
        }}
      >
        {dataIndex != "EndDate" && dataIndex != "StartDate" ? (
          <Input
            ref={searchInput}
            placeholder={`Enter text to search`}
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{
              marginBottom: 8,
              display: "block",
            }}
          />
        ) : (
          <DatePicker
            format={"YYYY-MM-DD"}
            onChange={(date, dateString) => {
              setSelectedKeys([dateString]);
              setFieldValue(moment(dateString), "YYYY-MM-DD");
            }}
            value={FieldValue}
            inputReadOnly={true}
            allowClear={false}
            style={{
              marginBottom: 8,
              display: "block",
            }}
          />
        )}

        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            {i18next.t("search")}
          </Button>
          <Button
            onClick={() =>
              clearFilters && handleReset(clearFilters, confirm, dataIndex)
            }
            size="small"
            style={{
              width: 90,
            }}
          >
            {i18next.t("reset_btn")}
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : "#000",
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ff8400",
            padding: 0,
            color: "white",
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const handleTableChange = (pagination, filters, sorter) => {
    setTableParams({
      pagination,
      filters,
      ...sorter,
    });
  };

  const columns = [
    {
      title: i18next.t("MissionCaption"),
      dataIndex: "MissionName",
      key: "MissionName",
      //fixed:'left',
      //width:'10%',
      sorter: (a, b) => a.MissionName.length - b.MissionName.length,

      ...getColumnSearchProps("MissionName"),
      className: "MissionResportStyle",
      render: (text) => {
        return (
          <>
            <OverlayTrigger
              key="top"
              placement="top"
              overlay={<Tooltip>{text}</Tooltip>}
            >
              <div variant="secondary">
                {text && text.length < 25
                  ? text
                  : text.substring(0, 25) + "..."}{" "}
              </div>
            </OverlayTrigger>
          </>
        );
      },
    },

    {
      title: i18next.t("Assigned_to"),
      dataIndex: "AssignedTo",
      key: "AssignedTo",
      sorter: (a, b) => a.AssignedTo.length - b.AssignedTo.length,
      ...getColumnSearchProps("AssignedTo"),
      // responsive: ['lg','sm','md'],
      className: "MissionResportStyle",
      render: (text) => {
        return (
          <>
            <OverlayTrigger
              key="top"
              placement="top"
              overlay={<Tooltip>{text}</Tooltip>}
            >
              <div variant="secondary">
                {text && text.length < 25
                  ? text
                  : text.substring(0, 25) + "..."}{" "}
              </div>
            </OverlayTrigger>
          </>
        );
      },
    },
    {
      title: i18next.t("Status"),
      dataIndex: "Status",
      key: "Status",
      filterIcon: (filtered) => (
        <SearchOutlined
          style={{
            color: filtered ? "#1890ff" : "#000",
          }}
        />
      ),
      filters: [
        {
          text: i18next.t("New"),
          value: i18next.t("New"),
        },
        {
          text: i18next.t("InProgress"),
          value: i18next.t("InProgress"),
        },
        {
          text: i18next.t("Finished"),
          value: i18next.t("Finished"),
        },
        {
          text: i18next.t("Failed"),
          value:i18next.t("Failed"),
        },
      ],
      onFilter: (value, record) => record.Status.indexOf(value) === 0,
      // responsive: ['lg','md','sm'],
      className: "MissionResportStyle",
      render: (text) => {
        let color = "red";
        if (text === i18next.t("InProgress")) {
          color = "blue";
        }
        if (text === i18next.t("Finished")) {
          color = "green";
        }
        if (text === i18next.t("New")) {
          color = "gray";
        }

        return (
          <Tag color={color} key={text}>
            {text.toUpperCase()}
          </Tag>
        );
      },
    },
    {
      title: i18next.t("StartDate"),
      dataIndex: "StartDate",
      key: "StartDate",
      // responsive: ['lg','md'],
      className: "MissionResportStyle",
      sorter: (a, b) => a.EndDate.length - b.EndDate.length,
      ...getColumnSearchProps("StartDate"),
    },
    {
      title: i18next.t("EndDate"),
      dataIndex: "EndDate",
      key: "EndDate",
      // responsive: ['lg','md'],
      className: "MissionResportStyle",
      sorter: (a, b) => a.EndDate.length - b.EndDate.length,
      ...getColumnSearchProps("EndDate"),
    },

    {
      className: "MissionResportStyle",
      title: i18next.t("Actions"),
      key: en.missionreport.Actions,
      //   responsive: ['lg','sm','xs'],
      render: (_, record) => {
      if (record.isSystemMission ==1){return (<Space size="middle">
              <span className="" >

              </span>
      </Space>)}
        if (record.Status ==i18next.t("InProgress_custom_mission")) {
          return (
            <Space size="middle">
              <span className="" onClick={() => viewnotification(record)}>
                <img width={30} height={30} src="../assets/imgs/finish.png" />
              </span>
            </Space>
          );
        } else if (record.Status == i18next.t("New_custom_mission")) {
          return (
            <>
              <Space size="middle">
                <span
                  className=""
                  onClick={() => ViewDeleteAssignemtDialog(record)}
                >
                  <img width={25} height={25} src="../assets/imgs/delete.png" />
                </span>
              </Space>

              <Space size="middle">
                <span className="" onClick={() => ViwUpdateAssignement(record)}>
                  <svg
                    width="25"
                    height="25"
                    viewBox="0 0 19 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M11.06 6.02L11.98 6.94L2.92 16H2V15.08L11.06 6.02ZM14.66 0C14.41 0 14.15 0.1 13.96 0.29L12.13 2.12L15.88 5.87L17.71 4.04C18.1 3.65 18.1 3.02 17.71 2.63L15.37 0.29C15.17 0.09 14.92 0 14.66 0ZM11.06 3.19L0 14.25V18H3.75L14.81 6.94L11.06 3.19Z"
                      fill="#2A49B6"
                    />
                  </svg>
                </span>
              </Space>
            </>
          );
        } else {
          return (
            <Space size="middle">
              <span className="" onClick={() => ViwReAssign(record)}>
                <img width={30} height={30} src="../assets/imgs/reassign.png" />
              </span>
            </Space>
          );
        }
      },
    },
  ];

  /////////////////////////////
  let BaseUrl = config.BaseUrl;
  // const id=props.id;
  const username = props.username;
  const navigate = useNavigate();
  let [pop, setpop] = useState(false);
  let [popmissionnew, setpopmissionnew] = useState(false);
  // const [needrefresh, setneedrefresh] = useState(props.needrefresh);
  let [notifpop, setnotifpop] = useState(false);
  let [DeleteAssignemtShow, setDeleteAssignemtShow] = useState(false);
  let [child, setchild] = useState();
  let [notif, setnotif] = useState({
    missionID: "",
    childID: "",
    PrentID: "",
  });
  let [missionID, setmissionID] = useState("");
  let [userMissionID, setuserMissionID] = useState("");
  let [childID, setchildID] = useState("");
  let [PrentID, setPrentID] = useState("");
  let [childname, setchildname] = useState("");
  let [missionName, setmissionName] = useState("");
  let [ShowUpdateDialog, setShowUpdateDialog] = useState(false);
  let [AssignemtObj, setAssignemtObj] = useState([]);
  let [isReAssign, setisReAssign] = useState(false);

  useEffect(() => {
    if (ShowUpdateDialog == true) {
      setTimeout(() => {
        setShowUpdateDialog(false);
      }, 1000);
    }
  }, [ShowUpdateDialog]);
  useEffect(() => {
    if (DeleteAssignemtShow == true) {
      setTimeout(() => {
        setDeleteAssignemtShow(false);
      }, 1000);
    }
  }, [DeleteAssignemtShow]);
  useEffect(() => {
    if (notifpop == true) {
      setTimeout(() => {
        setnotifpop(false);
      }, 1000);
    }
  }, [notifpop]);
  useEffect(() => {
    if (pop == true) {
      setTimeout(() => {
        setpop(false);
      }, 1000);
    }
  }, [pop]);
  useEffect(() => {
    if (popmissionnew == true) {
      setTimeout(() => {
        setpopmissionnew(false);
      }, 1000);
    }
  }, [popmissionnew]);

  let [missionDetails, setmissionDetails] = useState([
    {
      MissionID: "",
      MissionName: "",
      RewardName: "",
      AssignedTo: "",
      AssignedToID: "",
      Status: "",
      EndDate: "",
    },
  ]);
  let [addedMissions, setaddedMissions] = useState(0);
  let [completedMissions, setcompletedMissions] = useState(0);
  useEffect(() => {
    fetch(`${BaseUrl}GetParentMissionsReport/${id}`, {
      method: "get",
      body: JSON.stringify(),
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        "Accept-Language": cookies.lang,
      },
    }).then(function (response) {
      response.json().then(function (getMissionreport_response) {
        setaddedMissions(getMissionreport_response.data.addedMissions);
        setcompletedMissions(getMissionreport_response.data.completedMissions);
        setmissionDetails(getMissionreport_response.data.missionDetails);
      });
    });
  }, []);

  const handleChange = React.useCallback((newValue) => {
    fetch(`${BaseUrl}GetParentMissionsReport/${id}`, {
      method: "get",
      body: JSON.stringify(),
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        "Accept-Language": cookies.lang,
      },
    }).then(function (response) {
      response.json().then(function (getMissionreport_response) {
        setaddedMissions(getMissionreport_response.data.addedMissions);
        setcompletedMissions(getMissionreport_response.data.completedMissions);
        setmissionDetails(getMissionreport_response.data.missionDetails);
      });
    });
    props.onChange("needrefreshEditReward");
  });

  useEffect(() => {
    if (props.needrefresh == true) {
      fetch(`${BaseUrl}GetParentMissionsReport/${id}`, {
        method: "get",
        body: JSON.stringify(),
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          "Accept-Language": cookies.lang,
        },
      }).then(function (response) {
        response.json().then(function (getMissionreport_response) {
          setaddedMissions(getMissionreport_response.data.addedMissions);
          setcompletedMissions(
            getMissionreport_response.data.completedMissions
          );
          setmissionDetails(getMissionreport_response.data.missionDetails);
        });
      });
    }
  }, [props.needrefresh]);

  function viewnotification(listValue) {
    setnotif(listValue.MissionID, listValue.AssignedToID, id);
    setmissionID(listValue.MissionID);
    // setnotif(item);
    setchild(listValue.AssignedToID);
    setuserMissionID(listValue.userMission);
    setchildID(listValue.AssignedToID);
    setPrentID(id);
    setchildname(listValue.AssignedTo);
    setmissionName(listValue.MissionName);
    setnotifpop(true);
  }

  function ViwUpdateAssignement(item) {
    setisReAssign(false);
    setAssignemtObj(item);
    setShowUpdateDialog(true);
  }
  function ViwReAssign(item) {
    setisReAssign(true);
    setAssignemtObj(item);
    setShowUpdateDialog(true);
  }
  function ViewDeleteAssignemtDialog(item) {
    setuserMissionID(item.userMission);
    setDeleteAssignemtShow(true);
  }
  return (
    <div>
      <MissionDialog show={pop} username={username} onChange={handleChange} />
      <MissionDialog
        show={popmissionnew}
        username={username}
        onChange={handleChange}
      />
      <FinishMissionDialog
        userMissionId={userMissionID}
        show={notifpop}
        missionName={missionName}
        childname={childname}
        item={notif}
        missionID={missionID}
        childID={childID}
        PrentID={PrentID}
        child={child}
        onChange={handleChange}
      />
      <DeleteAssignmentDialog
        show={DeleteAssignemtShow}
        userMissionId={userMissionID}
        onChange={handleChange}
      >
        {" "}
      </DeleteAssignmentDialog>
      <EditReAssignDialog
        show={ShowUpdateDialog}
        isReAssign={isReAssign}
        item={AssignemtObj}
        onChange={handleChange}
      >
        {" "}
      </EditReAssignDialog>
      <div className="desktop">
        <section className=" container-fluid p-4 bg_black ">
          <div className="container-fluid mt-3 mission p-4 kidreward">
            <div className="pb-3">
              <a>
                <span
                  className={
                    cookies.lang === "ar"
                      ? " float-end bold"
                      : " float-start bold"
                  }
                >
                  {" "}
                  {i18next.t("Missions")}
                </span>
              </a>
            </div>
            <br></br>
            <br></br>
            <div className="row"></div>
            <div className="row">
              <div className="w-auto text-start p-2 ">
                <p className="retit"> {i18next.t("Added_missions")}</p>
                <p className="added">{addedMissions}</p>
                <p className="retit mt-5">{i18next.t("Completed_missions")}</p>
                <p className="comp">{completedMissions}</p>
                <a
                  className={
                    cookies.lang === "ar"
                      ? " btn black-btn mt-5 w-auto rtl text-end "
                      : " btn black-btn mt-5 w-auto "
                  }
                >
                  <span>
                    <svg
                      width="34"
                      height="34"
                      viewBox="0 0 34 34"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M17.0007 0.333252C7.80065 0.333252 0.333984 7.79992 0.333984 16.9999C0.333984 26.1999 7.80065 33.6666 17.0007 33.6666C26.2007 33.6666 33.6673 26.1999 33.6673 16.9999C33.6673 7.79992 26.2007 0.333252 17.0007 0.333252ZM25.334 18.6666H18.6673V25.3333H15.334V18.6666H8.66732V15.3333H15.334V8.66659H18.6673V15.3333H25.334V18.6666Z"
                        fill="#EECE52"
                      />
                    </svg>
                  </span>
                  <span
                    class="addnew"
                    className="p-3"
                    onClick={() => setpop(true)}
                  >
                    {i18next.t("assign_mission_btn")}
                  </span>
                </a>
              </div>
              <div className="w-80  pt-6">
                <div className={cookies.lang=='ar'?'custom_mission_rtl_table':''}>
                  <Table
                    columns={columns}
                    dataSource={missionDetails}
                    pagination={tableParams.pagination}
                    onChange={handleTableChange}
                  />
                </div>
              </div>
              <br />
              <br />
              <div className=""></div>
            </div>
          </div>
        </section>
      </div>
      <div className="mobile">
        <section className=" container-fluid p-4 bg_black ">
          <div className="container-fluid mt-3 mission p-4 kidreward">
            <div className="pb-3">
              <a>
                <span
                  className={
                    cookies.lang === "ar"
                      ? " float-end bold"
                      : " float-start bold"
                  }
                >
                  {i18next.t("Missions")}
                </span>
              </a>
            </div>
            <br></br>
            <br></br>
            <div className="">
              <div
                className={
                  cookies.lang === "ar"
                    ? " row text-end p-2"
                    : " row text-start p-2"
                }
              >
                <div className="w-50">
                  <p className="retit"> {i18next.t("Added_missions")}</p>
                  <p className="added">{addedMissions}</p>
                </div>
                <div className="w-50">
                  <p className="retit ">{i18next.t("Completed_missions")}</p>
                  <p className="comp">{completedMissions}</p>
                </div>
              </div>
              <div
                className={
                  cookies.lang === "ar"
                    ? " miss-mobile pb-5 pt-1 text-end"
                    : " miss-mobile pb-5 pt-1 text-start"
                }
              >
                <div className="col-md-4 Rep-mission pt-3 pb-3">
                  <p>{i18next.t("m_r")}</p>
                  <hr></hr>
                  <div>
                    <Table
                      columns={columns}
                      dataSource={missionDetails}
                      scroll={{ x: 1500, y: 300 }}
                      pagination={tableParams.pagination}
                      onChange={handleTableChange}
                    />
                  </div>
                </div>
              </div>
              <div className=" row text-center">
                <div className="w-100">
                  <a
                    className={
                      cookies.lang === "ar"
                        ? " btn black-btn m-1 w-100 rtl "
                        : " btn black-btn m-1 w-100 "
                    }
                  >
                    <span className="">
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 34 34"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M17.0007 0.333252C7.80065 0.333252 0.333984 7.79992 0.333984 16.9999C0.333984 26.1999 7.80065 33.6666 17.0007 33.6666C26.2007 33.6666 33.6673 26.1999 33.6673 16.9999C33.6673 7.79992 26.2007 0.333252 17.0007 0.333252ZM25.334 18.6666H18.6673V25.3333H15.334V18.6666H8.66732V15.3333H15.334V8.66659H18.6673V15.3333H25.334V18.6666Z"
                          fill="#EECE52"
                        />
                      </svg>
                    </span>
                    <span
                      class="addnew"
                      className="p-1"
                      onClick={() => setpop(true)}
                    >
                      {i18next.t("assign_mission_btn")}
                    </span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}
