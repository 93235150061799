import {useNavigate} from 'react-router-dom';
import {useLocation } from 'react-router-dom';
import { useState, useEffect } from "react";
import Head from '../layout/Head';
import Nav from '../layout/Nav';
import Footer from '../layout/Footer';
import en from '../resources/en.json'
import AlertDialog from '../layout/AlertDialog';
import { Modal,Button} from 'react-bootstrap';
import config from "../config.json";
import { useDispatch, useSelector } from "react-redux";
import i18next from '../i18n/config';
export default function Complete(){
    const dispatch = useDispatch();
    const token = useSelector((state) => state.appReducer.token);
    const id = useSelector((state) => state.appReducer.id);

     const Modalcontent = i18next.t('child_account_successfully_created');
    const navigate = useNavigate();
    const location = useLocation();
    // const id=location.state.id;
    const username=location.state.username;
    const parentId=location.state.parentId;
    const parentName=location.state.parentName;
    const [show, setshow] = useState(true);
    let [userrole,setrole]=useState(0);

   let [years, setyears] = useState([]);
   let props = {
    show:show,
    Modalcontent:Modalcontent
    }
   let [date, setdate]=useState({
    dd:'',
    mm:'',
    yy:''
    })
    let [num, setnum]=useState({
    code:'',
   // phone:''
    })

    const [items, setitems] = useState([]);
    
    function getdate(){
        const d = new Date();
        let max =  d.getFullYear();
        let min = max - 60;
        for (var i = max; i >= min; i--) {
          years.push(i)
        }
        setyears(years);
       }


    var hostName =window.location.origin;
    const backend="https://phplaravel-592682-2484169.cloudwaysapps.com/api/";
    const front="http://127.0.0.1:8000/api/";
    let BaseUrl = config.BaseUrl;;

    if(parentId != null && parentId != undefined)
    {
        fetch(`${BaseUrl}users/userWithRoles/${parentId}`,{
            method:'get',
            body:JSON.stringify(),
            headers:{
                'Accept':'application/json',
                'Authorization': `Bearer ${token}`,
                'Content-Type':'application/json'
            }
        }).then(function(response){
            response.json().then(function(user_roleResp){

                setrole(user_roleResp.data[0].roles[0].id);
            })
        });
    }

    const [form, setform] = useState({
       // Phone:"",
        //Landline:"",
        Gender:"", 
        Weight:"", 
        Height:"", 
       // DOB:"",
       // Country:""
    });

    function ValidateForm(form){
        let Isvalid=false;
        Object.keys(form).forEach(key => {
            if (form[key] !=  '') {
                Isvalid=true;
            }
        });
        return Isvalid ;
    }

    function fixFormData(form){
            Object.keys(form).forEach(key => {
                if (form[key] === '') {
                  delete form[key];
                }
              });
       return form ;
    }

    function submit(){
         if (! ValidateForm(form))
         {
             var compltetErrMsg=document.getElementById('CompleteErrMsg');
             compltetErrMsg.classList.remove('d-none');
         }
         else{
            fetch(`${BaseUrl}users/completeinfo/${id}`,{
                method:'put',
                body:JSON.stringify( fixFormData(form)),
                headers:{
                    'Accept':'application/json',
                    'Content-Type':'application/json'
                } 
            }).then(function(response){
                response.json().then(function(complete_userResp){

                    if(complete_userResp.status==true){
                        if(parentId == null){

                            navigate('/dgamer', {state:{id:id,username:username}});    
                        }
                        else{
                            if(userrole == 1){
                                navigate('/parent', {state:{id:parentId,username:parentName,parentId:parentId}});
                              }
                              else if(userrole == 3){
                                navigate('/dgamer', {state:{id:parentId,username:parentName,parentId:parentId}});
                              }

                        }
                                
                    }
    
            })
          });
         }

    }

    useEffect(() => {
    fetch(`${BaseUrl}users/userinfo/${id}`,{
        method:'get',
        headers:{
        } 
      }).then(function(response11){
          response11.json().then(function(null_infoResp){
            for(var i=0;i<null_infoResp.data.length;i++){
               document.getElementById(Object.keys(null_infoResp.data[i][0])[0]).classList.remove('d-none');
               
            }
          })
        })
    
    }, []);

    return(
    <div>
        <Head/>
        <div className='container-fluid p-2 pb-5 sign vh'>
            <Nav/>
            <div className='flash1'></div>
            <div className='flash2'></div>
            <div className='flash3'></div>
            <div className='container text-center mt-5 pt-3'>
            <h2 className='text-white'>{en.complete.complete_info}</h2>
            <div class=" mt-3 ">
                    <div class="container "><br/>
                    <form className=' m-auto form-white' id='form-com'>
                        

                        <div class="mb-3 d-none" id='Gender'>
                            <select name="gender" className='form-select ' onChange={e=>setform({...form,Gender:e.target.value})} required>
                               <option value=''>{en.sign.gender}</option>
                                <option  value="MALE" >{en.sign.male} </option>
                                <option  value="FEMALE">{en.sign.female} </option>
                            </select>
                            <div className='invalid-feedback text-start text-danger'>{en.complete.gender_validation} </div>                      
                        </div>

                        <div class=" mb-3  d-none" id='Height'>
                            <input type="number" min='0' class="form-control"  placeholder={en.complete.height_placeholder}
                            onChange={e=>setform({...form,Height:e.target.value})} required/>
                           <div className='invalid-feedback text-start text-danger'>{en.complete.height_validation} </div>

                        </div>
                        <div class=" mb-3  d-none" id='Weight'>
                            <input type="number" min='0' class="form-control"  placeholder={en.complete.weight_placeholder}
                            onChange={e=>setform({...form,Weight:e.target.value})} required/>
                            <div className='invalid-feedback text-start text-danger'>{en.complete.weight_validation} </div>

                        </div>

                        <a className="btn yellow-btn mr-5 mt-4" id='sign'  onClick={submit}>
                            <span className="m-3">{en.complete.complete_btn}</span>
                            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M7.66659 1.66668V3.44668L6.99992 4.11334L6.33325 3.44668V1.66668H7.66659ZM12.3333 6.33334V7.66668H10.5533L9.88659 7.00001L10.5533 6.33334H12.3333ZM3.44659 6.33334L4.11325 7.00001L3.44659 7.66668H1.66659V6.33334H3.44659ZM6.99992 9.88668L7.66659 10.5533V12.3333H6.33325V10.5533L6.99992 9.88668ZM8.99992 0.333344H4.99992V4.00001L6.99992 6.00001L8.99992 4.00001V0.333344ZM13.6666 5.00001H9.99992L7.99992 7.00001L9.99992 9.00001H13.6666V5.00001ZM3.99992 5.00001H0.333252V9.00001H3.99992L5.99992 7.00001L3.99992 5.00001ZM6.99992 8.00001L4.99992 10V13.6667H8.99992V10L6.99992 8.00001Z" fill="#100E23"/>
                            </svg>
                        </a>
                     
                    </form>
                        <div class="alert alert-danger mt-5 d-none" id='CompleteErrMsg' >
                            {en.sign.fail_CompleteAccount}


                        </div>
                    </div>
              
            </div>
        </div>
        </div>
        <Footer bg='bg_blue'/>
    </div>
    )
}