import * as React from "react";
import {NavLink} from "react-router-dom";
import {useNavigate, useLocation} from "react-router-dom";
import {useState, useEffect} from "react";
import en from "../../resources/en.json";
import config from "../../config.json";
import {useDispatch, useSelector} from "react-redux";
import {Auth} from "../../redux/Actions/AuthActions";
import AlertDialog from "../../layout/AlertDialog";
import CircularProgress from "@mui/material/CircularProgress";
import {useCookies} from "react-cookie";
import i18next from "../../i18n/config";
import {Checkbox} from "antd";
import TermsPDF from "../../TermsAndConditions/TermsAndConditions.pdf";

export default function SignGamer(props) {
    const dispatch = useDispatch();
    const token = useSelector((state) => state.appReducer.token);
    const role = useSelector((state) => state.appReducer.role);
    const [cookies, setCookie] = useCookies(["lang"]);

    const navigate = useNavigate();
    const location = useLocation();
    const id = location.state?.id;
    const parentName = props?.parentName;
    const parent = props?.parent;
    const [wearableTypes, setwearableTypes] = useState([]);
    const [pop, setpop] = useState(false);
    let [watch1, setwatch1] = useState(0);
    const [loading, setLoading] = useState(false);

    let [userrole, setrole] = useState(0);
    let [isMore13, setisMore13] = useState(true);
    let [count, setcount] = useState(0);
    let [idd, setid] = useState(0);
    let [date, setdate] = useState({
        dd: "",
        mm: "",
        yy: "",
    });
    let [num, setnum] = useState({
        code: "",
        phone: "",
    });
    const [form, setform] = useState({
        Parent_id: "",
        name: "",
        email: "",
        password: "",
        // Phone:"",
        // Landline:"",
        Gender: "",
        Weight: 0,
        Height: 0,
        DOB: "",
        //Country:"",
        RoleID: "2",
        WithRole: true,
        AccountAge: "0",
        is_assafeer_user: false,
        isNewAssafeerUser: "false",
        asafeeerUserName: "",
        asafeerPassword: ""
    });
    let [years, setyears] = useState([]);
    var hostName = window.location.origin;
    const backend = "https://phplaravel-592682-2484169.cloudwaysapps.com/api/";
    const front = "http://127.0.0.1:8000/api/";
    let BaseUrl = config.BaseUrl;

    const openInNewTab = (url) => {
        const newWindow = window.open(url, "_blank", "width=500, height=500");
    };

    function getdate() {
        const d = new Date();

        let ArrYears = Array();
        let max = d.getFullYear();
        let min = max - 13;
        for (var i = max; i >= min; i--) {
            ArrYears.push(i);
        }
        setyears(ArrYears);
    }

    useEffect(() => {
        getdate();
        if (date.dd != "" || date.mm != "" || date.yy != "") {
            setform({
                ...form,
                DOB: date.yy
                    .concat("-", date.mm)
                    .concat("-", date.dd)
                    .concat(" ", "00:00:00"),
            });
        }
    }, [date]);

    useEffect(() => {
        fetch(`${BaseUrl}wearabletype`, {
            method: "get",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "Accept-Language": cookies.lang,
            },
        }).then(function (response) {
            response.json().then(function (wearablesresp) {
                if (wearablesresp.status == true) setwearableTypes(wearablesresp.data);
            });
        });
    }, []);

    useEffect(() => {
        if (parent != "") {
            setform({...form, Parent_id: parent});
        }
    }, [parent]);

    useEffect(() => {
        if (pop == true) {
            setTimeout(() => {
                setpop(false);
            }, 1000);
        }
    }, [pop]);

    function google() {
        fetch(`${BaseUrl}login/google`, {
            method: "get",
            headers: {},
        }).then(function (response) {
            response.json().then(function (googleresp) {
            });
        });
    }

    function facebook() {
        fetch(`${BaseUrl}login/facebook`, {
            method: "get",
            headers: {},
        }).then(function (response) {
            response.json().then(function (facebookresp) {
            });
        });
    }

    const AccountAgeHandleChange = (e) => {
        setform(null);
        setform({...form, AccountAge: e.target.value});

        if (e.target.value == "0") {
            setisMore13(false);
            setform({...form, is_assafeer_user:false});
        } else {
            setisMore13(true);
            setform({...form, is_assafeer_user:false});
        }
    };

    function connect() {
        document.getElementById("sign").style.display = "none";
        document.getElementById("connect").classList.add("disabled");
        setcount(++count);
        document.getElementById("message").style.display = "none";
        document.getElementById("message").innerHTML = "";
        setLoading(true);

        fetch(`${BaseUrl}loginn`, {
            method: "post",
            body: JSON.stringify({
                email: form.email,
                password: form.password,
            }),
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "Accept-Language": cookies.lang,
            },
        }).then(function (response) {
            response.json().then(function (loginresp) {
                dispatch(
                    Auth(loginresp.user.token, loginresp.user.id, loginresp.user.name)
                );

                setid(loginresp.user.id);
                if (count >= 4) {
                    clearInterval(x);
                    document.getElementById("demo").innerHTML = " ";
                    alert(en.sign.manytimes_alert);
                    //navigate('/signup');
                } else if (count < 4) {
                    fetch(
                        `${BaseUrl}connectUserTowearable/${loginresp.user.id}/${watch1}`,
                        {
                            method: "get",
                            headers: {
                                Accept: "application/json",
                                Authorization: `Bearer ${token}`,
                                "Accept-Language": cookies.lang,
                            },
                        }
                    ).then(function (response11) {
                        response11.json().then(function (connect_fitbitresp) {
                            openInNewTab(connect_fitbitresp.data);
                        });
                    });
                    var seconds = 30;
                    // Update the count down every 1 second
                    var x = setInterval(function () {
                        // Time calculations for days, hours, minutes and seconds
                        --seconds;

                        // Display the result in the element with id="demo"
                        document.getElementById("demo").innerHTML =
                            en.sign.watchconnect_within + seconds + "s ";

                        // If the count down is finished, write some text
                        if (seconds < 0) {
                            clearInterval(x);
                            document.getElementById("demo").innerHTML = en.sign.time_expired;
                        }
                    }, 1000);
                    var y = setTimeout(function () {
                        fetch(`${BaseUrl}checkwearableAccess/${loginresp.user.id}`, {
                            method: "get",
                            headers: {
                                Accept: "application/json",
                                Authorization: `Bearer ${token}`,
                                "Accept-Language": cookies.lang,
                            },
                        }).then(function (response33) {
                            response33.json().then(function (check_fitbitresp) {
                                setLoading(false);
                                if (
                                    Array.isArray(check_fitbitresp) &&
                                    check_fitbitresp[0] == "user connect to wearable successfully"
                                ) {
                                    clearInterval(x);
                                    document.getElementById("connect").style.display = "none";
                                    document.getElementById("demo").innerHTML =
                                        en.sign.account_prepare;
                                    navigate("/parent", {
                                        state: {username: parentName, parentId: parent},
                                    });
                                } else {
                                    if ("UserError" in check_fitbitresp[0]) {
                                        document.getElementById("connect").style.display = "block";
                                        document
                                            .getElementById("connect")
                                            .classList.remove("disabled");
                                        document.getElementById("message").style.display = "block";
                                        if (
                                            check_fitbitresp[0].UserError.status ==
                                            "This user does not have a wearable account"
                                        ) {
                                            document.getElementById("message").innerHTML =
                                                en.sign.watchlongtime_msg;
                                        } else {
                                            document.getElementById("message").innerHTML =
                                                en.sign.watchfailed_msg;
                                        }
                                    }
                                }
                            });
                        });
                    }, 30000);
                }
            });
        });
    }

    function heightOnchang(e) {

        setform({...form, Height: e.target.value});
        if (e.target.value > 200) {
            document.getElementById("HeightError").style.display = "block";
            document.getElementById("HeightError").innerHTML = i18next.t(
                "heightValue_validation"
            );
            // HeightError = 'Height value is incorrect! ';
        } else if (e.target.value == null || e.target.value == "") {
            document.getElementById("HeightError").style.display = "block";
            document.getElementById("HeightError").innerHTML =
                i18next.t("height_validation");
        } else {
            document.getElementById("HeightError").style.display = "none";
        }
    }

    function weightOnchang(e) {
        setform({...form, Weight: e.target.value});
        if (e.target.value > 200) {
            document.getElementById("WeightError").style.display = "block";
            document.getElementById("WeightError").innerHTML = i18next.t(
                "weightValue_validation"
            );
        } else if (e.target.value == null || e.target.value == "") {
            document.getElementById("WeightError").style.display = "block";
            document.getElementById("WeightError").innerHTML =
                i18next.t("weight_validation");
        } else {
            document.getElementById("WeightError").style.display = "none";
        }
    }

    function CreateChildWithoutFitbit(lessThan13 = false) {

        if (lessThan13) {
            if (date.dd == "" || date.mm == "" || date.yy == "") {
                document.getElementById("message").innerHTML = i18next.t("date_rquired");
                ;
                document.getElementById("message").style.display = "block";


                return;
            }
        }
        document.getElementById("form").classList.add("was-validated");
        if (document.getElementById("form").checkValidity()) {
            setLoading(true);
            fetch(`${BaseUrl}users?XDEBUG_SESSION_START=PHPSTORM`, {
                method: "post",
                body: JSON.stringify(form),
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    "Accept-Language": cookies.lang,
                },
            }).then(function (response) {
                response.json().then(function (signresp) {
                    setLoading(false);
                    if (signresp.errNum == "E0002") {
                        document.getElementById("message").style.display = "block";
                        document.getElementById("message").innerHTML = signresp.msg;
                    } else if (signresp.msg == "Failed") {
                        document.getElementById("message").style.display = "block";
                        if (signresp && signresp.data && signresp.data.name[0]) {
                            document.getElementById("message").innerHTML =
                                signresp.data.name[0];
                        } else {
                            document.getElementById("message").innerHTML =
                                i18next.t("fail_createaccount");
                        }
                    } else if (signresp.errNum == "S0000") {
                        // if(userrole &&  userrole == 1){
                        navigate("/parent", {
                            state: {username: parentName, parentId: parent},
                        });
                        // }
                        // else if(userrole && userrole == 3){
                        //     navigate('/dgamer', {state:{id:id,username:parentName}});
                        // }
                    }
                });
            });
        }
    }

    function CreateChildWithFitbit() {
        if (watch1 == 0) {
            setpop(true);
        } else {
            document.getElementById("form").classList.add("was-validated");
            if (
                !document
                    .getElementById("password")
                    .value.match(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{7,20}$/)
            ) {
                document.getElementById("pass").style.display = "block";
            }
            if (
                document.getElementById("co-password").value !=
                document.getElementById("password").value
            ) {
                document.getElementById("confirm").style.display = "block";
            }
            if (
                document.getElementById("form").checkValidity() &&
                document.getElementById("co-password").value ==
                document.getElementById("password").value &&
                document
                    .getElementById("password")
                    .value.match(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{7,20}$/)
            ) {
                fetch(`${BaseUrl}users`, {
                    method: "post",
                    body: JSON.stringify(form),
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        "Accept-Language": cookies.lang,
                    },
                }).then(function (response) {
                    response.json().then(function (signresp) {
                        if (signresp.errNum == "E0002") {
                            document.getElementById("message").style.display = "block";
                            document.getElementById("message").innerHTML = signresp.msg;
                        } else if (signresp.msg == "Failed") {
                            document.getElementById("message").style.display = "block";
                            if (signresp && signresp.data && signresp.data.email[0]) {
                                document.getElementById("message").innerHTML =
                                    signresp.data.email[0];
                            } else {
                                document.getElementById("message").innerHTML =
                                    i18next.t("fail_createaccount");
                            }
                        } else if (signresp.msg == "That e-mail has taken, Try another") {
                            document.getElementById("message").style.display = "block";
                            document.getElementById("message").innerHTML = signresp.msg;
                        } else if (signresp.errNum == "S0000") {
                            connect();
                        }
                    });
                });
            }
        }
    }

    function submit() {
        if (isMore13) {
            // CreateChildWithFitbit();
            CreateChildWithoutFitbit(false);
        } else {
            CreateChildWithoutFitbit(true);
        }
    }

//////////////////ASAFEER SECTION//////////////////
    function onChangeUseAsafeer(e) {

        setform({...form, is_assafeer_user: e.target.checked});
    }

    function handleRadioChange(e) {

        setform({...form, isNewAssafeerUser: e.target.value});
    }

    ///////////////////END ASAFEER SECTION//////////////////
    return (
        <div>
            <AlertDialog show={pop} Modalcontent={en.sign.chooseWatch_pop}/>
            <form className=" m-auto form-white" id="form">
                <hr className="my-3 hr-yellow"/>
                <div className="mb-3">
                    <select
                        name="AccountAge"
                        className={
                            cookies.lang == "ar" ? "form-select text-end" : "form-select"
                        }
                        id=""
                        onChange={AccountAgeHandleChange}>
                        <option value="1" selected={true}>
                            {i18next.t("MoreThan13")}{" "}
                        </option>
                        <option value="0">{i18next.t("LessThan13")} </option>
                    </select>
                </div>
                <div className="mb-3 ">
                    <input
                        type="text"
                        maxLength="50"
                        className={
                            cookies.lang == "ar" ? "form-control text-end" : "form-control"
                        }
                        placeholder={i18next.t("name_placeholder")}
                        onFocus={(e) => (e.target.placeholder = "")}
                        onBlur={(e) =>
                            (e.target.placeholder = i18next.t("name_placeholder"))
                        }
                        name="name"
                        onChange={(e) => setform({
                            ...form,
                            name: e.target.value,
                          //  email: e.target.value?.replace(/\s+/g, '_').toLowerCase()+"_"+parent + "@gamers.com"
                        })}
                        required
                    />
                    <div className="invalid-feedback text-start text-danger">
                        {i18next.t("name_validation")}{" "}
                    </div>
                </div>
                {!isMore13 ? (
                    <>
                        {/*// Weight*/}
                        {/*<div className="mb-3 ">*/}
                        {/*    <input*/}
                        {/*        type="number"*/}
                        {/*        min={0}*/}
                        {/*        max={200}*/}
                        {/*        className={*/}
                        {/*            cookies.lang == "ar"*/}
                        {/*                ? "form-control text-end"*/}
                        {/*                : "form-control"*/}
                        {/*        }*/}
                        {/*        placeholder={i18next.t("profile_weight")}*/}
                        {/*        onFocus={(e) => (e.target.placeholder = "")}*/}
                        {/*        onBlur={(e) =>*/}
                        {/*            (e.target.placeholder = i18next.t("profile_weight"))*/}
                        {/*        }*/}
                        {/*        name="Weight"*/}
                        {/*        onChange={(e) => weightOnchang(e)}*/}
                        {/*        required*/}
                        {/*    />*/}
                        {/*    <div*/}
                        {/*        className="invalid-feedback text-start text-danger"*/}
                        {/*        id="WeightError"*/}
                        {/*        name="WeightError">*/}
                        {/*        {i18next.t("weight_validation")}*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        {/*// Height*/}
                        {/*<div className="mb-3 ">*/}
                        {/*    <input*/}
                        {/*        type="number"*/}
                        {/*        min={0}*/}
                        {/*        max={200}*/}
                        {/*        className={*/}
                        {/*            cookies.lang == "ar"*/}
                        {/*                ? "form-control text-end"*/}
                        {/*                : "form-control"*/}
                        {/*        }*/}
                        {/*        placeholder={i18next.t("profile_height")}*/}
                        {/*        onFocus={(e) => (e.target.placeholder = "")}*/}
                        {/*        onBlur={(e) =>*/}
                        {/*            (e.target.placeholder = i18next.t("profile_height"))*/}
                        {/*        }*/}
                        {/*        name="Height"*/}
                        {/*        onChange={(e) => heightOnchang(e)}*/}
                        {/*        required*/}
                        {/*    />*/}
                        {/*    <div*/}
                        {/*        className="invalid-feedback text-start text-danger"*/}
                        {/*        id="HeightError"*/}
                        {/*        name="HeightError">*/}
                        {/*        {i18next.t("height_validation")}*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        {/*// Gender*/}
                        <div className="mb-3 ">
                            <div className="row"   dir={
                                cookies.lang == "ar" ? "rtl" : "ltr"
                            }>
                            <div className="col-6">
                                <input
                                    type="text"
                                    className={
                                        cookies.lang == "ar"
                                            ? "form-control text-end"
                                            : "form-control"
                                    }
                                    id="name"
                                    placeholder={i18next.t("user_name_placeholder")}
                                    onFocus={(e) => (e.target.placeholder = "")}
                                    onBlur={(e) =>
                                        (e.target.placeholder = i18next.t("user_name_placeholder"))
                                    }
                                    name="name"
                                    onChange={(e) => setform({...form, email: e.target.value + "@veryality-gamers.com"})}
                                    required
                                />
                                <div className="invalid-feedback text-start text-danger">
                                    {i18next.t("name_validation")}{" "}
                                </div>
                            </div>
                            <div className="col-6 p-0" dir="ltr">
                                 <p className="text-white pt-3  email-lable " style={{fontSize:"14px"}}>@veryality-gamers.com</p>
                            </div>
                            </div>
                        </div>
                        <div className="mb-3">
                            <select
                                name="gender"
                                className={
                                    cookies.lang == "ar" ? "form-select text-end" : "form-select"
                                }
                                id=""
                                onChange={(e) => setform({...form, Gender: e.target.value})}>
                                <option>{i18next.t("gender")}</option>
                                <option value="MALE">{i18next.t("male")} </option>
                                <option value="FEMALE">{i18next.t("female")} </option>
                            </select>
                        </div>
                        {/*// birthday*/}
                        <div className={
                            cookies.lang == "ar" ? "mb-3 input-group rtl_rightAlign" : "mb-3 input-group"
                        }>
              <span className="input-group-text select-watch text-white w-30">
                {i18next.t("birthday")}
              </span>
                            <select
                                name=""
                                className="form-select w-20"
                                id=""
                                onChange={(e) => {
                                    setdate({...date, mm: e.target.value});
                                }}>
                                <option>MM</option>
                                <option value="01">1</option>
                                <option value="02">2</option>
                                <option value="03">3</option>
                                <option value="04">4</option>
                                <option value="05">5</option>
                                <option value="06">6</option>
                                <option value="07">7</option>
                                <option value="08">8</option>
                                <option value="09">9</option>
                                <option value="10">10</option>
                                <option value="11">11</option>
                                <option value="12">12</option>
                            </select>
                            <select
                                name=""
                                className="form-select w-20"
                                id=""
                                onChange={(e) => {
                                    setdate({...date, dd: e.target.value});
                                }}>
                                <option>DD</option>
                                <option value="01">1</option>
                                <option value="02">2</option>
                                <option value="03">3</option>
                                <option value="04">4</option>
                                <option value="05">5</option>
                                <option value="06">6</option>
                                <option value="07">7</option>
                                <option value="08">8</option>
                                <option value="09">9</option>
                                <option value="10">10</option>
                                <option value="11">11</option>
                                <option value="12">12</option>
                                <option value="13">13</option>
                                <option value="14">14</option>
                                <option value="15">15</option>
                                <option value="16">16</option>
                                <option value="17">17</option>
                                <option value="18">18</option>
                                <option value="19">19</option>
                                <option value="20">20</option>
                                <option value="21">21</option>
                                <option value="22">22</option>
                                <option value="23">23</option>
                                <option value="24">24</option>
                                <option value="25">25</option>
                                <option value="26">26</option>
                                <option value="27">27</option>
                                <option value="28">28</option>
                                <option value="29">29</option>
                                <option value="30">30</option>
                                <option value="31">31</option>
                            </select>
                            <select
                                name=""
                                className="form-select w-20"
                                id=""
                                onChange={(e) => {
                                    setdate({...date, yy: e.target.value});
                                }}>
                                <option>YYYY</option>
                                {years.map((year) => (
                                    <option value={year}>{year} </option>
                                ))}
                            </select>


                        </div>

                        <div className={cookies.lang == "ar" ? "col-lg-12 text-end mb-3" : "col-lg-12 text-start mb-3 pb-4"}
                             dir={cookies.lang == "ar" ? "rtl" : "ltr"}>
                            <Checkbox onChange={onChangeUseAsafeer}>

                                <span className=" text-white"> {i18next.t("use_asafeer")} </span>

                            </Checkbox>
<br/>
                        </div>


                        {form.is_assafeer_user == true ? (
                            <div>
                                <div
                                    className={cookies.lang == "ar" ? "col-lg-12 text-end" : "col-lg-12 text-start mb-3"}
                                    dir={cookies.lang == "ar" ? "rtl" : "ltr"}>

                                    <span className=" text-white"> {i18next.t("do_u_have_asafeer_account")}
                                    <a href="https://3asafeer.com/" target="_blank"> {i18next.t("learn_more")} </a>
                                    </span>


                                    <div className="col-lg-12">
                                        {/* First radio button */}
                                        <label>
                                            <input
                                                type="radio"
                                                value="false"
                                                checked={form.isNewAssafeerUser === "false"}
                                                onChange={handleRadioChange}
                                                name="radioGroup"
                                            />
                                            <span className=" text-white"> {i18next.t("yes")} </span>
                                        </label>
                                    </div>
                                    <div className="col-lg-12">
                                        {/* Second radio button */}
                                        <label>
                                            <input
                                                type="radio"
                                                value="true"
                                                checked={form.isNewAssafeerUser === "true"}
                                                onChange={handleRadioChange}
                                                name="radioGroup"
                                            />
                                            <span className=" text-white"> {i18next.t("no")} </span>
                                        </label>
                                    </div>
                                </div>
                              {form.isNewAssafeerUser == "false" && form.is_assafeer_user == true?(
                                      <div>
                                      <div className="mb-3 " >
                                        <input
                                            type="text"
                                            maxLength="50"
                                            className={
                                              cookies.lang == "ar" ? "form-control text-end" : "form-control"
                                            }
                                            placeholder={i18next.t("asafeer_user_name_placeholder")}
                                            onFocus={(e) => (e.target.placeholder = "")}
                                            onBlur={(e) =>
                                                (e.target.placeholder = i18next.t("asafeer_user_name_placeholder"))
                                            }
                                            name="name"
                                            onChange={(e) => setform({...form, asafeeerUserName: e.target.value})}
                                            required
                                        />
                                        <div className="invalid-feedback text-start text-danger">
                                          {i18next.t("asafeer_user_name_validation")}{" "}
                                        </div>
                                      </div>
                                  <div className="mb-3 ">

                                    <input
                                        type="text"
                                        maxLength="50"
                                        className={
                                          cookies.lang == "ar" ? "form-control text-end" : "form-control"
                                        }
                                        placeholder={i18next.t("asafeer_password_placeholder")}
                                        onFocus={(e) => (e.target.placeholder = "")}
                                        onBlur={(e) =>
                                            (e.target.placeholder = i18next.t("asafeer_password_placeholder"))
                                        }
                                        name="name"
                                        onChange={(e) => setform({...form, asafeerPassword: e.target.value})}
                                        required
                                    />
                                    <div className="invalid-feedback text-start text-danger">
                                      {i18next.t("asafeer_password_validation")}{" "}
                                    </div>
                                  </div>
                                      </div>
                              ):( <div class="mb-3 ">
                                  <input
                                      type="email"
                                      className={
                                          cookies.lang == "ar"
                                              ? "form-control text-end"
                                              : "form-control"
                                      }
                                      id="email"
                                      placeholder={i18next.t("mail_placeholder")}
                                      onFocus={(e) => (e.target.placeholder = "")}
                                      onBlur={(e) =>
                                          (e.target.placeholder = i18next.t("mail_placeholder"))
                                      }
                                      name="email"
                                      onChange={(e) => setform({...form, email: e.target.value})}
                                      required
                                  />
                                  <div className="invalid-feedback text-start text-danger">
                                      {i18next.t("mail_validation")}{" "}
                                  </div>
                              </div>)}


                            </div>
                        ) : ("")}


                        {" "}
                    </>
                ) : (
                    <>
                        {/*// email*/}
                        <div class="mb-3 ">
                            <input
                                type="email"
                                className={
                                    cookies.lang == "ar"
                                        ? "form-control text-end"
                                        : "form-control"
                                }
                                id="email"
                                placeholder={i18next.t("mail_placeholder")}
                                onFocus={(e) => (e.target.placeholder = "")}
                                onBlur={(e) =>
                                    (e.target.placeholder = i18next.t("mail_placeholder"))
                                }
                                name="email"
                                onChange={(e) => setform({...form, email: e.target.value})}
                                required
                            />
                            <div className="invalid-feedback text-start text-danger">
                                {i18next.t("mail_validation")}{" "}
                            </div>
                        </div>

                        {/*// password*/}
                        <div class="mb-3">
                            <input
                                type="password"
                                className={
                                    cookies.lang == "ar"
                                        ? "form-control text-end"
                                        : "form-control"
                                }
                                id="password"
                                placeholder={i18next.t("password_placeholder")}
                                onFocus={(e) => (e.target.placeholder = "")}
                                onBlur={(e) =>
                                    (e.target.placeholder = i18next.t("password_placeholder"))
                                }
                                name="password"
                                onChange={(e) => setform({...form, password: e.target.value})}
                                required
                            />
                            <div className="invalid-feedback text-start text-danger">
                                {i18next.t("password_validation")}{" "}
                            </div>
                            <div
                                className="invalid-feedback text-start text-danger"
                                id="pass">
                                {i18next.t("password_validation_contain")}
                            </div>
                        </div>
                        {/*// co-passwor*/}
                        <div class="mb-3">
                            <input
                                type="password"
                                className={
                                    cookies.lang == "ar"
                                        ? "form-control text-end"
                                        : "form-control"
                                }
                                id="co-password"
                                placeholder={i18next.t("confirmPassword_placeholder")}
                                onFocus={(e) => (e.target.placeholder = "")}
                                onBlur={(e) =>
                                    (e.target.placeholder = i18next.t(
                                        "confirmPassword_placeholder"
                                    ))
                                }
                                name=""
                                required
                            />
                            <div className="invalid-feedback text-start text-danger">
                                {i18next.t("confirmpassword_validation")}{" "}
                            </div>
                            <div
                                className="invalid-feedback text-start text-danger"
                                id="confirm">
                                {i18next.t("confirmpassword_match")}{" "}
                            </div>
                        </div>
                        <hr class="my-3 hr-yellow"/>
                        <div className="mb-3 input-group">
              <span className="input-group-text select-watch">
                <img src="../assets/imgs/watch.png" className=""></img>
              </span>
                            <select
                                name=""
                                className={
                                    cookies.lang == "ar" ? "form-select text-end" : "form-select"
                                }
                                id=""
                                onChange={(e) => {
                                    setwatch1(e.target.value);
                                }}>
                                <option>{i18next.t("select_device")}</option>
                                {wearableTypes.length > 0
                                    ? wearableTypes.map((item) => (
                                        <option className="" value={item.id}>
                                            {item.Type}{" "}
                                        </option>
                                    ))
                                    : ""}
                            </select>
                        </div>
                    </>
                )}

                <a
                    className="btn yellow-btn mr-5 "
                    id="sign"
                    onClick={submit}
                    style={{backgroundColor: "#EECE52 !important"}}>
                    <span className="m-3">{i18next.t("creategamer_btn")}</span>
                    <svg
                        width="14"
                        height="14"
                        viewBox="0 0 14 14"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M7.66659 1.66668V3.44668L6.99992 4.11334L6.33325 3.44668V1.66668H7.66659ZM12.3333 6.33334V7.66668H10.5533L9.88659 7.00001L10.5533 6.33334H12.3333ZM3.44659 6.33334L4.11325 7.00001L3.44659 7.66668H1.66659V6.33334H3.44659ZM6.99992 9.88668L7.66659 10.5533V12.3333H6.33325V10.5533L6.99992 9.88668ZM8.99992 0.333344H4.99992V4.00001L6.99992 6.00001L8.99992 4.00001V0.333344ZM13.6666 5.00001H9.99992L7.99992 7.00001L9.99992 9.00001H13.6666V5.00001ZM3.99992 5.00001H0.333252V9.00001H3.99992L5.99992 7.00001L3.99992 5.00001ZM6.99992 8.00001L4.99992 10V13.6667H8.99992V10L6.99992 8.00001Z"
                            fill="#100E23"
                        />
                    </svg>
                </a>
                <a
                    className="btn yellow-btn mr-5 disabled"
                    id="connect"
                    onClick={connect}
                    style={{backgroundColor: "#EECE52 !important"}}>
                    <span className="m-3">{i18next.t("linkwatch_btn")}</span>
                </a>
            </form>
            {loading == true ? (
                <div className="w-100 mt-3 mb-2 m-auto">
                    <CircularProgress/>
                </div>
            ) : (
                <div class="alert alert-danger mt-5" id="message"></div>
            )}
            <p id="demo" className="text-white p-2 mt-3 "></p>
        </div>
    );
}
